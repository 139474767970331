//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Injectable } from "@angular/core";
import { HttpRequest, HttpRequestType } from "@Shared/utils/httpRequest";
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service"
import { MsalService } from "@Msal/services/msal.service";
import { BehaviorSubject } from 'rxjs';

export interface UserAccountDetails {
  givenName: string;
  surname: string;
  phone: string;
  jobTitle: string;
  company: string;
  street: string;
  city: string;
  postal: string;
  state: string;
  displayName: string;
  mail: string;
  country: string;
  marketingOption: boolean;
}

@Injectable()
export class AccountService {
  public userInfoRequest: HttpRequest;
  public userDetailsSubject = new BehaviorSubject<UserAccountDetails | null>(null);

  constructor(private httpService: MsalHttpRequestService, private msalService: MsalService) {

    this.userInfoRequest = this.httpService.getRequest(HttpRequestType.GET, "user/info");

    this.userInfoRequest.onSuccess.on(result => {
      const user = result.getReponseObj<UserAccountDetails>();
      this.userDetailsSubject.next(user);
    });

    this.msalService.onTokenReceived.on(t => this.refresh());
    if (this.msalService.isOnline) {
      this.refresh();
    }
  }

  public get signedInUserDetails(): UserAccountDetails | null {
    if (!this.userInfoRequest.isCompleteWithoutError) {
      return null;
    }
    return this.userInfoRequest.getReponseObj<UserAccountDetails>();
  }

  private refresh() {
    this.userInfoRequest.send();
  }
}
