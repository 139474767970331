//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { LiteEvent } from '../utils/liteEvent';
import { StringUtils } from '../utils/stringUtils';
import { HttpRequest } from '../utils/httpRequest';
import { Component, Input, OnInit } from '@angular/core';

export class UserModel {
    public email: string;

    /* set if the user exist in the database */
    public b2cId: string | null;
    public fnoId: string | null;

    public givenName: string | null;
    public surname: string | null;
    public country: string | null;
    public city: string | null;
    public streetAddress: string | null;
    public state: string | null;
    public postalCode: string | null;

    public constructor(init?: Partial<UserModel>) {
        Object.assign(this, init);
    }

    public isValid(): boolean {
        if (StringUtils.IsNullOrWhiteSpace(this.email)) {
            return false;
        }

        return !StringUtils.IsNullOrWhiteSpace(this.givenName)
            && !StringUtils.IsNullOrWhiteSpace(this.surname)
            && !StringUtils.IsNullOrWhiteSpace(this.country)
            && !StringUtils.IsNullOrWhiteSpace(this.city)
            && !StringUtils.IsNullOrWhiteSpace(this.streetAddress);
    }
}

@Component({
    selector: 'user-form',
    styles: [`
        input {
            width: 50%;
        }
    `],
    template: `
        <request-presenter [request]='getUserRequest' [locker]=true></request-presenter>
        <form clrForm>
            <clr-control-container>
                <label for="email" i18n>Email</label>
                <input
                    clrControl
                    type="text"
                    id="email"
                    name="email"
                    [(ngModel)]="email"
                    email
                    required
                    #emailInputModel="ngModel"/>
                <button class='btn btn-outline btn-sm' (click)='checkUserEmail()' [disabled]='!emailInputModel.valid' i18n>Check</button>
            </clr-control-container>
        </form>

        <ng-container *ngIf="emailChecked">
            <h3 *ngIf="userFound">Existing User</h3>
            <h3 *ngIf="!userFound">New User</h3>

            <form clrForm>
                <clr-input-container>
                    <label for="givenName" i18n>Given Name</label>
                    <input clrInput type="text" id="givenName" name="givenName" [(ngModel)]="model.givenName" [readonly]="isReadonly"/>
                </clr-input-container>
                <clr-input-container>
                    <label for="surname" i18n>Surname</label>
                    <input clrInput type="text" id="surname" name="surname" [(ngModel)]="model.surname" [readonly]="isReadonly"/>
                </clr-input-container>
                <address-enrichment [(street)]="model.streetAddress" [(city)]="model.city"
                    [(postal)]="model.postalCode" [(state)]="model.state"
                    [(country)]="model.country" [readonly]="isReadonly">
                </address-enrichment>
            </form>
    `
})
export class UserFormComponent implements OnInit {
    @Input() public getUserRequest: HttpRequest;

    public onUserNotFound: LiteEvent<UserModel>;

    public model: UserModel;
    public emailChecked: boolean;
    public userFound: boolean;
    public isReadonly: boolean;

    public constructor() {
        this.reset();
        this.onUserNotFound = new LiteEvent();
    }

    public ngOnInit(): void {
        this.getUserRequest.onStart.on(req => {
            req.query.userEmail = this.email;
            this.emailChecked = false;
            this.model = new UserModel({ email: req.query.userEmail });
        });

        this.getUserRequest.onSuccess.on(req => {
            let responseObj: any = req.responseObj;
            this.emailChecked = true;
            this.userFound = responseObj.found;
            if (this.userFound) {
                this.model = new UserModel(responseObj.model);
                this.isReadonly = this.model.isValid();
            } else {
                this.isReadonly = false;
                this.onUserNotFound.trigger(this.model);
            }
        });
    }

    public checkUserEmail(): void {
        this.getUserRequest.send();
    }

    public reset(): void {
        this.model = new UserModel();
        this.emailChecked = false;
        this.userFound = false;
        this._email = null;
    }

    public isValid(): boolean {
        return this.model.isValid();
    }

    private _email: string | null;

    public get email(): string | null {
        return this._email;
    }

    public set email(value: string | null) {
        this.reset();
        this._email = value;
    }
}
