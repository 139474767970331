//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
    selector: 'loading-presenter',
    template: `
        <ng-container *ngIf="isLoading">
            <ng-container *ngIf="isCenter">
                <div class="centerText">
                    <span class='spinner spinner-sm'></span>
                </div>
            </ng-container>

            <ng-container *ngIf="!isCenter">
                <span class='spinner spinner-sm'></span>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="!isLoading">
            <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
        </ng-container>
    `
})
export class LoadingPresenterComponent {
    @Input() isLoading:boolean;
    @Input() isCenter:boolean = true;

    @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
}
