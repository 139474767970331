//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { EnterpriseComponent } from './Enterprise/enterprise.component';
import { SubscriptionsComponent } from './Subscriptions/subscriptions.component';
import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountComponent } from './Account/account.component';
import { HelpComponent } from './Help/help.component';

const routes: Routes = [
    { path: '', redirectTo: '/mysubscriptions', pathMatch: 'full' },
    { path: 'account', component: AccountComponent},
    { path: 'mysubscriptions', component: SubscriptionsComponent},
    { path: 'enterprise', component: EnterpriseComponent},
    { path: 'help', component: HelpComponent},
];

@NgModule({
    imports: [ RouterModule.forRoot(routes, {}) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}
