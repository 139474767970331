//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

/*
    Usage:
        <request-presenter [request:HttpRequest] [spinner:bool] [locker:bool]>
            <request-loading>...</request-loading>
            <ng-template>...</ng-template>
        <request-presenter

    On the use of template: it is because the content of ng-content is initialised even if *ngIf="false".
    Need to use ng-template:
        https://stackoverflow.com/questions/44929726/angular4-ng-content-gets-built-when-ngif-is-false
        https://github.com/angular/angular/issues/14957#issuecomment-284420787
        https://github.com/angular/angular/issues/14957#issuecomment-284420787
        https://stackoverflow.com/questions/41652919/instantiate-transcluded-components-depending-on-their-dom-state-in-angular-2/41655566#41655566
*/

import { Component, ContentChild, Input, OnInit, TemplateRef, Directive } from '@angular/core';
import { IHttpRequest } from '@Shared/utils/iHttpRequest';

@Component({
    selector: 'request-presenter',
    template: `
        <ng-container *ngIf="showError">
            <div
                class="errorBlock"
                *ngIf="request.isInError"
                [innerHtml]="request.errorMessage"
            >
            </div>
        </ng-container>

        <locker [enabled]="request.isLoading && locker"></locker>
        <ng-container *ngIf="request.isLoading">
            <ng-content select="request-loading"></ng-content>
            <div class="centerText" *ngIf="spinner">
                <span [ngClass]="isLarge ? 'spinner spinner-lg' : 'spinner spinner-sm'"></span>
            </div>
        </ng-container>

        <ng-container *ngIf="(alwaysShowContent && !request.isLoading) || request.isCompleteWithoutError">
            <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
        </ng-container>
    `
})
export class RequestPresenterComponent implements OnInit {
    @Input() request: IHttpRequest;
    @Input() locker: boolean = false;
    @Input() spinner: boolean = true;
    @Input() showError: boolean = true;
    @Input() isLarge: boolean = false;

    /* 
        To show the content even if the request has not been sent yet or if an error occurred. But not if request is still loading.
        Useful for buttons that send requests.
    */
    @Input() alwaysShowContent = false;

    @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

    public ngOnInit(): void {
        if (this.request == null) {
            throw new Error('No Request.');
        }
    }
}

/*
    https://github.com/angular/angular/issues/11251
    Declare request-loading to not have unknown tags error. This is the best way if we don't want to remove schemas errors.
*/
@Directive({ selector: 'request-loading' })
export class RequestPresenterComponentTags { }

export const RequestPresenterDirectives = [RequestPresenterComponent, RequestPresenterComponentTags];
