<clr-input-container>
    <label for="StreetAddress">Street Address</label>
    <input clrInput id="StreetAddress" name="StreetAddress" required pattern="^$|[\x00-\x7F]+" [ngModel]="street"
        (ngModelChange)="streetChange.emit($event)" (blur)="verifyAddress()" (keyup.enter)="verifyAddress()"
        [readonly]="readonly" />
    <clr-control-helper *ngIf="addressEnrichmentEnabled && !readonly">Please enter a street address to see suggestions.
    </clr-control-helper>
    <clr-control-error>Please enter only
        alphanumeric characters (A-Z, a-z, 0-9).</clr-control-error>
</clr-input-container>

<ng-container *ngIf="isAddressLookupInProgress || isAddressLookupCompleted">
    <div *ngIf="isAddressLookupInProgress">
        <div style="text-align:center; width:100%;">
            <span class='spinner spinner-sm'></span>
        </div>
    </div>

    <ng-container *ngIf="isAddressLookupCompleted && possibleAddress.length > 0">
        <p>We could not find an exact match for your address. Please review the suggested verified
            address below or edit your previously entered address.
        </p>
        <table style="width: 100%; border-spacing: 0 5px;">
            <colgroup>
                <col style="width: 15px;">
                <col style="width: 10%;">
                <col style="width: 50%;">
                <col style="width: auto;">
                <col style="width: 15px">
            </colgroup>
            <tr *ngFor="let address of possibleAddress" class="row"
                [ngClass]="{ 'highlight': selectedAddress == address }" (click)="selectedAddress = address">
                <td>
                    <!-- Spacing -->
                </td>
                <td>
                    <input type="radio" style="background: black;" [checked]="selectedAddress == address">
                </td>
                <td>
                    <div>{{address.Address}}</div>
                    <div>{{address.City}}, {{address.State}}, {{address.Country}}</div>
                </td>
                <td>
                    <div style="text-align: right;" *ngIf="selectedAddress == address">
                        <button class="btn btn-sm" (click)="useSelectedAddress()">
                            Use this address
                        </button>
                    </div>
                </td>
                <td>
                    <!-- Spacing -->
                </td>
            </tr>
        </table>
    </ng-container>

    <ng-container *ngIf="isAddressLookupCompleted && possibleAddress.length == 0">
        <p class="errorColor">
            We could not identify your address. Please check your street address for accuracy. Or click
            the button to enter your address manually.
        </p>
    </ng-container>

    <br>

    <button *ngIf="isAddressLookupCompleted" class="btn btn-sm" (click)="useCustomAddress()">
        Use custom address
    </button>
</ng-container>

<ng-container *ngIf="!(isAddressLookupInProgress || isAddressLookupCompleted)">
    <clr-input-container>
        <label for="City">City</label>
        <input clrInput id="City" name="City" required pattern="^$|[\x00-\x7F]+" [ngModel]="city"
            (ngModelChange)="cityChange.emit($event)" [disabled]="addressEnrichmentEnabled && !readonly"
            [readonly]="readonly" />
        <clr-control-error>Please enter only
            alphanumeric characters (A-Z, a-z, 0-9).</clr-control-error>
    </clr-input-container>

    <clr-input-container>
        <label for="PostalCode">Postal Code</label>
        <input clrInput id="PostalCode" name="PostalCode" pattern="^$|[\x00-\x7F]+" [ngModel]="postal"
            (ngModelChange)="postalChange.emit($event)" [disabled]="addressEnrichmentEnabled && !readonly"
            [readonly]="readonly" />
        <clr-control-error>Please enter only
            alphanumeric characters (A-Z, a-z, 0-9).</clr-control-error>
    </clr-input-container>

    <clr-input-container>
        <label for="State">State</label>
        <input clrInput id="State" name="State" pattern="^$|[\x00-\x7F]+" [ngModel]="state"
            (ngModelChange)="stateChange.emit($event)" [disabled]="addressEnrichmentEnabled && !readonly"
            [readonly]="readonly" />
        <clr-control-error>Please enter only
            alphanumeric characters (A-Z, a-z, 0-9).</clr-control-error>
    </clr-input-container>

    <clr-input-container *ngIf="addressEnrichmentEnabled || readonly">
        <label for="Country">Country</label>
        <input clrInput id="Country" name="Country" [ngModel]="country" (ngModelChange)="countryChange.emit($event)"
            [disabled]="addressEnrichmentEnabled && !readonly" [readonly]="readonly">
    </clr-input-container>
    <clr-select-container *ngIf="!(addressEnrichmentEnabled || readonly)">
        <label for="Country">Country</label>
        <select clrSelect id="Country" name="Country" [ngModel]="country" (ngModelChange)="countryChange.emit($event)"
            required>
            <option *ngFor="let country of countryList" [ngValue]="country">{{country}}</option>
        </select>
    </clr-select-container>

    <button *ngIf="addressEnrichmentEnabled && !readonly" class="btn btn-sm" style="margin-top:10px"
        (click)="useCustomAddress()">
        Use custom address
    </button>

    <button *ngIf="!addressEnrichmentEnabled && !readonly" class="btn btn-sm" style="margin-top:10px"
        (click)="useAddressEnrichment()">
        Use address enrichment
    </button>
</ng-container>