//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

// https://webcake.co/object-properties-in-angular-2s-ngfor/

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'keyValues'})
export class KeyValuesPipe implements PipeTransform {
    transform(obj: any, noNullValue:boolean, sortKeys:boolean): Object[] {
        let keyArr: any[] = Object.keys(obj);
        let dataArr:any[] = [];

        if (sortKeys) {
            keyArr.sort();
        }

        keyArr.forEach((key: any) => {
            let value = obj[key];
            if (!noNullValue || (noNullValue && value != null))
            {
                dataArr.push({key: key, value: value})
            }
        });

        return dataArr;
    }
}
