//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

export class Subscription {
    userEmail: string;
    startDate: string;
    validityDate: string;
    tecsDate: string;
    renewalPeriod: string;
    productName: string;
    partNumber: string;
    productCategory: string;
    fnoActivationId: string;
    constructor (sub : Subscription) {
        this.userEmail = sub.userEmail;
        this.startDate = sub.startDate;
        this.validityDate = sub.validityDate;
        this.tecsDate = sub.tecsDate;
        this.renewalPeriod = sub.renewalPeriod;
        this.productName = sub.productName;
        this.partNumber = sub.partNumber;
        this.productCategory = sub.productCategory;
        this.fnoActivationId = sub.fnoActivationId;
    }
}


export interface SubscriptionClassifier {
	matchesWith(subscription: Subscription):boolean;
}

export class NameAndDateClassifier implements SubscriptionClassifier {
    constructor(subscription: Subscription) {
        this.productName = subscription.productName;
        this.startDate = subscription.startDate;
        this.validityDate = subscription.validityDate;
        this.tecsDate = subscription.tecsDate;
    }

    matchesWith(subscription: Subscription): boolean {
        return this.productName === subscription.productName && 
            this.startDate === subscription.startDate && 
            this.validityDate === subscription.validityDate && 
            this.tecsDate === subscription.tecsDate;
    }

    productName: string;
    startDate: string;
    validityDate: string;
    tecsDate: string;
}

export class ProductClassifier implements SubscriptionClassifier {
    constructor(subscription: Subscription) {
        this.name = subscription.productName;
        this.partNumber = subscription.partNumber;
    }

    matchesWith(subscription: Subscription): boolean {
        return this.partNumber === subscription.partNumber;
    }

    name: string;
    partNumber: string;
}

export class SubscriptionGroup<Key extends SubscriptionClassifier>{
    constructor(key: Key) {
        this.key = key;
    }

    key: Key;

    subscriptions: Subscription[] = [];
}

export class SubscriptionGroupUtils {
    static groupBy<Key extends SubscriptionClassifier>(subscriptions: Subscription[], getClassifer: {(subscription: Subscription): Key}): SubscriptionGroup<Key>[] {
        let groupedSubscriptions: SubscriptionGroup<Key>[] = [];
        for (let subscription of subscriptions) {
            let group = groupedSubscriptions.find(s => s.key.matchesWith(subscription));
            if (!group) {
                group = new SubscriptionGroup(getClassifer(subscription));
                groupedSubscriptions.push(group);
            }
            group.subscriptions.push(subscription);
        }
        return groupedSubscriptions;
    }
}

