//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

import { environment } from '@environments/environment';

import { UserManager, UserManagerEvents, User, UserManagerSettings } from 'oidc-client';

import { Observable, fromEventPattern, merge } from '@rxjs';
import { publishReplay, refCount, map, mapTo, tap, filter } from '@rxjs/operators';

interface WindowWithUserManager extends Window {
  mgr: UserManager;
}

declare const window: WindowWithUserManager;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly settings: UserManagerSettings = {
    authority: environment.oidc,
    client_id: environment.oidcClientId,
    redirect_uri: this.document.location.origin + '/signin.html',
    post_logout_redirect_uri: this.document.location.origin + '/signout.html',
    silent_redirect_uri: this.document.location.origin + '/silent.html',
    response_type: 'code',
    response_mode: 'query',
    scope: 'openid profile company email address phone marketing_consent admin',
    automaticSilentRenew: true,
  };

  private mgr = new UserManager(this.settings);

  private user: Observable<User | null>;

  public token: Observable<string | null>;

  public onLoggedIn: Observable<void>;

  public onLoggedOut: Observable<void>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {
    const userLoaded = fromEventPattern<User>((handler: UserManagerEvents.UserLoadedCallback) => this.mgr.events.addUserLoaded(handler), (handler: UserManagerEvents.UserLoadedCallback) => this.mgr.events.removeUserLoaded(handler));
    const userUnloaded = fromEventPattern<void>((handler: UserManagerEvents.UserUnloadedCallback) => this.mgr.events.addUserUnloaded(handler), (handler: UserManagerEvents.UserUnloadedCallback) => this.mgr.events.removeUserUnloaded(handler)).pipe(mapTo(null));
    const currentUser = this.mgr.getUser();

    // since userLoaded emits on every new token, don't want to send loggedIn event on every token, only when a token becomes present
    let loggedIn = currentUser != null;
    this.onLoggedIn = userLoaded.pipe(
      filter(_ => !loggedIn),
      tap(_ => loggedIn = true),
      map(_ => { })
    );
    this.onLoggedOut = userUnloaded.pipe(
      filter(_ => loggedIn),
      tap(_ => loggedIn = false),
      map(_ => { })
    );

    this.user = merge(userLoaded, userUnloaded, currentUser).pipe(
      publishReplay(1),
      refCount()
    );
    this.token = this.user.pipe(
      map(user => user ? user.access_token : null)
    );
  }

  public async login() {
    await this.mgr.signinRedirect({ state: this.router.url });
  }

  public async logout() {
    await this.mgr.signoutRedirect();
  }

}
