<ng-container *ngIf="!enterpriseService.isSubscriptionsRequestCompleted" >
    <h4 class="centerText" i18n>Please wait. Refreshing subscriptions, retrieving data from licensing service.</h4>
        <div class="progress">
            <progress max="1" [value]="enterpriseService.getSubscriptionRequestProgression()"></progress>
        </div>
</ng-container>

<div class="card" *ngIf="enterpriseService.isSubscriptionsRequestCompleted">
    <div class="card-header">
        <span i18n>Company Subscriptions</span>

        <clr-tooltip>
            <button
                clrTooltipTrigger
                *ngIf="enterpriseService.clientComputedData && !enterpriseService.isSubscriptionsRequestCompleted"
                class="btn btn-icon btn-link"
                (click)="enterpriseService.refreshSubscriptions()"
            >
            <clr-icon shape="refresh"></clr-icon>
            </button>
            <clr-tooltip-content clrPosition="right" clrSize="lg" *clrIfOpen>
                <span i18n>Changes have been made. Refresh to retrieve current data from licensing service.</span>
            </clr-tooltip-content>
        </clr-tooltip>
    </div>
    <div class="card-block">
      <clr-datagrid>
        <clr-dg-column>Product</clr-dg-column>
        <clr-dg-column>Subsciption Used</clr-dg-column>

        <clr-dg-row  *clrDgItems="let subGroup of subscriptionDatagridController.getCollection()"
                    [clrDgItem]="subGroup">
          <clr-dg-cell>{{subGroup.key.name}}</clr-dg-cell>
          <clr-dg-cell>{{getUserFriendlySubscriptionUsage(subGroup)}}</clr-dg-cell>
        </clr-dg-row>
        <clr-dg-placeholder i18n>Your company has no active subscriptions.</clr-dg-placeholder>
        <clr-dg-footer>
          <request-page-presenter [controller]="subscriptionDatagridController"></request-page-presenter>
        </clr-dg-footer>

        <clr-dg-detail *clrIfDetail="let subGroup">
          <clr-dg-detail-header>{{subGroup.key.name}} Details</clr-dg-detail-header>
          <clr-dg-detail-body>
            <clr-datagrid>

              <clr-dg-column i18n>Start Date</clr-dg-column>
              <clr-dg-column i18n>Expiration Date</clr-dg-column>
              <clr-dg-column i18n>Tecs Date</clr-dg-column>
              <clr-dg-column i18n>Assignee</clr-dg-column>

              <clr-dg-row *clrDgItems="let subscription of subGroup.subscriptions" [clrDgItem]="subscription">
                <clr-dg-cell>{{subscription.startDate.substring(0,10)}}</clr-dg-cell>
                <clr-dg-cell>{{subscription.validityDate.substring(0,10)}}</clr-dg-cell>
                <clr-dg-cell>{{subscription.tecsDate.substring(0,10)}}</clr-dg-cell>
                <clr-dg-cell>{{subscription.userEmail ? subscription.userEmail : "Not Assigned"}}</clr-dg-cell>
              </clr-dg-row>

              <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="5">
                  <clr-dg-page-size [clrPageSizeOptions]="[5,10,25,50,100]"></clr-dg-page-size>
                  {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of
                  {{pagination.totalItems}}
                </clr-dg-pagination>
              </clr-dg-footer>

            </clr-datagrid>

          </clr-dg-detail-body>
        </clr-dg-detail>

      </clr-datagrid>
    </div>
</div>
