//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, AfterContentInit, AfterContentChecked, AfterViewInit,  Input, Output, EventEmitter } from '@angular/core';
import { StringUtils } from '@Shared/utils/stringUtils';

/*****************************************************************
 ** The expanding input list displays an optional label and a   **
 ** list of removable items and an add item textbox as follows: **
 *****************************************************************
 *   Label  * Item 1 (-)                                        **
 *          * Item 2 (-)                                        **
 *          * ... Item N (-)                                    **
 *          * [______________] (+)                              **
 ***************************************************************/

 @Component({
  selector: 'expanding-input-list',
  template: `
    <div [id]="Id + '_div'" (window:resize)="SizeChanged = true">
      <label [style.width]="labelwidth" class="autosize-label" [id]="Id + '_label'"> {{label}}</label>
      <ul [id]="Id + '_list'" [style.margin-left]="labelwidth" class="expanding-list" [style.max-height]="MaxHeight" [style.display]="'block'">
          <li *ngFor="let name of ItemList" class="expanding-list-item">
            {{name}}
            <button class="btn btn-sm btn-link btn-icon btn-tree btn-ovrrd" style="padding:0"
            (click)="DeleteItem(name)">
              <clr-icon shape="trash" class="is-solid clr-btn-ovrrd"></clr-icon>
            </button>
          </li>
          <li class="expanding-list-item li-container">
            <autosize-text-input [(value)]="InputValue" [placeholder]="placeholder" [containerclass]="'li-child'" [maxwidth]="MaxWidth" [minwidth]="minwidth" (keydown.enter)="AddItem($event)"></autosize-text-input>
            <button class="btn btn-sm btn-link btn-icon btn-tree btn-ovrrd" style="padding:0" (click)="AddItem()">
              <clr-icon shape="plus-circle" class="is-solid clr-btn-ovrrd"></clr-icon>
            </button>
          </li>
      </ul>
    </div>
  `,
  styleUrls: ['./ui-components.css']
})

export class ExpandingInputList implements AfterContentInit, AfterViewInit, AfterContentChecked {
//#region PrivateMemberVariables
    private m_itemList: string [];
    private m_inputvalue: string = "";
    private m_maxHeight: string = "";
    private m_maxWidth: number = 0;
    private m_id = (Date.now() * Math.random()).toString(); // to avoid id collisions, let's randomize the current time in ms
    private m_sizeChanged = true;  // detect size changes since last calculation
//#endregion PrivateMemberVariables

//#region InputVariables
    @Input() label: string = "";        // label text
    @Input() labelwidth: string = "";
    @Input() placeholder: string = "";
    @Input() minwidth: number = 20;      // minwidth is the minimum number of characters before resizing
    @Input() maxwidth: number = 0;       // maxwidth is the maximum number of characters
    @Input() maxheight: string = "15vh"; // maxheight is the maximum height of the list before scrolling
    @Input() 
    get itemlist(): string[] { return this.m_itemList};         // list of strings to display and modify.
    set itemlist(val: string[]) { this.m_itemList = val};
    
    // output properties
    @Output() itemlistChange: EventEmitter<string[]> = new EventEmitter<string[]>(); // Allows the itemlist to follow [(ngModel)] format.
//#endregion InputVariables    

//#region PublicVariables
  public get ItemList(): string[] { return this.m_itemList; };
  public get InputValue(): string { return this.m_inputvalue; };
  public set InputValue(val: string) { this.m_inputvalue = val; };
  public get MaxHeight(): string { return this.m_maxHeight; };
  public get MaxWidth(): number { return this.m_maxWidth; };
  public get Id(): string { return this.m_id; };
  public get SizeChanged(): boolean { return this.m_sizeChanged; };
  public set SizeChanged(val: boolean) { this.m_sizeChanged = val; };
//#endregion PublicVariables

//#region Initialization
    constructor() {
    }

    ngAfterContentInit() {
      // after we've initialized our content, let's update our member variables.
      this.m_itemList = this.itemlist;
      this.m_maxHeight = this.maxheight;
    }

    ngAfterContentChecked() {
      // If the div is hidden, we can't get its id. We will keep checking until we mark it as available.
      // We also want to adjust when the window is resized, whether or not it's in view. sizeChanged is set on window:resize
      if (this.IsAvailable()) {
        if (this.m_sizeChanged) {
          this.CalculateWidth();
          this.m_sizeChanged = false;
        }
      }
    }

    ngAfterViewInit() {
    }
//#endregion Initialization

//#region ItemManipulation
    public AddItem(event?: KeyboardEvent) {
      if (event !== undefined && !StringUtils.IsNullOrWhiteSpace(this.m_inputvalue)) { event.stopImmediatePropagation(); event.stopPropagation(); event.preventDefault(); }
      if (!StringUtils.IsNullOrWhiteSpace(this.m_inputvalue)) {
        this.m_itemList.push(this.m_inputvalue);
        this.m_inputvalue = "";
        this.itemlistChange.emit(this.m_itemList);
        
        // wait a tick -> allows the content/view to update before scrolling to the bottom
        setTimeout( () => {
            let list = (document.getElementById(this.m_id + '_list') as HTMLElement);
            list.scrollTop = list.scrollHeight - list.clientHeight;
        }, 0);
      }
    }

    public DeleteItem(item: string) {
        this.m_itemList.splice(this.m_itemList.indexOf(item), 1);
        this.itemlistChange.emit(this.m_itemList);
    }
//#endregion ItemManipulation

//#region ElementReady
    IsAvailable(): Boolean {
      return (document.getElementById(this.m_id + '_div') as HTMLElement) !== null;
    }
//#endregion ElementReady

//#region SizingFunctions
    CalculateWidth() {
      // To calculate the width of the text box most accurately, we will grab the width of the parent container
      // then adjust the offset by the width of the label and the button size. The max width will be passed to 
      // the autosize text component for its own evaluation, limited by our maximum size.
      let container = document.getElementById(this.m_id + '_div') as HTMLElement;
      let containerWidth = Number.parseFloat(window.getComputedStyle(container, null).width as string);
      let fontSize = Number.parseFloat(window.getComputedStyle(container, null).fontSize as string) * 0.72;
      let labelWidth = (document.getElementById(this.m_id + "_label") as HTMLElement).offsetWidth;
      let buttonWidth = (fontSize * 4);
      this.m_maxWidth = (containerWidth - labelWidth - buttonWidth)/(fontSize);
    }
//#endregion SizingFunctions
}
