//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { IDatagridController, Filter, Sort } from "./iDatagridController";
import { ClarityFilter } from "@Shared/utils/clarityFilter";

export abstract class DatagridController<ElementType = any> implements IDatagridController<ElementType> {
    private _pageSize: number;

    protected filters: Filter[] | undefined;
    protected sort: Sort | null;
    protected collection:ElementType[];
    protected page: number;
    protected totalCount: number | null;
    protected fetchDataTimer: any = null;

    /* Called when page,sort or filter change. */
    public abstract refresh():void;

    public constructor(page: number = 1, pageSize: number = 10) {
        this.page = page;
        this.pageSize = pageSize;
        this.collection = [];
        this.totalCount = null;
        this.filters = undefined;
        this.sort = null;
    }

    protected cancelFetchDataTimer(): void {
        if (this.fetchDataTimer) {
            clearTimeout(this.fetchDataTimer);
            this.fetchDataTimer = null;
        }
    }

    public previousPage(): void {
        this.page--;
        if (this.page < 1) {
            this.page = 1;
        }
        this.refresh();
    }

    public nextPage(): void {
        this.page++;
        let pageCount:number | null = this.getPageCount();
        if (pageCount !== null && this.page > pageCount) {
            this.page = pageCount;
        }
        this.refresh();
    }

    public canNext(): boolean {
        let pageCount:number | null = this.getPageCount();
        if (pageCount === null)
            return true;
        return this.page < pageCount;
    }

    public canPrevious(): boolean {
        return this.page > 1;
    }

    public getCollection():ElementType[] {
        return this.collection;
    }

    public getPageCount(): number | null {
        if (this._pageSize == 0) {
            return 0;
        }

        if (this.totalCount === null) {
            return null;
        }

        return Math.ceil(this.totalCount / this._pageSize);
    }

    public getStartIndex(): number {
        return (this.getPage() - 1) * this.pageSize;
    }

    public getEndIndex(): number {
        if (this.collection.length == 0) {
            return this.getStartIndex() + this.pageSize;
        }
        return this.getStartIndex() + this.collection.length;
    }

    public getTotalCount(): number | null {
        return this.totalCount;
    }

    public setPage(page:number) {
        this.page = page;
        this.refresh();
    }

    public getPage(): number {
        return this.page;
    }

    public get pageSize(): number {
        return this._pageSize;
    }

    public set pageSize(value: number) {
        this._pageSize = value;
        this.page = 1; // keeping previous value is weird?
    }

    public isLoading(): boolean {
        return false;
    }

    private static filtersEqual(first: Filter[] | undefined, second: Filter[] | undefined) {
        if (first === second) {
            return true;
        }

        if (first === undefined || second === undefined) {
            return false;
        }

        if (first.length != second.length) {
            return false;
        } else {
            for (let filterName in first) {
                let firstFilter: Filter = first[filterName];
                let secondFilter: Filter = second[filterName];

                if (!secondFilter) {
                    return false;
                }

                if (firstFilter.value != secondFilter.value) {
                    return false;
                }

                let secondClarityFilter:ClarityFilter = <ClarityFilter> secondFilter;
                if (secondClarityFilter.changed) {
                    secondClarityFilter.changed = false;
                    return false;
                }
            }
            return true;
        }
    }

    public setFilters(newFilters: Filter[] | undefined) {
        let oldFilters: Filter[] | undefined = this.filters;
        this.filters = newFilters;

        if (!DatagridController.filtersEqual(newFilters, oldFilters)) {
            // Wait for the user to finish his typing before fetching data.
            this.cancelFetchDataTimer();
            this.fetchDataTimer = setTimeout(() => {
                this.onFilterChanged();
            }, 1000);
        }
    }

    protected onFilterChanged() {
        this.page = 1;
        this.totalCount = null;
        this.refresh();
    }

    protected onSortChanged() {
        this.page = 1;
        this.totalCount = null;
        this.refresh();
    }

    public getSort():Sort | null {
        return this.sort;
    }

    public setSort(sort: Sort | null) {
        let defaultSort: Sort = {
            sortBy: null,
            isReversed: false
        };
        let oldSort: Sort = this.sort || defaultSort;
        let compareSort = sort || defaultSort;

        if (oldSort == compareSort) return;
        if (oldSort.isReversed == compareSort.isReversed && oldSort.sortBy == compareSort.sortBy) return;

        this.sort = sort;
        this.onSortChanged();
        this.refresh();
    }

    public getFilters():Filter[] | undefined {
        return this.filters;
    }

    // Call when we want to reput the array at his initial state.
    public reset():void {
        this.totalCount = null;
        this.collection = [];
        this.page = 1;
        this.refresh();
    }

    public getState():any {
        // Only keep property and value for filter. To not send all component information.
        // And to avoid circular reference and having too much data if we want to serialize the state.
        let minimalFilters:Filter[] | undefined;
        if (this.filters) {
            minimalFilters = [];
            for (let filter of this.filters) {
                minimalFilters.push({ value: filter.value, property: filter.property });
            }
        }

        let obj = {
            collection: this.collection,
            page: this.page,
            pageSize: this._pageSize,
            filters: minimalFilters,
            sort: this.sort
        }

        return obj;
    }

    public readState(state:any) {
        this.page = state.page;
        this._pageSize = state.pageSize;
        this.collection = state.collection;
        this.filters = state.filters;
        this.sort = state.sort;
        this.refresh();
    }
}
