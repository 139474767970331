<div class="card">
    <div class="card-header" i18n="@@293e4c1e4468a0a5a83063d607c72ecefe9726a7">
        Invitations
    </div>
    <div class="card-block">
        <clr-datagrid [(clrDgSelected)]="selectedInvitations" [clrDgLoading]="invitationsDatagridController.isLoading()"
            class="hidePlaceholderFilterIcon">
            <clr-dg-action-bar>
                <request-presenter [request]="deleteInvitationsRequest" [alwaysShowContent]="'true'">
                    <ng-template>
                        <button class="btn btn-secondary btn-sm btn-icon" (click)="deleteInvitationsRequest.send()"
                            [disabled]="selectedInvitations.length == 0">
                            <clr-icon shape="minus-circle"></clr-icon>
                            <span i18n>Remove</span>
                        </button>
                    </ng-template>
                </request-presenter>
                <request-presenter [request]="resendInvitationsRequest" [alwaysShowContent]="'true'">
                    <ng-template>
                        <button class="btn btn-secondary btn-sm btn-icon" (click)="isResendInvitationModalOpen = true"
                            [disabled]="selectedInvitations.length == 0">
                            <clr-icon shape="envelope"></clr-icon>
                            <span i18n>Resend</span>
                        </button>
                    </ng-template>
                </request-presenter>
            </clr-dg-action-bar>

            <clr-dg-placeholder i18n>No pending invitations</clr-dg-placeholder>

            <clr-dg-column i18n>Email</clr-dg-column>
            <clr-dg-column i18n>Subscriptions</clr-dg-column>
            <clr-dg-column i18n>Status</clr-dg-column>

            <clr-dg-row *clrDgItems="let invitation of invitationsDatagridController.getCollection()"
                [clrDgItem]="invitation">
                <clr-dg-cell>{{invitation.userEmail}}</clr-dg-cell>
                <clr-dg-cell class="mainColor">
                    <product-count-cell [products]="invitation.subscriptions"></product-count-cell>
                </clr-dg-cell>
                <clr-dg-cell>
                    <ng-container *ngIf="invitation.status == 'Pending'" i18n>Pending</ng-container>
                    <ng-container *ngIf="invitation.status == 'Refused'" i18n>Declined</ng-container>
                </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
                <request-page-presenter [controller]="invitationsDatagridController"></request-page-presenter>
            </clr-dg-footer>
        </clr-datagrid>

        <!-- <button class="btn btn-primary" style="margin-top: 12px" (click)="inviteModal.isOpen = true" i18n="@@54994248457b76cc465035d31d72aee87e2f3b20">
            <clr-icon shape="user"></clr-icon> Invite User
        </button> -->
    </div>
</div>

<!-- add cc email modal -->
<clr-modal [(clrModalOpen)]="isResendInvitationModalOpen" [clrModalStaticBackdrop]="true">
    <h3 class="modal-title" i18n>Resend Invitation</h3>
    <div class="modal-body">
        <request-presenter [request]="resendInvitationsRequest" [locker]="true"></request-presenter>
        <form clrForm clrLayout="vertical" #checkForm="ngForm"
            (ngSubmit)="isResendInvitationModalOpen = false; resendInvitationsRequest.send();">
            <clr-input-container class="full-width">
                <label for=" cc_mail">Optional email to CC</label>
                <input clrInput id="cc_mail" name="mail" email multiple #status="ngModel"
                    [(ngModel)]="resendInvitationMailModel.ccEmail">
            </clr-input-container>
            <br />
        </form>
        <span i18n>Are you sure you want to resend {this.selectedInvitations.length, plural, =1 {1 invitation} other
            {{{this.selectedInvitations.length}} invitationss}}?</span>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="isResendInvitationModalOpen = false" i18n>
            Cancel
        </button>
        <button type="button" class="btn btn-primary"
            (click)="resendInvitationsRequest.send(); isResendInvitationModalOpen = false" i18n>
            Resend Invitation
        </button>
    </div>
</clr-modal>
