//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { MsalService } from './services/msal.service';
import { MsalHttpRequestService } from './services/msalHttpRequest.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    declarations: [
    ],
    providers: [
        MsalService,
        MsalHttpRequestService
    ],
    exports: [
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class MsalModule {
}
