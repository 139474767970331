//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpRequest, HttpRequestType } from "@Shared/utils/httpRequest";
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service"
import { LiteEvent } from "@Shared/utils/liteEvent";
import { Injectable } from "@angular/core";
import { Subscription } from "./subscription";

export class SubscriptionCollection {
    // The request to get subscriptions is fragmented to parts. This events indicate all subscriptions have been fetched
    public onComplete:LiteEvent<Subscription[]>;

    private _getSubscriptionsRequest: HttpRequest;
    private _getSubscriptionsTotalCountRequest:HttpRequest;

    private _org:string | null = null;
    private _pageCount:number | null = null;
    private _pages:Subscription[][] = [];
    // is subscription fetch completed
    private _isCompleted:boolean = false;
    // is the cached subscription updated or modified by web client. If true, user will have the option to refresh the subscriptions
    private _stale = true;

    // Data this collection is storing
    private _subscriptions:Subscription[] = [];

    public constructor(private httpService:MsalHttpRequestService) {
        this._getSubscriptionsRequest = this.httpService.getRequest(HttpRequestType.GET, "userenterprise/subscriptions");
        this._getSubscriptionsTotalCountRequest = this.httpService.getRequest(HttpRequestType.GET, "userenterprise/subscriptionsCount");

        this._getSubscriptionsRequest.query.pageSize = 50;
        this._getSubscriptionsRequest.onStart.on(req => {
            this.checkOrgNotNull();
            req.query.org = this._org;
            req.query.page++; // start at 1 and initialized at 0
        });

        this._getSubscriptionsRequest.onSuccess.on(req => {
            var responseObj = req.responseObj;

            if (responseObj.page != req.query.page) {
                console.warn("Subscriptions pages doesn't correspond");
                this.refresh();
                return;
            }

            var subscriptions = responseObj.subscriptions;
            this._pages.push(subscriptions);
            if (this._pageCount != null && req.query.page > this._pageCount) {
                this.onAllSubscriptionsPagesRetrieved();
            } else {
                this._getSubscriptionsRequest.send();
            }
        });

        this._getSubscriptionsTotalCountRequest.onStart.on(req => {
            this.checkOrgNotNull();
            req.query.org = this._org;
            this._pageCount = null;
        });
        this._getSubscriptionsTotalCountRequest.onSuccess.on(req => {
            let count = req.getReponseObj<number>();
            this._pageCount = Math.ceil(count / this._getSubscriptionsRequest.query.pageSize);
        });

        this.onComplete = new LiteEvent<any[]>();
    }

    private checkOrgNotNull() : void {
        if (this._org == null) {
            throw new Error("Org is null");
        }
    }

    private onAllSubscriptionsPagesRetrieved():void {
        this._subscriptions = [];
        for (let page of this._pages) {
            for (let subscription of page) {
                this._subscriptions.push(subscription);
            }
        }
        this._isCompleted = true;
        this._stale = false;

        this.onComplete.trigger(this._subscriptions);
    }

    public refresh() : void {
        // We need to paginate cause fno request aren't responsive enough.
        this._getSubscriptionsRequest.query.page = 0;
        this._isCompleted = false;
        this._stale = true;
        this._pages = [];

        this._getSubscriptionsTotalCountRequest.send();
        this._getSubscriptionsRequest.send();
    }

    public getProgression():number {
        let startProg = 0.05;  // don't want to start at 0 to see something start
        if (this._pageCount == null || this._pageCount == 0) {
            return startProg;
        }
        return startProg + (((this._getSubscriptionsRequest.query.page - 1) / this._pageCount) * (1-startProg));
    }

    public get isCompleted() : boolean {
        return this._isCompleted;
    }

    public get isLoading() : boolean {
        return this._getSubscriptionsRequest.isLoading;
    }

    public get subscriptions(): Subscription[] {
        return this._subscriptions;
    }

    public get isStale(): boolean {
        return this._stale;
    }

    public setOrg(org:string | null) : void {
        this._org = org;
        this.refresh();
    }
}
