<div style="margin:20px;">


    <request-presenter [request]="subscriptionsRequest" [isLarge]="true">
        <ng-template>
            <ng-container *ngIf="subscriptionsRequest.responseObj.length == 0"
                i18n="@@2177a6ca91fdc2bc2ba7ac0867e807f8fcacef23">
                You have no subscription assigned to your account
            </ng-container>

            <div class="clr-row">
                <div class="clr-col-md-12" *ngIf="subscriptions.length > 0">
                    <h1 i18n>Active</h1>
                </div>
            </div>

            <div class="clr-row">
                <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12 clr-col-xs-12"
                    *ngFor="let subscription of subscriptions">
                    <div class="card">
                        <div class="card-header">
                            {{subscription.product}}
                        </div>
                        <div class="card-block">
                            <ul class="list noBulletList">
                                <li i18n>Start date: <span class="mainColor">{{subscription.from}}</span></li>

                                <ng-container *ngIf="subscription.renewable">
                                    <li>
                                        <span i18n>Expiration date: <span
                                                class="mainColor">{{subscription.to}}</span></span>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="!subscription.renewable">
                                    <li i18n>Expiration date: <span class="errorColor">{{subscription.to}}</span></li>
                                </ng-container>

                                <li i18n>Tecs date: <span class="mainColor">{{subscription.tecs}}</span></li>

                                <li i18n>Activation ID: <span class="mainColor">{{subscription.id}}</span></li>

                            </ul>
                        </div>
                        <div class="card-block" *ngIf="subscription.productCategory !== 'cloud'">
                            <h5 i18n>Activations</h5>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th i18n>Device</th>
                                        <th i18n>Activation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let i of [0, 1]">
                                        <ng-container *ngIf="subscription.devices.length > i; else unused">
                                            <td>{{subscription.devices[i].deviceId}}</td>
                                            <td>
                                                <request-presenter
                                                    [request]="subscription.devices[i].releaseActivationRequest"
                                                    [alwaysShowContent]="'true'">
                                                    <ng-template>
                                                        <button class="btn btn-sm btn-primary-outline"
                                                            (click)="subscription.devices[i].releaseActivationRequest.send()">
                                                            <clr-icon shape="times-circle"></clr-icon> <span
                                                                i18n>Release</span>
                                                        </button>
                                                    </ng-template>
                                                </request-presenter>
                                            </td>
                                        </ng-container>

                                        <ng-template #unused>
                                            <td i18n>Unused</td>
                                            <td>
                                                <button class="btn btn-sm btn-primary-outline" i18n disabled>
                                                    <clr-icon shape="times-circle"></clr-icon> Release
                                                </button>
                                            </td>
                                        </ng-template>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clr-row">
                <div class="clr-col-md-12" *ngIf="expiredSubscriptions.length > 0">
                    <h1 i18n>Expired</h1>
                </div>
            </div>

            <div class="clr-row">
                <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12 clr-col-xs-12"
                    *ngFor="let subscription of expiredSubscriptions">
                    <div class="card">
                        <div class="card-header">
                            {{subscription.product}}
                        </div>
                        <div class="card-block">
                            <ul class="list noBulletList">
                                <li i18n>Start date: <span class="errorColor">{{subscription.from}}</span></li>

                                <ng-container *ngIf="subscription.renewable">
                                    <li>
                                        <span i18n>Expiration date: <span
                                                class="errorColor">{{subscription.to}}</span></span>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="!subscription.renewable">
                                    <li i18n>Expiration date: <span class="errorColor">{{subscription.to}}</span></li>
                                </ng-container>

                                <li i18n>Tecs date: <span class="errorColor">{{subscription.tecs}}</span></li>

                                <li i18n>Activation ID: <span class="errorColor">{{subscription.id}}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </request-presenter>

</div>