//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, AfterContentInit, AfterViewInit, Input, Output, EventEmitter} from '@angular/core';

/****************************************************************************
 ** custom-modal is a standard 2 option modal dialog with the option to    **
 ** use as notification only. It looks as follows by default:              **                    
 ****************************************************************************
 *                                                                         **
 *                                                                         **
 *                   _______________________________                       **
 *                  | ___________Confirm___________ |                      **
 *                  |                               |                      **
 *                  |      (html) Message           |                      **
 *                  |      <custom-element>         |                      **
 *                  |                               |                      **
 *                  |                (Ok) [(Cancel)]|                      **
 *                  |_______________________________|                      **
 *                                                                         **
 *                                                                         **
 *                                                                         **
 ***************************************************************************/

export enum ModalSize {
    Small = 'sm',
    Medium = '',
    Large = 'lg',
    XLarge = 'xl'
}

@Component({
    selector: 'custom-modal',
    template: `
    <!-- General Confirmation/Text Dialogue -->
    <clr-modal class="modal-dialog in out" [clrModalSize]="size" [(clrModalOpen)]="dlgopen" [clrModalStaticBackdrop]="true" [clrModalClosable]="false" (document:keydown.esc)="close()" (document:keydown.enter)="submit()">
        <div class="modal-dlg-header modal-title underline" [innerHTML]="title"></div>
        <div class="modal-body">
            <div *ngIf="message !== ''" [innerHTML]="message"></div>
            <ng-content></ng-content>
        </div>
        <div class="modal-footer" style="padding: 0.25rem 0 0 0;">
            <span [id]="errid" class="red small lefty" style="overflow-y: auto; max-height: 50px"></span>
            <div class="btn-submit-container">
                <button [class]="lbtnclass" id="cdlgleftbtn" (click)="leftclick.emit(null)" >
                    <clr-icon shape="success-standard" class="is-solid"></clr-icon> {{leftbtn}}
                </button>
                <button *ngIf="!notify" [class]="rbtnclass" id="cdlgrightbtn" (click)="rightclick.emit(null)">
                    <clr-icon shape="times-circle" class="is-solid" ></clr-icon> {{rightbtn}}
                </button>
            </div>
        </div>
    </clr-modal>
  `,
    styleUrls: ['./ui-components.css']
})

export class CustomModal implements AfterContentInit, AfterViewInit {
//#region InputVariables
    @Input() size = ModalSize.Medium;               // Set the size of the dialog
    @Input() dlgopen = false;                       // Set whether or not dialog is open
    @Input() title = 'Confirm';                     // Changes the header of the dialog
    @Input() message = '';                          // Standard text message ** If using HTML format, do any styling through classes in the main css file **
    @Input() leftbtn = 'Ok';                        // Left button text
    @Input() rightbtn = 'Cancel';                   // Right button text
    @Input() lefticon = 'success-standard';         // Left button icon
    @Input() righticon = 'times-circle';            // right button icon
    @Input() notify = false;                        // set true to use one button notification mode.
    @Input() ignoreesc = false;                     // disable esc key to close
    @Input() leftcancel = false;                    // set to true if left button cancels and right button performs another action;
    @Input() lbtnclass = 'btn btn-icon btn-submit';         // use button override classes through main css file
    @Input() rbtnclass = 'btn btn-icon btn-submit cancel';  // use button override classes through main css file
    @Input() errid = "error_id"
    @Input() ignoreenter = false;

    @Output() leftclick: EventEmitter<any> = new EventEmitter();
    @Output() rightclick: EventEmitter<any> = new EventEmitter();
//#endregion InputVariables

//#region Initialization
    constructor() {
    }

    ngAfterContentInit() {
    }

    ngAfterViewInit() {
    }
//#endregion Initialization

//#region Closing
    close() {
        // if we allow the user to use esc to exit check if the consumer set left to cancel or it's just a notification
        if (!this.ignoreesc) {
            if(this.leftcancel || this.notify) {
                this.leftclick.emit(null);
            }
            else {
                this.rightclick.emit(null);
            }
        }
    }
    submit() {
        if (!this.ignoreenter) {
            if (this.leftcancel || this.notify) {
                this.rightclick.emit(null);
            } else {
                this.leftclick.emit(null);
            }
        }
    }
//#endregion Closing
}
