<div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 clr-col-xs-12">
  <ng-container *ngIf="activeGlobalError">
    <div class="errorBlock" [innerHtml]="globalErrorMessage"></div>
  </ng-container>

  <request-presenter [request]="isAdminRequest" [isLarge]="true">
    <ng-template>
      <form clrForm>
        <div class="centerText" style="width: 400px; margin:30px auto 30px auto; font-size:20px" *ngIf="orgs.length>1">
          <clr-select-container>
            <select clrSelect id="company" [(ngModel)]="currentOrg" name="company" (change)="onCompanyChange()"
              style="color: #000000; font-size:20px">
              <option *ngFor="let org of orgs" [ngValue]="org.id">{{org.displayName}} ({{org.id}})</option>
            </select>
          </clr-select-container>
        </div>
        <div class="centerText" style="margin:30px auto 30px auto; font-size:20px" *ngIf="orgs.length==1">
          <h3>{{orgs[0].displayName}} ({{orgs[0].id}})</h3>
        </div>
      </form>
    </ng-template>
  </request-presenter>

  <ng-container *ngIf="currentOrg">
    <subscriptions-summary></subscriptions-summary>
    <users-enterprise [enterpriseComponent]="this"></users-enterprise>
    <invitations-enterprise></invitations-enterprise>
    <admins-enterprise></admins-enterprise>
  </ng-container>
</div>