<div class="card">
  <div class="card-header" i18n="@@b9ba68952e1003c654348d8d0cc5aa9a4aae386e">
    Users
  </div>
  <div class="card-block">
    <div
      class="errorBlock"
      *ngIf="userErrorMessage"
      [innerHtml]="userErrorMessage"
    ></div>
    <clr-datagrid [(clrDgSelected)]="selectedUsers"
                  [clrDgLoading]="isUsersLoading"
                  class="hidePlaceholderFilterIcon datagrid-with-large-action-bar">
      <clr-dg-action-bar style="margin-top: 0">
        <div class="action-bar-container">
          <div class="action-bar-buttons">
            <div class="btn-group">
              <button class="btn btn-secondary btn-sm btn-icon"
                      [disabled]="selectedUsers.length == 0 || subGroupRows.length == 0"
                      (click)="openAssignModal()">
                <clr-icon shape="plus-circle"></clr-icon>
                <span i18n>Assign</span>
              </button>
              <button class="btn btn-secondary btn-sm btn-icon" 
                      [style]="(selectedUsers.length == 0 || subGroupRows.length == 0) ? 'border-left: solid 1px' : ''"
                      [disabled]="selectedUsers.length == 0"
                      (click)="isUnassignModalOpen=true">
                <clr-icon shape="minus-circle"></clr-icon>
                <span i18n>Unassign</span>
              </button>
            </div>
            <button class="btn btn-secondary btn-sm btn-icon"
                    (click)="addUserModal.isOpen = true; enterpriseComponent.closeErrorMessage()">
              <clr-icon shape="user"></clr-icon>
              <span i18n>Add user</span>
            </button>
            <button class="btn btn-secondary btn-sm btn-icon"
                    [disabled]="selectedUsers.length == 0 || isCurrentUserSelected"
                    (click)="isRemoveUserModalOpen=true">
              <clr-icon shape="user"></clr-icon>
              <span i18n>Remove user</span>
            </button>
          </div>
          <div class="action-bar-user-lookup">
            <clr-input-container *ngIf="isUserLookup" style="display: inline-block; margin-top: 0">
              <label>Email Lookup</label>
              <input clrInput [(ngModel)]="userByEmailSearch">
              <clr-control-helper *ngIf="isUserLookupError">{{isUserLookupError}}</clr-control-helper>
            </clr-input-container>
            <button *ngIf="isUserLookup" class="btn btn-secondary btn-sm btn-icon"
                    (click)="handleSubmitSearchByUserEmail()">
              <clr-icon shape="search"></clr-icon>
              <span i18n>Search</span>
            </button>
            <clr-toggle-wrapper style="display: inline-block; margin-top: 0">
              <input type="checkbox" clrToggle [ngModel]="isUserLookup"
                     (ngModelChange)="handleSwitchUserLookup()" />
              <label>Use Email Lookup</label>
            </clr-toggle-wrapper>
          </div>
        </div>
        <addUserModal #addUserModal [enterpriseComponent]="enterpriseComponent" (refresh)="handleAddUserModalRefresh()">
        </addUserModal>
      </clr-dg-action-bar>

      <clr-dg-column i18n>First Name</clr-dg-column>
      <clr-dg-column i18n>Last Name</clr-dg-column>
      <clr-dg-column i18n>Email</clr-dg-column>
      <clr-dg-column i18n>Subscriptions</clr-dg-column>

      <clr-dg-row *clrDgItems="let user of users; index as i" [clrDgItem]="user">
        <clr-dg-cell>{{user.givenName}}</clr-dg-cell>
        <clr-dg-cell>{{user.surname}}</clr-dg-cell>
        <clr-dg-cell>{{user.email}}</clr-dg-cell>
        <clr-dg-cell class="mainColor">
          <product-count-cell [userEmail]="user.email" [tablePositionIndex]="i" [tableTotalRowCount]="users.length"></product-count-cell>
        </clr-dg-cell>
      </clr-dg-row>
      <clr-dg-footer *ngIf="!isUserLookup">
        <request-page-presenter [controller]="usersDatagridController"></request-page-presenter>
      </clr-dg-footer>
      <clr-dg-detail *clrIfDetail="let user">
        <clr-dg-detail-header>Subscriptions for User: {{user.givenName}} {{user.surname}} ({{user.email}})
        </clr-dg-detail-header>
        <clr-dg-detail-body>
          <clr-datagrid>
            <clr-dg-column i18n>Subscription</clr-dg-column>
            <clr-dg-column i18n>Start Date</clr-dg-column>
            <clr-dg-column i18n>Expiration Date</clr-dg-column>
            <clr-dg-column i18n>Tecs Date</clr-dg-column>
            <clr-dg-placeholder i18n>This user has no subscriptions assigned.</clr-dg-placeholder>
            <clr-dg-row *clrDgItems="let subscription of enterpriseService.getSubscriptionsForUser(user.email)"
              [clrDgItem]="subscription">
              <clr-dg-cell>{{subscription.productName}}</clr-dg-cell>
              <clr-dg-cell>{{subscription.startDate.substring(0,10)}}</clr-dg-cell>
              <clr-dg-cell>{{subscription.validityDate.substring(0,10)}}</clr-dg-cell>
              <clr-dg-cell>{{subscription.tecsDate.substring(0,10)}}</clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
              <clr-dg-pagination #pagination [clrDgPageSize]="5">
                <clr-dg-page-size [clrPageSizeOptions]="[5,10,25,50,100]"></clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of
                {{pagination.totalItems}}
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </clr-dg-detail-body>
      </clr-dg-detail>
    </clr-datagrid>
  </div>
</div>

<!-- assign subscription modal -->
<clr-modal [(clrModalOpen)]="isAssignModalOpen" [clrModalStaticBackdrop]="true" clrModalSize="xl">
  <h3 class="modal-title" i18n>
    Assign Subscription 
      <span class="mainColor">
        ({selectedUsers.length, plural, =1 {1 user} other {{{selectedUsers.length}} users}})
      </span>
  </h3>
    <div class="modal-body">
      <div
            class="errorBlock"
            *ngIf="assignSubscriptionErrorMessage"
            [innerHtml]="assignSubscriptionErrorMessage"
      ></div>
      <loading-presenter [isLoading]="enterpriseService.isAssignSubscriptionsInProgress" [isCenter]="true"></loading-presenter>
      <locker [enabled]="enterpriseService.isAssignSubscriptionsInProgress"></locker>
        <subscription-group-table [numberOfAssignees]="selectedUsers.length" [subGroupRows]="subGroupRows"></subscription-group-table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="isAssignModalOpen = false" i18n>
            Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="onSubmitAssign()" [disabled]="!assignProductIsValid()" i18n>
            Assign
        </button>
    </div>
</clr-modal>
<!-- unassign subscriptions modal -->
<clr-modal [(clrModalOpen)]="isUnassignModalOpen" [clrModalStaticBackdrop]="true">
    <h3 class="modal-title" i18n>Unassign Subscription <span class="mainColor">({selectedUsers.length, plural, =1 {1
            user} other {{{selectedUsers.length}} users}})</span></h3>
    <div class="modal-body">
      <div
            class="errorBlock"
            *ngIf="unassignSubscriptionErrorMessage"
            [innerHtml]="unassignSubscriptionErrorMessage"
      ></div>
      <loading-presenter [isLoading]="enterpriseService.isUnassignSubscriptionsInProgress" [isCenter]="true"></loading-presenter>
      <locker [enabled]="enterpriseService.isUnassignSubscriptionsInProgress"></locker>
        <div i18n>
            Which product do you want to unassign for the selected {selectedUsers.length, plural, =1 {user} other
            {users}}?
        </div>
        <clr-select-container class="full-width" style="margin-bottom: 5px">
            <label for="product" i18n>Product</label>
            <select clrSelect id="product" [(ngModel)]="unassignProductName" name="unassignProductName">
                <option value="" disabled i18n>Select a product</option>
                <option value="_ALL_PRODUCT_" i18n>All products</option>
                <option *ngFor="let p of getUnassignAvailableProducts()" [ngValue]="concatPartNumberAndNameOfProduct(p.partNumber, p.name)">{{p.name}}
                </option>
            </select>
        </clr-select-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="isUnassignModalOpen = false" i18n>
            Cancel
        </button>
        <button type="submit" class="btn btn-primary" (click)="onSubmitUnassign()"
            [disabled]="!unassignProductIsValid()" i18n>
            Unassign
        </button>
    </div>
</clr-modal>
<!-- remove users modal -->
<clr-modal [(clrModalOpen)]="isRemoveUserModalOpen" [clrModalSize]="'lg'" [clrModalStaticBackdrop]="true">
        <h3 class="modal-title" i18n>
            <b>Remove users from company</b>
        </h3>
        <div class="modal-body">
          <div
            class="errorBlock"
            *ngIf="removeUsersErrorMessage"
            [innerHtml]="removeUsersErrorMessage"
          ></div>
        <loading-presenter [isLoading]="enterpriseService.isRemoveUsersInProgress" [isCenter]="true"></loading-presenter>
        <locker [enabled]="enterpriseService.isRemoveUsersInProgress"></locker>
        <div i18n>
            The following users will <strong>no longer have access</strong> to Ansys Cloud and Discovery subscriptions
            from {{enterpriseService.getOrganization()}}. All subscriptions will be unassigned and associated Ansys
            Cloud storage account data <strong>will be permanently deleted</strong>.
        </div>
        <clr-datagrid>
            <clr-dg-column i18n>First Name</clr-dg-column>
            <clr-dg-column i18n>Last Name</clr-dg-column>
            <clr-dg-column i18n>Email</clr-dg-column>
            <clr-dg-column i18n>Subscriptions</clr-dg-column>
            <clr-dg-row *clrDgItems="let user of selectedUsers; index as i" [clrDgItem]="user">
                <clr-dg-cell>{{user.givenName}}</clr-dg-cell>
                <clr-dg-cell>{{user.surname}}</clr-dg-cell>
                <clr-dg-cell>{{user.email}}</clr-dg-cell>
                <clr-dg-cell class="mainColor">
                  <product-count-cell [userEmail]="user.email" [tablePositionIndex]="i" [tableTotalRowCount]="selectedUsers.length"></product-count-cell>
                </clr-dg-cell>
            </clr-dg-row>
        </clr-datagrid>
        <div class="alert alert-info" role="alert">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                    </div>
                    <span class="alert-text">
                        Users listed above will not be removed from other Ansys services.
                    </span>
                    <div class="tooltip tooltip-lg tooltip-top-left" role="tooltip">
                        Details
                        <span class="tooltip-content">
                            When a user is removed, they only lose access to subscriptions managed by this subscription
                            list (Ansys Cloud Essentials, Ansys Cloud Storage, and/or Discovery products). Access to
                            other portals, subscriptions, or Ansys applications that use Ansys ID SSO will not be
                            affected. To remove users from other Ansys ID SSO-managed products, use the account
                            management tools for those products. Please contact support@ansys.com with any questions.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="isRemoveUserModalOpen = false" i18n>
            Cancel
        </button>
        <button type="submit" class="btn btn-danger" (click)="onSubmitRemoveUser()" i18n>
            Remove Users
        </button>
    </div>
</clr-modal>
