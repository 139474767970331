//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, EmailValidator } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ObjectViewComponent } from './components/object-view.component';
import { LockerComponent } from './components/locker.component';
import { LoadingPresenterComponent } from './components/loadingPresenter.component';
import { RequestPresenterComponent, RequestPresenterDirectives } from './components/requestPresenter.component';
import { RequestFragmenterPresenterComponent } from './components/requestFragmenterPresenter.component';
import { RequestPagePresenterComponent } from './components/requestPagePresenter';
import { UserFormComponent } from './components/userForm.component';
import { SelectFilterComponent } from './components/selectFilter.component';
import { DateFilterComponent } from './components/dateFilter.component';
import { OneFieldSearchComponent } from './components/oneFieldSearch.component';
import { StatusComponent } from './components/status.component';
import { UserDataService } from './services/userData.service';
import { KeyValuesPipe } from './pipes/keyValuesPipe';
import { FileSizePipe } from './pipes/fileSizePipe';
import { UseKeyPipe } from './pipes/useKeyPipe';
import { MutuallyExclusiveList } from './ui-components/mutuallyExclusiveList.component';
import { MutuallyExclusiveListBox } from './ui-components/mutuallyExclusiveListBox.component';
import { ExpandingInputList } from './ui-components/expandingInputList.component';
import { AutoSizingTextInput } from './ui-components/autoSizingTextInput.component';
import { ComboBox } from './ui-components/comboBox.component';
import { CustomModal } from './ui-components/customModalDialog.component';
import { ClarityModule } from '@clr/angular';
import { AddressComponent } from './components/address/address.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ClarityModule
    ],
    declarations: [
        KeyValuesPipe,
        FileSizePipe,
        UseKeyPipe,
        AddressComponent,
        ObjectViewComponent,
        UserFormComponent,
        LockerComponent,
        LoadingPresenterComponent,
        RequestPresenterDirectives,
        RequestFragmenterPresenterComponent,
        RequestPagePresenterComponent,
        SelectFilterComponent,
        DateFilterComponent,
        OneFieldSearchComponent,
        StatusComponent,
        MutuallyExclusiveList,
        MutuallyExclusiveListBox,
        AutoSizingTextInput,
        ExpandingInputList,
        CustomModal,
        ComboBox
    ],
    providers: [],
    exports: [
        KeyValuesPipe,
        FileSizePipe,
        UseKeyPipe,
        AddressComponent,
        ObjectViewComponent,
        UserFormComponent,
        LockerComponent,
        LoadingPresenterComponent,
        RequestPresenterDirectives,
        RequestFragmenterPresenterComponent,
        RequestPagePresenterComponent,
        SelectFilterComponent,
        DateFilterComponent,
        OneFieldSearchComponent,
        StatusComponent,
        MutuallyExclusiveList,
        AutoSizingTextInput,
        ExpandingInputList,
        CustomModal,
        ComboBox
    ]
})
export class SharedModule {
}
