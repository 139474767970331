//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input } from '@angular/core';
import { ClrDatagridFilter } from "@clr/angular";
import { StringUtils } from '@Shared/utils/stringUtils';
import { ClarityFilter } from "@Shared/utils/clarityFilter"

@Component({
    selector: "date-filter",
    template: `
        <input type="date" [(ngModel)]="dateStr" name="date">
    `
})
export class DateFilterComponent extends ClarityFilter<Date> {
    @Input() public property: string;

    private _dateStr: string;
    public date: Date | null = null;

    public constructor(private filterContainer: ClrDatagridFilter) {
        super();

        filterContainer.setFilter(this);
        this.date = null;
        this._dateStr = "";
    }

    public get dateStr(): string {
        return this._dateStr;
    }

    public set dateStr(dateStr: string) {
        this._dateStr = dateStr;

        if (StringUtils.IsNullOrEmpty(dateStr)) {
            this.date = null;
        } else {
            this.date = new Date(<string>dateStr);
        }

        this.triggerChange(this.date);
    }

    public get value(): string {
        return this._dateStr;
    }

    public accepts(value: Date): boolean {
        return this._dateStr == value.toString();
    }
}
