//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { IDatagridController } from '../utils/iDatagridController';

@Component({
    selector: 'request-page-presenter',
    template: `
    <span style="width:200px">
        <label for="pageSize" style="display:inline-block" i18n>Show</label>
        &nbsp;
        <span>
            <select style="display:inline-block;"
                id="pageSize"
                name="pageSize"
                [(ngModel)]="controller.pageSize"
                [disabled]="controller.isLoading()"
                (change)="onPageSizeChange()"
            >
                <!-- +i transform the str in number, need ngValue to store a number -->
                <option *ngFor="let i of [5, 10, 25, 50, 100]" [ngValue]="+i">{{i}}</option>
            </select>
        </span>
    </span>
    &nbsp;|&nbsp;
    <button
        class="btn btn-sm btn-icon btn-secondary"
        [ngClass]="{ 'btn-link': compact }"
        *ngIf="!controller.isLoading()"
        (click)="onPreviousPage()"
        [disabled]="!controller.canPrevious()"
        style="margin: 0px"
    >
    <clr-icon shape="caret left"></clr-icon>
    </button>
    <span i18n="@@pageIndexes">&nbsp;{{controller.getStartIndex() + 1}} to {{controller.getEndIndex()}}&nbsp;</span>
    <ng-container>
        &nbsp;
        <span *ngIf="controller.getTotalCount()" i18n="@@pagePresenterTotal">
            (Total: {{controller.getTotalCount()}})
        </span>
        &nbsp;
    </ng-container>
    <button
        class="btn btn-sm btn-icon btn-secondary"
        [ngClass]="{ 'btn-link': compact }"
        *ngIf="!controller.isLoading()"
        (click)="onNextPage()"
        [disabled]="!controller.canNext()"
        style="margin: 0px"
    >
    <clr-icon shape="caret right"></clr-icon>
    </button>
    `
})
export class RequestPagePresenterComponent implements OnInit {
    @Input("controller") controller:IDatagridController;
    @Input("compact") compact:boolean;

    @Output()
    public propertyChange:EventEmitter<string>;

    public constructor() {
        this.propertyChange = new EventEmitter<string>();
    }

    public ngOnInit(): void {
        if (this.controller == null) {
            throw new Error('No page manager.');
        }
    }

    public onPageSizeChange(): void {
        this.controller.refresh();
        this.propertyChange.emit("pageSize");
    }

    public onNextPage(): void {
        this.controller.nextPage();
        this.propertyChange.emit("page");
    }

    public onPreviousPage(): void {
        this.controller.previousPage();
        this.propertyChange.emit("page");
    }
}
