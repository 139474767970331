//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input } from "@angular/core";
import { EnterpriseService } from "./enterprise.service";

@Component({
    selector: "product-count-cell",
    template: `
    <clr-tooltip>
        <div *ngIf="shouldShowSubscriptionsCount()" clrTooltipTrigger>
            {{getSubscriptionsCount()}}
        </div>
        <div *ngIf="!shouldShowSubscriptionsCount()">
            {{getUserFriendlySubscriptionsList()}}
        </div>
        <clr-tooltip-content [clrPosition]="getTooltipPosition()" clrSize="lg" *clrIfOpen>
            <span>
                {{getUserFriendlySubscriptionsList()}}
            </span>
        </clr-tooltip-content>
    </clr-tooltip>
    `
})
export class ProductCountCell {
    @Input() public userEmail: string;
    @Input() public tablePositionIndex: number;
    @Input() public tableTotalRowCount: number;

    public readonly minimum: number = 4;

    public constructor(public enterpriseService: EnterpriseService) {

    }

    public getTooltipPosition(): string {
        return this.tablePositionIndex < this.getSubscriptionsCount() ? 'bottom-left' : 'top-left';
    }

    public getUserFriendlySubscriptionsList(): string {
        let groupedSubscriptions = this.enterpriseService.getGroupedSubscriptionsForUser(this.userEmail);
        if (groupedSubscriptions.length == 0)
            return "This user does not have any active subscriptions.";

        return groupedSubscriptions.map(subGroup => {
            return subGroup.key.name + (subGroup.subscriptions.length > 1 ? `(${subGroup.subscriptions.length}x)` : "");
        }).join(', ');
    }

    public getSubscriptionsCount(): number {
        return this.enterpriseService.getSubscriptionsForUser(this.userEmail).length;
    }

    public shouldShowSubscriptionsCount(): boolean {
        return (this.tableTotalRowCount >= this.minimum) && (this.getSubscriptionsCount() != 1);
    }
}
