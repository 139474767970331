//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, OnInit } from "@angular/core";
import { ClientDatagridController } from "@Shared/utils/clientDatagridController";
import { HttpRequest, HttpRequestType } from "@Shared/utils/httpRequest";
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service";
import { EnterpriseService } from "./enterprise.service";

@Component({
    selector: 'invitations-enterprise',
    templateUrl: './invitations.component.html',
})
export class InvitationsComponent implements OnInit {
    public invitationsDatagridController:ClientDatagridController;
    public selectedInvitations:any[] = [];
    public deleteInvitationsRequest:HttpRequest;
    public resendInvitationsRequest:HttpRequest;

    public isResendInvitationModalOpen = false;
    public resendInvitationMailModel = {"ccEmail" : ""};

    public constructor(private httpService:MsalHttpRequestService, public enterpriseService:EnterpriseService) {

    }

    ngOnInit(): void {
        this.deleteInvitationsRequest = this.httpService.getRequest(HttpRequestType.POST, "userenterprise/deleteInvitations");
        this.resendInvitationsRequest = this.httpService.getRequest(HttpRequestType.POST, "userenterprise/resendInvitations");

        this.invitationsDatagridController = new ClientDatagridController(1, 10);

        this.resendInvitationsRequest.onStart.on(req => {
            req.query.org = this.enterpriseService.getOrganization();
            req.body.ccEmail = this.resendInvitationMailModel.ccEmail.split(',');
            req.body.invitationIds = this.getSelectedInvitationsIds();
        });

        this.resendInvitationsRequest.onSuccess.on(req => {
            this.enterpriseService.refreshInvitations();
        });

        this.deleteInvitationsRequest.onStart.on(req => {
            req.query.org = this.enterpriseService.getOrganization();
            req.body = this.getSelectedInvitationsIds();
        });

        this.deleteInvitationsRequest.onSuccess.on(req => {
            for (let invitation of this.selectedInvitations) {
                for (let subscription of invitation.subscriptions) {
                    this.enterpriseService.clientRemoveInvitation(subscription);
                }
            }

            this.enterpriseService.refreshInvitations();
        });

        this.enterpriseService.invitationCollection.onStart.on(_ => {
            this.invitationsDatagridController.setEntireCollection(null);
        });

        this.enterpriseService.invitationCollection.onComplete.on(invitations => {
            this.invitationsDatagridController.setEntireCollection(invitations);
            this.selectedInvitations = [];
        });
    }

    private getSelectedInvitationsIds():string[] {
        let invitationIds:string[] = [];
        this.selectedInvitations.forEach(invitation => {
            invitationIds.push(invitation.id);
        });
        return invitationIds;
    }
}
