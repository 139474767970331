//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

export enum TypeStatus {
    info,
    success,
    danger,
    warning
}

export interface Status {
    label:string;
    type:TypeStatus | null;
}

@Component({
    selector: 'status',
    template: `
        <span class="label" style="vertical-align:text-top" [ngClass]="typeStatusClass">{{status.label}}</span>
    `
})
export class StatusComponent implements OnChanges {
    @Input() public status:Status;

    public typeStatusClass:string;

    public constructor() {
        this.status = {
            label: "",
            type: TypeStatus.info
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.status.type == null)
            this.typeStatusClass = "label";
        else
            this.typeStatusClass = "label-" + TypeStatus[this.status.type];
    }
}
