//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'one-field-search',
    styles: [`
        label {
            margin-right: 25px;
        }

        input, button {
            margin-top:0px;
        }

        input {
            margin-right: 10px;
        }
    `],
    template: `
    <form clrForm clrLayout="vertical">
        <clr-control-container>
            <label for="field">{{label}}</label>
            <input clrControl type="text" id="field" placeholder="{{placeholder}}" [(ngModel)]="value" size="30" name="field" [disabled]="loading" (change)="value=value ? value.trim() : null"/>
            <button class="btn btn-primary btn-sm" (click)="onClick()" [disabled]="!value || loading">
                Search
            </button>
        </clr-control-container>
    </form>
    `
})
export class OneFieldSearchComponent {
    @Input()
    public label: string;

    @Input()
    public placeholder: string;

    @Input()
    public loading: boolean;

    private _value: string | null;
    @Input()
    public set value(v: string | null) {
        this._value = v;
        this.valueChange.emit(v);
    }
    public get value(): string | null {
        return this._value;
    }

    @Output()
    public valueChange: EventEmitter<string | null>;

    @Output()
    public searchClicked: EventEmitter<string>;

    constructor() {
        this.searchClicked = new EventEmitter<string>();
        this.valueChange = new EventEmitter<string | null>();
    }

    public onClick(): void {
        this.searchClicked.emit(<string>this.value);
    }
}
