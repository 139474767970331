//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpRequest, HttpRequestType } from "@Shared/utils/httpRequest";
import { HttpRequestFragmenter } from "@Shared/utils/httpRequestFragmenter";
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service"
import { LiteEvent } from "@Shared/utils/liteEvent";

export interface Invitation {
    id:string;
    subscriptions:string[];
    userEmail:string;
    status:string;
}

export class InvitationCollection {
    public getInvitationsRequest: HttpRequest;
    public getInvitationsRequestFragmenter:HttpRequestFragmenter;

    private _org:string | null;
    private _invitations:Invitation[] = [];

    public onStart:LiteEvent<null>;
    public onComplete:LiteEvent<Invitation[]>;

    public constructor(private httpService:MsalHttpRequestService) {
        this.getInvitationsRequest = this.httpService.getRequest(HttpRequestType.GET, "userenterprise/invitations");

        this.getInvitationsRequestFragmenter = new HttpRequestFragmenter(this.getInvitationsRequest);

        this.getInvitationsRequestFragmenter.onStart.on(frag => {
            let req = frag.request;
            req.query.org = this._org;
            req.query.page = 1;
            req.query.pageSize = 50;

            this._invitations = [];

            this.onStart.trigger(null);
        });

        this.getInvitationsRequestFragmenter.onSuccessFragment.on(frag => {
            let req = frag.request;
            req.query.page++;

            let collection = req.responseObj.collection;

            for (let item of collection) {
                this._invitations.push(item);
            }

            if (this._invitations.length >= req.responseObj.count) {
                this.getInvitationsRequestFragmenter.stop();
            }
        });

        this.getInvitationsRequestFragmenter.onStop.on(frag => {
            this.onComplete.trigger(this._invitations);
        });

        this.onComplete = new LiteEvent<Invitation[]>();
        this.onStart = new LiteEvent<null>();
    }

    public setOrg(org:string | null) : void {
        this._org = org;
        this.refresh();
    }

    public refresh() {
        this.getInvitationsRequestFragmenter.start();
    }

    public get isLoading():boolean {
        return this.getInvitationsRequest.isLoading;
    }
}
