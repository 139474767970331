//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input, Output, EventEmitter, AfterContentInit } from '@angular/core';

import { Buttons, Direction} from './mutuallyExclusiveListBox.component';

/****************************************************************************
 ** MutuallyExclusiveList is an element that has one list of items on the  **
 ** left and one list on the right. The list items are controlled by       **
 ** directional buttons. MutuallyExclusiveList looks as follows:           **                    
 ****************************************************************************
 *                                                                         **
 *                                                                         **
 *                   _______________     _______________                   **
 *                  | LItem         |   | RItem         |                  **
 *                  | LItem2        |   | RItem2        |                  **
 *               (^)| LItem3        |(<)| RItem3        |                  **
 *               (v)|  ...          |(>)|  ...          |                  **
 *                  | LItem(n-1)    |   | RItem(n-1)    |                  **
 *                  | LItem(n)      |   | RItem(n)      |                  **
 *                  |_______________|   |_______________|                  **
 *                                                                         **
 *                                                                         **
 *                                                                         **
 ***************************************************************************/

export interface IMutuallyExclusiveList {
  LeftList: string [];
  RightList: string [];
}

@Component({
  selector: 'mutually-exclusive-list',
  template: `
    <div class="config-container exclusive-list" (contextmenu)="Beautify($event)"> 
      <mutually-exclusive-list-box [buttons]="LeftButtons" [list]="exclusivelist.LeftList" (buttonclick)="MoveItem($event)" [height]="Height" [width]="Width"
      (selection)="SelectItem($event[0], $event[1])" [selectedItems]="LeftSelected" class="exclusive-list-box" [title]="lefttitle"></mutually-exclusive-list-box>

      <mutually-exclusive-list-box [buttons]="RightButtons" [list]="exclusivelist.RightList" (buttonclick)="MoveItem($event)" [height]="Height" [width]="Width"
      (selection)="SelectItem($event[0], $event[1], false)" [selectedItems]="RightSelected" class="exclusive-list-box" [title]="righttitle"></mutually-exclusive-list-box>
    </div>
  `,
  styleUrls: ['./ui-components.css']
})

export class MutuallyExclusiveList implements AfterContentInit {
//#region PrivateVariables
  lists: IMutuallyExclusiveList;    // contains the list on the left and the list on the right

  private m_leftSelected: string[] = [];   // Stores selected items from the left list
  private m_rightSelected: string[] = [];  // Stores selected items from the right list

  // Set up button configurations Left: ^ v Right: <> 
  private m_leftButtons: Buttons = { top: { direction: Direction.up, disabled: true }, bottom: { direction: Direction.down, disabled: true} };
  private m_rightButtons: Buttons = { top: { direction: Direction.left, disabled: true }, bottom: { direction: Direction.right, disabled: true} };
  
  private m_height: string = "";
  private m_width: string = "";
  
  DragItem: string ="";
//#endregion PrivateVariables

//#region InputVariables
  @Input()
  get exclusivelist () { return this.lists; }
  set exclusivelist (lists: IMutuallyExclusiveList) {
    this.lists = lists;
    this.m_leftSelected = [];
    this.m_rightSelected = [];
    this.UpdateButtons();
  }
  @Input() lefttitle: string = "";
  @Input() righttitle: string = "";
  @Input() height: string = "10em";   // Default height is 10em
  @Input() width: string = "10em";    // Default width is 10em
  @Input() update: Function = () => { return true; } 
  @Input() caller: any = this;

//#endregion InputVariables

//#region PublicVariables
  public get LeftSelected(): string[] { return this.m_leftSelected; };
  public get RightSelected(): string[] { return this.m_rightSelected; };
  public get LeftButtons(): Buttons { return this.m_leftButtons; };
  public get RightButtons(): Buttons { return this.m_rightButtons; };
  public get Height(): string { return this.m_height; };
  public get Width(): string { return this.m_width; };
//#endregion PublicVariables

//#region Initialization
  constructor() { 
  }

  ngAfterContentInit() {
    this.m_height = this.height;
    this.m_width = this.width;
  }
//#endregion Initialization

//#region SelectionFunctions
  SelectItem(event: KeyboardEvent, item: string|string[], leftList: boolean = true) {
    // Left list and right list have same logic
    if (leftList) {
      // Clear the other list.
      // If selection's a string, then select as usual. If it's a list = repleace the selection
      this.ClearItemList(this.m_rightSelected);
      if (typeof item === "string") {
        this.SelectAnItem(event, this.m_leftSelected, item as string);
      }
      else {
        this.ClearItemList(this.m_leftSelected);
        this.m_leftSelected.push.apply(this.m_leftSelected, this.exclusivelist.LeftList);
      }
    }
    else {
      this.ClearItemList(this.m_leftSelected);
      if (typeof item === "string") {
        this.SelectAnItem(event, this.m_rightSelected, item as string);
      }
      else {
        this.ClearItemList(this.m_rightSelected);
        this.m_rightSelected.push.apply(this.m_rightSelected, this.exclusivelist.RightList);
      }
    }
  }

  private SelectAnItem(event: KeyboardEvent, itemList: string[], item: string) {
    // If shift is pressed, we select everything between the initial selection and current selection
    if (event.shiftKey && itemList.length > 0) {
      let parentList = this.exclusivelist.LeftList.includes(itemList[0]) ? this.exclusivelist.LeftList : this.exclusivelist.RightList;
      let initialIndex = parentList.indexOf(itemList[0]);
      let endIndex = parentList.indexOf(item);
      itemList.splice(1, itemList.length - 1);
      for (let index = Math.min(initialIndex, endIndex); index <= Math.max(initialIndex, endIndex); index++) { 
        itemList.push(parentList[index]);
      }
    }
    // If control is pressed, we want to add to selection, unless it's in the list already, in which case we remove it
    else if (event.ctrlKey)
    {
      let index = itemList.indexOf(item);
      // if it's in the list already, we wanna remove it
      if ( index >= 0) {
        itemList.splice(index, 1);
      }
      else {
        itemList.push(item);
      }
    }
    // If we're not doing special operations, clear everything and select the single item.
    else {
      this.ClearItemList(itemList)
      itemList.push(item);
    }
    this.UpdateButtons();
  }
//#endregion SelectionFunctions

//#region SortingAndMovingFunctions
  private SortSelection(itemList: string[]) {
    // When migrating a selection left or right, we want to keep the original order of the list - the user can then move as seen fit
    let itemListInitialLength: number = itemList.length;
    if (itemListInitialLength > 0) {
      let sortList: string[] = this.exclusivelist.LeftList.includes(itemList[0]) ? this.exclusivelist.LeftList : this.exclusivelist.RightList;
      sortList.forEach((x) => { itemList.includes(x) ? itemList.push(x) : null});
      itemList.splice(0, itemListInitialLength);
    }
  }

  MoveSelectionLeft() {
    this.SortSelection(this.m_rightSelected);
    this.exclusivelist.LeftList.push.apply(this.exclusivelist.LeftList, this.m_rightSelected);
    this.m_rightSelected.forEach((x) => this.exclusivelist.RightList.splice(this.exclusivelist.RightList.indexOf(x), 1));
    this.m_leftSelected = this.ClearItemList(this.m_rightSelected);
  }

  MoveSelectionRight() {
    this.SortSelection(this.m_leftSelected);
    this.exclusivelist.RightList.push.apply(this.exclusivelist.RightList, this.m_leftSelected);
    this.m_leftSelected.forEach((x) => this.exclusivelist.LeftList.splice(this.exclusivelist.LeftList.indexOf(x), 1));
    this.m_rightSelected = this.ClearItemList(this.m_leftSelected);
  }

  MoveSelectedItemWithinList(index: number, splitIndex: number) {
    if (splitIndex >=0 && splitIndex < this.exclusivelist.LeftList.length)
    {
      let tmp = this.exclusivelist.LeftList[splitIndex]
      this.exclusivelist.LeftList[splitIndex] = this.exclusivelist.LeftList[index]
      this.exclusivelist.LeftList[index] = tmp;
    }
  }

  MoveItem(event: string) {
    // Determine which button is pressed and where to move the item(s)
    if (event === Direction.up && this.m_leftSelected.length > 0) {
      let index: number = this.exclusivelist.LeftList.indexOf(this.m_leftSelected[0]);
      this.MoveSelectedItemWithinList(index, index - 1);
    }
    else if (event === Direction.down && this.m_leftSelected.length > 0) {
      let index: number = this.exclusivelist.LeftList.indexOf(this.m_leftSelected[0]);
      this.MoveSelectedItemWithinList(index, index + 1);
    }
    else if (event === Direction.left) {
      this.MoveSelectionLeft();
    }
    else if (event === Direction.right) {
      this.MoveSelectionRight();
    }
    const proceed = this.update.apply(this.caller);
    if (proceed === undefined || proceed === null || proceed === false) {
      this.ClearItemList(this.m_leftSelected);
      this.ClearItemList(this.m_rightSelected);
    }
    this.UpdateButtons();
  }
//#endregion SortingAndMovingFunctions

//#region CleaningAndUpdatingFunctions
  Beautify(event: MouseEvent) {
    // prevent any kind of drag effect
    event.preventDefault();
  }

  private ClearItemList(list: string[]) {
    return list.splice(0, list.length);
  }
  UpdateButtons() {
    // If something's selected in the left side check if we have more than one selection or a selection is at a boundary
    // if we have more than one item selected, we can only move right. If we're on a boundary, that boundary is disabled. Otherwise Up Down and Right are enabled
    if (this.m_leftSelected.length > 0) {
      let moreThanOneSelected = (this.m_leftSelected.length > 1);
      this.m_leftButtons.top.disabled = false || this.exclusivelist.LeftList.indexOf(this.m_leftSelected[0]) == 0 || moreThanOneSelected;
      this.m_leftButtons.bottom.disabled = false || this.exclusivelist.LeftList.indexOf(this.m_leftSelected[0]) == (this.exclusivelist.LeftList.length - 1) || moreThanOneSelected;
      this.m_rightButtons.bottom.disabled = false;
      this.m_rightButtons.top.disabled = true;
    }
    // If we selected an item in the right list, we can only move items left
    else if (this.m_rightSelected.length > 0) {
      this.m_rightButtons.top.disabled = false;
      this.m_rightButtons.bottom.disabled = this.m_leftButtons.top.disabled = this.m_leftButtons.bottom.disabled = true;
    }
    // If we have no selections, disable all buttons
    else {
      this.m_rightButtons.top.disabled = this.m_rightButtons.bottom.disabled = this.m_leftButtons.top.disabled = this.m_leftButtons.bottom.disabled = true;
    }
  }
//#endregion CleaningAndUpdatingFunctions
}
