//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { OnInit, Component, ViewChild } from "@angular/core";
import { HttpRequest, HttpRequestType } from "@Shared/utils/httpRequest";
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service"
import { EnterpriseService } from "./enterprise.service";
import { Router } from "@angular/router";

interface Org {
    id: string;
    displayName: string;
}

@Component({
    selector: 'enterpriseComponent',
    templateUrl: './enterprise.component.html',
})
export class EnterpriseComponent implements OnInit {
    public isAdminRequest: HttpRequest;

    public orgs: Org[] = [];
    public currentOrg: string;
    public globalErrorMessage: string = ""
    public activeGlobalError: boolean = false;

    public constructor(
        public router: Router,
        private httpService: MsalHttpRequestService,
        public enterpriseService: EnterpriseService
    ) {
    }

    public ngOnInit(): void {
        this.isAdminRequest = this.httpService.getRequest(HttpRequestType.GET, "UserEnterprise/AdminForOrgs");
        this.isAdminRequest.onSuccess.on(req => {
            this.orgs = <Org[]>req.responseObj.orgs;

            // No company to administrate so nothing to do here.
            if (this.orgs.length == 0) {
                this.router.navigateByUrl("/");
                return;
            }

            this.currentOrg = this.orgs[0].id;
            this.onCompanyChange();
        });
        this.isAdminRequest.send();
    }

    public onCompanyChange() {
        this.enterpriseService.setOrganization(this.currentOrg);
    }

    public displayError(message: string) {
        this.activeGlobalError = true;
        this.globalErrorMessage = message;
    }

    public closeErrorMessage() {
        this.activeGlobalError = false;
    }
}
