//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { DatagridController } from "./datagridController";

export class ClientDatagridController<ElementType = any> extends DatagridController<ElementType> {
    private entireCollection:ElementType[] | null = null;

    public refresh(): void {
        if (this.entireCollection == null) {
            this.collection = [];
            return;
        }

        // TODO sort
        // TODO filter
        this.collection = this.entireCollection.slice(this.getStartIndex(), this.getStartIndex() + this.pageSize);
    }

    public setEntireCollection(collection:ElementType[] | null) {
        this.entireCollection = collection;

        if (this.entireCollection != null) {
            this.totalCount = this.entireCollection.length;
        } else {
            this.totalCount = 0;
        }

        this.refresh();
    }

    public isLoading():boolean {
        return this.entireCollection == null;
    }

    public getEntireCollection(): ElementType[] | null {
        return this.entireCollection;
    }

    public getState():any {
        let state = super.getState();
        state.entireCollection = this.entireCollection;
        return state;
    }

    public readState(state:any) {
        this.entireCollection = state.entireCollection;
        super.readState(state);
    }
}
