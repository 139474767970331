//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service"
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'subscriptions',
    templateUrl: "subscriptions.component.html"
})
export class SubscriptionsComponent implements OnInit {
    public subscriptionsRequest:HttpRequest;

    public subscriptions:any[] = [];
    public expiredSubscriptions:any[] = [];

    public constructor(private httpService:MsalHttpRequestService) { }

    public ngOnInit(): void {
        this.subscriptionsRequest = this.httpService.getRequest(HttpRequestType.GET, "user/subscriptions");

        this.subscriptionsRequest.onSuccess.on(req=> {
            for (let s of this.subscriptionsRequest.responseObj) {
                for (let i of [0,1]) {
                    if (s.devices.length>i) {
                        let r: HttpRequest = this.httpService.getRequest(HttpRequestType.GET, "user/releaseActivation");
                        s.devices[i].releaseActivationRequest = r;
                        r.query.activationId = s.id;
                        r.query.deviceId = s.devices[i].deviceId;
                        r.query.deviceType = s.devices[i].deviceType;
                        r.onSuccess.on(rr=> {
                            this.subscriptionsRequest.send();
                        });
                    }
                }
                s.isExpired = Date.now() > Date.parse(s.to);
            }

            this.subscriptions = this.subscriptionsRequest.responseObj.filter((s:any) => !s.isExpired);
            this.expiredSubscriptions = this.subscriptionsRequest.responseObj.filter((s:any) => s.isExpired);
        });

        this.subscriptionsRequest.send();
        
    }
}
