//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

export class User {
    email: string;
    fnoId: string;
    givenName: string;
    surname: string;
}

export class FailedUserLookup {
    email: string;
    org: string;
    errorMessage: string;
    errorStatus: number;
}