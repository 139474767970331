//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { MsalService } from './msal.service';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { HttpRequest, HttpRequestType } from "@Shared/utils/httpRequest"
import { IHttpRequestService } from "@Shared/utils/iHttpRequestService"
import { environment } from '@environments/environment';
import { AuthService } from '@Authentication';
import { Subscription } from '@rxjs';

@Injectable()
export class MsalHttpRequestService implements IHttpRequestService, OnDestroy {
    private url: string = environment.urlCloudApi;

    private token: string | null;
    private tokenSubscription: Subscription;

    public constructor(
        private http: HttpClient,
        private msalService: MsalService,
        private auth: AuthService) {
        this.tokenSubscription = this.auth.token.subscribe({ next: token => this.token = token });
    }

    ngOnDestroy(): void {
        this.tokenSubscription.unsubscribe();
    }

    public getUri(url: string) {
        return this.url + url;
    }

    public getRequest(typeRequest: HttpRequestType, uri: string): HttpRequest {
        let request = new HttpRequest(this.http, typeRequest, this.getUri(uri));
        request.onStart.on(r => {
            if (this.msalService.isOnline) {
                r.authorizationHeader = "Bearer " + this.token;
            }
        });
        request.onError.on(r => {
            let httpError: HttpErrorResponse = r.httpError;
            if (httpError.status == 401 && httpError.error == "invalid_token") {
                // token is invalid we can delete it
                localStorage.removeItem("msalToken");
            }
        });

        return request;
    }
}
