//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AddUsersResult, EnterpriseService, MultipleUserSubscriptionRequestItem } from "./enterprise.service";
import { HttpRequest } from "@Shared/utils/httpRequest";
import { EnterpriseComponent } from "./enterprise.component";
import { SubscriptionGroupTableRow } from "./SubscriptionGroupTable.component";
import { ProductClassifier } from "./subscription";
import { CSVFileParsedEvent } from 'app/files/csvFileInput.component';

enum errorCode {
    invalidEmail,
    duplicateEmail,
    validEmail
}

const emailValidation = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

@Component({
    selector: 'addUserModal',
    styleUrls: ['./users.component.css'],
    templateUrl: './addUserModal.component.html',
})
export class AddUserModalComponent implements OnInit {
    @Input() refreshUsersFragment: HttpRequest;
    @Output() refresh = new EventEmitter();
    @Input() enterpriseComponent: EnterpriseComponent;
    private _isOpen: boolean = false;

    public _typeAddInput: string = "one";
    public additionManualMail: string[] = [""];
    public additionsMails: string[] = [];
    public disableAddButton: boolean = false;
    public errorMessage: string = "";
    public hintMessage: string = "";
    public emailValidation = emailValidation;

    public subscriptionGroupRows: SubscriptionGroupTableRow[] = [];

    public csvInputError: string = "";

    public constructor(public enterpriseService: EnterpriseService) {
        enterpriseService.AddUsersCompleteEvent.on(this.handleAddUsersComplete.bind(this));
        enterpriseService.AddUsersErrorEvent.on(this.handleAddUsersError.bind(this));
    }

    public ngOnInit() {
        this.enterpriseService.onSubscriptionsUpdate.on(_ => {
            this.subscriptionGroupRows = this.enterpriseService.getAvailableSubscriptionsGrouped().map(subscriptionGroup => new SubscriptionGroupTableRow(subscriptionGroup));
        });
    }

    public get getErrorCode(): typeof errorCode {
        return errorCode;
    }

    public handleFileParsedEvent(fileRows: CSVFileParsedEvent) {        
        // remove whitespaces and newlines
        const invalidEmailCount = fileRows.invalidItems.length;
        this.additionsMails = fileRows.validItems.map(row => row.trim());
        if (invalidEmailCount > 0) {
            this.csvInputError = `Found ${invalidEmailCount} invalid ${invalidEmailCount > 1 ? "emails" : "email"}: ${fileRows.invalidItems.join(', ')}.<br/>These emails will not be included.`
        } else {
            this.resetCsvInputError();
        }

        // remove previously selected products to not have them still selected when we haven't enough subscription
        this.resetSubscriptionSelection();
    }

    public CheckValidEmail(email: string, emailList: string[]) {
        let occurences = 0;

        if (email === null || !emailValidation.test(email)) {
            return errorCode.invalidEmail;
        }

        for (let emails of emailList) {
            if (email.toLowerCase() === emails.toLowerCase()) {
                occurences += 1;
            }
            if (occurences > 1) {
                this.disableAddButton = true;
                return errorCode.duplicateEmail;
            }
        }
        this.disableAddButton = false;
        return errorCode.validEmail;
    }

    public ConfirmValidEmails(emailList: string[]) {
        let i = 0;

        for (let email of emailList) {
            if (email === null) {
                return false;
            }
            if (!emailValidation.test(email)) {
                return false;
            }
            if (i++ != emailList.indexOf(email)) {
                return false;
            }
        }
        return emailList.length > 0;
    }

    public handleAddEmailClick() {
        this.additionManualMail.push("");
        this.resetSubscriptionSelection();
    }

    public RemoveEmail(emailIndex: number) {
        this.additionManualMail = this.additionManualMail.filter((value, index) => index != emailIndex);
    }

    public handleSubmitAddUser() {
        this.clearMessages();
        
        if (this.typeAddInput === "one") {
            this.additionsMails = this.additionManualMail.slice();
        }

        let addUsersRequestBody: MultipleUserSubscriptionRequestItem[] = this.additionsMails.map((email, index) => {
            // For each new user, create a request item for its creation.
            let addUsersRequestItem = new MultipleUserSubscriptionRequestItem();
            addUsersRequestItem.userEmail = email;
            addUsersRequestItem.fnoActivationIds = this.getSubscriptionIdsForUser(index);
            return addUsersRequestItem;
        });

        let error = this.enterpriseService.dispatchAddUsers(addUsersRequestBody);
        if (error) {
            this.errorMessage = error;
        }
    }

    public trackingFunction(index: number, email: string) {
        return index;
    }

    public resetStates() {
        this.resetAdditionMails();
        this.resetAdditionManualMails();
        this.typeAddInput = "one";
        this.clearMessages();
        this.resetSubscriptionSelection();
    }

    public resetCsvInputError() {
        this.csvInputError = "";
    }

    public resetAdditionManualMails() {
        this.additionManualMail = [""];
    }

    public resetAdditionMails() {
        this.additionsMails = [];
    }

    public resetSubscriptionSelection() {
        this.subscriptionGroupRows.forEach(r => r.resetSelection());
    }

    public get isOpen(): boolean {
        return this._isOpen;
    }

    public set isOpen(open: boolean) {
        this._isOpen = open;

        if (this._isOpen) {
            this.resetStates();
        }
    }

    public get typeAddInput() {
        return this._typeAddInput;
    }
    
    public set typeAddInput(value: string) {
        this.resetCsvInputError();
        this.resetAdditionManualMails();
        this.resetAdditionMails();
        this._typeAddInput = value;
    }

    public get numberOfNewUsers() {
        return this._typeAddInput === "one" ? this.additionManualMail.length : this.additionsMails.length;
    }

    private handleAddUsersComplete(result: AddUsersResult) {
        if (Object.keys(result.didNotCreate.alreadyExists).length > 0) {
            let userEmails = Object.keys(result.didNotCreate.alreadyExists);
            this.hintMessage = `Failed to add existing users: {${userEmails.join(", ")}}.`;
        }
        if (Object.keys(result.didNotCreate.failed).length > 0) {
            let keys = Object.keys(result.didNotCreate.failed)
            for (let key of keys){
                 this.errorMessage += `Failed to add users {${key}} due to the following reasons: \n` + Object(result.didNotCreate.failed)[key].join(", ");
            }
        }
        if (result.failedAssignment.length > 0) {
            this.errorMessage += `\nFailed assigning subscriptions to user: {${result.failedAssignment.map(result => result.userEmail)}}`;
        }
        if (this.errorMessage == "" && this.hintMessage == "") {
            // If no error message to present, close the modal
            this._isOpen = false;
        }
        this.resetAdditionManualMails();
        this.resetAdditionMails();
        this.resetSubscriptionSelection();
        this.refresh.emit();
        this.refreshUsersFragment?.send();
        if (result.fullySuccessful.length > 0) {
            // If any subscriptions are assigned, reload the subscriptions.
            this.enterpriseService.refreshSubscriptions();
        }
    }

    private handleAddUsersError(errorMessages: string[]) {
        this.errorMessage = errorMessages.join("; ");
    }

    private getSubscriptionIdsForUser(userIndex: number): string[] {
        let fnoActivationIds: string[] = []
        this.subscriptionGroupRows.filter(row => row.numberSelected > 0).forEach(row => {
            // Take the subscriptions assigned to the new user.
            let subscriptionCount: number = row.numberSelected;            
            let productActivationIds = row.subGroup.subscriptions.map(subscription => subscription.fnoActivationId);
            let start: number = userIndex * subscriptionCount;
            let end: number = (userIndex + 1) * subscriptionCount;
            let productActivationIdsForUser = productActivationIds.slice(start, end);
            fnoActivationIds.push(...productActivationIdsForUser);
        });
        return fnoActivationIds;
    }

    private clearMessages() {
        this.errorMessage = "";
        this.hintMessage = "";
    }
}
