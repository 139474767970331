//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { SubscriptionsComponent } from './Subscriptions/subscriptions.component';
import { SharedModule } from '@Shared/Shared.module';
import { MsalModule } from '@Msal/msal.module';

import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, EmailValidator } from '@angular/forms';

import { ClarityModule } from "@clr/angular";

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AccountComponent } from './Account/account.component';
import { HelpComponent } from './Help/help.component';
import { AccountService } from './Account/account.service';
import { EnterpriseModule } from './Enterprise/enterprise.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LoggingService } from 'services/logging.service';
import { ErrorHandlerService } from 'services/errors.service';

@NgModule({
    declarations: [
        AppComponent,
        AccountComponent,
        HelpComponent,
        SubscriptionsComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ClarityModule,
        AppRoutingModule,
        SharedModule,
        MsalModule,
        EnterpriseModule,
        ReactiveFormsModule,
    ],
    providers: [
        AccountService,
        LoggingService,
        { provide: ErrorHandler, useClass: ErrorHandlerService }
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA]  // to avoid errors with transclusion (https://scotch.io/tutorials/angular-2-transclusion-using-ng-content)
})
export class AppModule { }
