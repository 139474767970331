//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { countries } from '../../utils/countries';

class Address {
  public Address: string;
  public City: string;
  public Country: string;
  public CountryCode: string;
  public Full: string;
  public PostalCode: string;
  public State: string;
}

@Component({
  selector: 'address-enrichment',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
})
export class AddressComponent {

  @Input() url: string = 'https://accountregistration.ansys.com';

  @Input() street: string;
  @Output() streetChange = new EventEmitter<string>();

  @Input() city: string;
  @Output() cityChange = new EventEmitter<string>();

  @Input() postal: string;
  @Output() postalChange = new EventEmitter<string>();

  @Input() state: string;
  @Output() stateChange = new EventEmitter<string>();

  @Input() country: string;
  @Output() countryChange = new EventEmitter<string>();

  @Input() readonly: boolean = false;

  public selectedAddress: Address | null = null;
  public possibleAddress: Address[] = [];
  public isAddressLookupInProgress: boolean = false;
  public isAddressLookupCompleted: boolean = false;
  public addressEnrichmentEnabled: boolean = true;

  public countryList: string[] = countries;

  constructor(private http: HttpClient) { }

  verifyAddress() {
    if (!this.readonly && this.addressEnrichmentEnabled) {
      this.isAddressLookupInProgress = true;
      this.http.get<Address[]>(`${this.url}/api/address-lookup?address=${this.street}`)
        .subscribe({
          next: data => {
            this.possibleAddress = data || [];
            this.isAddressLookupCompleted = true;
          },
          error: error => {
            this.possibleAddress = [];
            console.error("There is something wrong with the request.", error);
          },
          complete: () => this.isAddressLookupInProgress = false
        });
    }
  }

  useSelectedAddress() {
    this.streetChange.emit(this.selectedAddress!.Address);
    this.cityChange.emit(this.selectedAddress!.City);
    this.stateChange.emit(this.selectedAddress!.State);
    this.postalChange.emit(this.selectedAddress!.PostalCode);
    this.countryChange.emit(this.selectedAddress!.Country);
    this.reset(true);
  }

  useCustomAddress() {
    this.reset(false);
  }

  useAddressEnrichment() {
    this.reset(true);
  }

  reset(addressEnrichmentEnabled: boolean = true) {
    this.selectedAddress = null;
    this.possibleAddress = [];
    this.isAddressLookupInProgress = false;
    this.isAddressLookupCompleted = false;
    this.addressEnrichmentEnabled = addressEnrichmentEnabled;
  }
}
