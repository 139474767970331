//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input } from '@angular/core';

@Component({
    selector: 'locker',
    template: `
    <div [hidden]="!enabled" class="locker">
    </div>
    `,
    styles: [
        `
        .locker {
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0px;
            top: 0px;
            z-index:999999;
            background-color: black;
            opacity: 0.5;
            cursor: progress;
        }`
    ]
})
export class LockerComponent {
    @Input() public enabled: boolean = false;
}
