//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, AfterContentInit, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    selector: 'combo-box',
    template: `
    <div [class]="customClass" [id]="id" [style.width]="width">
        <label class="default-label" [style.width]="labelwidth">{{label}}</label>
        <select [(ngModel)]="m_value">
            <option *ngIf="defaultselection!==null" [ngValue]="emptyitem" [attr.selected]="(emptyitem | UseKey: comparekey)==(m_value | UseKey: comparekey) ? 'selected' : null">{{defaultselection}}</option>
            <option *ngFor="let item of list" [ngValue]="item" [attr.selected]="(item | UseKey: comparekey)==(m_value | UseKey: comparekey) ? 'selected' : null" [disabled]="disabled.includes(item)">{{key | UseKey: item}}</option>
        </select>
    </div>
    `,
    styleUrls: ['./ui-components.css']
  })
  
  export class ComboBox implements AfterContentInit, AfterViewInit, OnChanges {
    @Input() customClass: string = "";  
    @Input() id: string = "";
    @Input() label: string = "";
    @Input() emptyitem: any = "";
    @Input() labelwidth: string = "";
    @Input() list: any[] = [];
    @Input() disabled: any[] = [];
    @Input() comparekey: any = "";
    @Input() defaultselection: any = null;
    @Input() key = ""
    @Input() width: string = "";
    @Input() value: any = "";
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
    public get m_value(): any { return this.value; };
    public set m_value(val: any) { this.value = val; this.valueChange.emit(this.value); }

    constructor() {
        this.id = Date.now.toString();
    }
    
    ngAfterContentInit() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.defaultselection === null && this.list.length > 0 && (!this.list.includes(this.m_value) || this.disabled.includes(this.m_value))) {
                this.m_value = this.list.filter(x => !this.disabled.includes(x))[0];
            }
        }, 0);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.list.length > 0 && (!this.list.includes(this.m_value) || this.disabled.includes(this.m_value))) {
            this.m_value = this.emptyitem;
        }
    }

  }
