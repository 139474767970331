//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { HttpRequestFragmenter } from '../utils/httpRequestFragmenter';

@Component({
    selector: 'fragment-request-presenter',
    template: `
        <ng-container *ngFor="let errorMessage of fragmenter.errorMessages">
            <div
                class="errorBackgroundColor"
                style="width:100%; border: 1px solid #ebafa6; border-radius:2px; padding: 2px 10px 2px 10px; margin: 7px 0px 7px 0px;"
                [innerHtml]="errorMessage"
            >
            </div>
        </ng-container>

        <request-presenter [request]="fragmenter.request" [showError]="false" [locker]="locker">
            <request-loading>
                <div [hidden]="fragmenter.maxIndex == null" class="centerText">
                    Processing {{(100 * fragmenter.currentIndex / (fragmenter.maxIndex || 1)).toFixed(2)}}%
                </div>
            </request-loading>
        </request-presenter>
    `
})
export class RequestFragmenterPresenterComponent implements OnInit {
    @Input() fragmenter:HttpRequestFragmenter;
    @Input() locker:boolean;

    @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

    public ngOnInit(): void {
        if (this.fragmenter == null) {
            throw new Error('No Fragmenter.');
        }
    }
}
