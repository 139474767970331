//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpRequest } from './httpRequest';
import { LiteEvent } from './liteEvent';

export class HttpRequestFragmenter {
    private _request:HttpRequest;
    private _currentIndex:number;
    private _isRunning:boolean;

    private _isInError:boolean;
    private _errorsMessages:string[];

    public maxIndex:number | null;

    public onStartFragment:LiteEvent<HttpRequestFragmenter>;
    public onSuccessFragment:LiteEvent<HttpRequestFragmenter>;
    public onErrorFragment:LiteEvent<HttpRequestFragmenter>;
    public onCompleteFragment:LiteEvent<HttpRequestFragmenter>;
    public onStart:LiteEvent<HttpRequestFragmenter>;
    public onStop:LiteEvent<HttpRequestFragmenter>;

    public constructor(request:HttpRequest) {
        this._request = request;
        this.reset();

        this.onStartFragment = new LiteEvent<HttpRequestFragmenter>();
        this.onStartFragment = new LiteEvent<HttpRequestFragmenter>();
        this.onSuccessFragment = new LiteEvent<HttpRequestFragmenter>();
        this.onErrorFragment = new LiteEvent<HttpRequestFragmenter>();
        this.onCompleteFragment = new LiteEvent<HttpRequestFragmenter>();
        this.onStart = new LiteEvent<HttpRequestFragmenter>();
        this.onStop = new LiteEvent<HttpRequestFragmenter>();

        this._request.onStart.on(req => this.onStartRequest(req));
        this._request.onComplete.on(req => this.onCompleteRequest(req));
        this._request.onError.on(req => this.onErrorRequest(req));
        this._request.onSuccess.on(req => this.onSuccessRequest(req));
    }

    public reset():void {
        this._currentIndex = 0;
        this._isRunning = false;
        this._isInError = false;
        this._errorsMessages = [];
        this._request.reset();
    }

    public start() {
        this._isRunning = true;
        this.onStart.trigger(this);
        this.sendRequest();
    }

    public stop() {
        this._isRunning = false;
        this.onStop.trigger(this);
    }

    public get currentIndex():number {
        return this._currentIndex;
    }

    public get request():HttpRequest {
        return this._request;
    }

    public get isRunning():boolean {
        return this._isRunning;
    }

    public get isInError():boolean {
        return this._isInError;
    }

    public get errorMessages():string[] {
        return this._errorsMessages;
    }

    private onStartRequest(request:HttpRequest): void {
        this.onStartFragment.trigger(this);
    }

    private onSuccessRequest(request:HttpRequest): void {
        this.onSuccessFragment.trigger(this);
    }

    private onErrorRequest(request:HttpRequest): void {
        this._errorsMessages.push(request.errorMessage);
        this._isInError = true;
        this.onErrorFragment.trigger(this);
    }

    private sendRequest() {
        if (this.maxIndex && this.currentIndex >= this.maxIndex) {
            this.stop();
            return;
        }

        this._request.send();
    }

    private onCompleteRequest(request:HttpRequest): void {
        this.onCompleteFragment.trigger(this);

        if (this._isRunning) {
            this._currentIndex++;  // first send is in start() so start at 0
            this.sendRequest();
        }
    }
}
