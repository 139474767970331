//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { environment } from 'environments/environment';
import { MsalService } from '@Msal/services/msal.service';
import { MsalHttpRequestService } from '@Msal/services/msalHttpRequest.service';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { AuthService } from '@Authentication';
import { filter, first } from 'rxjs/operators';
import { Subscription } from '@rxjs';
import { LoggingService } from 'services/logging.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    private languageStorageName: string = "language";
    public supportedLanguages: Array<string> = ["en", "fr", "de", "it", "ja", "ko", "zh"];
    public language: string;
    public isEnterpriseAdmin: boolean = false;
    public isACCUser: boolean = false;
    public isAdminRequest: HttpRequest;
    public redirectToECommerceRequest: HttpRequest;
    public welcomeForumUri: string;
    public ACCForumUri: string;
    public basicProfileUri: string;
    public subscriptionsRequest: HttpRequest;

    private tokenSubscription: Subscription;
    private isLoggingOut: boolean;

    public constructor(
        private msalService: MsalService,
        private auth: AuthService,
        private httpService: MsalHttpRequestService,
        private loggingService: LoggingService,
        @Inject(LOCALE_ID) protected localeId: string
    ) {
        this.language = localeId;
        if (this.language == "" || this.language == "en-US") {
            this.language = "en";
        }
        console.log(`localId ${this.language}`);

        let usualLanguage = sessionStorage.getItem(this.languageStorageName);
        if (usualLanguage != null) {
            if (usualLanguage != this.language) {
                console.log(`Usual language : ${usualLanguage}`);
                this.setLanguage(usualLanguage);
            }
        } else {
            let browserLanguage: string = navigator.language;
            for (let supportedLanguage of this.supportedLanguages) {
                if (browserLanguage.indexOf(supportedLanguage) >= 0) {
                    console.log(`Browser language : ${this.language}`);
                    this.setLanguage(supportedLanguage);
                    break;
                }
            }
        }
    }

    ngOnInit() {
        this.isAdminRequest = this.httpService.getRequest(HttpRequestType.GET, "UserEnterprise/AdminForOrgs");

        this.isAdminRequest.onSuccess.on(h => {
            this.isEnterpriseAdmin = h.responseObj.orgs.length > 0;
        });

        this.isAdminRequest.onError.on(h => {
            this.isEnterpriseAdmin = false;
        });

        this.tokenSubscription = this.auth.token.subscribe({
            next: token => {
                if (token == null && !this.isLoggingOut) {
                    this.login();
                }
            }
        });

        this.auth.token.pipe(
            filter(t => t != null),
            first()
        ).subscribe(() => this.isAdminRequest.send());

        this.redirectToECommerceRequest = this.httpService.getRequest(HttpRequestType.GET, "user/ecommerceaccount");
        this.redirectToECommerceRequest.onSuccess.on(h => {
            window.location.href = h.responseObj.myaccount;
        });
        this.subscriptionsRequest = this.httpService.getRequest(HttpRequestType.GET, "user/subscriptions");

        this.subscriptionsRequest.onSuccess.on(req => {
            if (req.responseObj === null && req.responseText !== null) {
                return;
            }

            for (let s of this.subscriptionsRequest.responseObj) {
                if (s.productCategory === 'cloud') {
                    this.isACCUser = true;
                    break;
                }
            }
        });

        this.subscriptionsRequest.send();

        this.welcomeForumUri = this.httpService.getUri("DiscoveryForum");
        this.ACCForumUri = this.httpService.getUri("CloudComputeForum");
        this.basicProfileUri = this.httpService.getUri("Account/BasicProfile");
    }

    ngOnDestroy(): void {
        this.tokenSubscription.unsubscribe();
    }

    public get isConnected(): boolean {
        return this.msalService.isOnline;
    }

    public login() {
        this.msalService.login();
    }

    public logout() {
        this.isLoggingOut = true;
        this.msalService.logout();
    }

    public redirectToECommerce() {
        if (this.isConnected) {
            this.redirectToECommerceRequest.send();
        }
    }

    public setLanguage(language: string, save: boolean = false) {
        // we don't have localization support in local since we need to build multiple app for that.
        if (!environment.production) {
            return;
        }

        if (this.supportedLanguages.indexOf(language) == -1) {
            return;
        }

        if (save) {
            console.log(`${this.languageStorageName} save to ${language}`);
            sessionStorage.setItem(this.languageStorageName, language);
        }

        if (language == this.language) {
            return;
        }

        // get path
        let path: string = window.location.pathname;
        for (let supportedLanguage of this.supportedLanguages) {
            let prefix = `/${supportedLanguage}/`;
            if (path.startsWith(prefix)) {
                path = path.substr(prefix.length - 1);
                break;
            }
        }

        console.log(`redirect to language ${language}...with path ${path}`);
        setTimeout(function () {
            if (language == "en") {
                window.location.href = window.location.origin + path;
            } else {
                window.location.href = window.location.origin + "/" + language + path;
            }
        }, 100);
    }
}
