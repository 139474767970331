<div class="card">
    <div class="card-header" i18n="@@d8a5a39955f5064ad378d884fab201fe00d3730e">
        Admin Users
    </div>
    <div class="card-block">
        <request-presenter [request]="getAdminUsersRequest">
            <ng-template>
                <clr-datagrid [(clrDgSelected)]="selectedAdmins" class="selectAllDisabled hidePlaceholderFilterIcon">
                    <clr-dg-action-bar>
                        <button class="btn btn-secondary btn-sm btn-icon" [disabled]="selectedAdmins.length == 0"
                            (click)="isRemoveAdminModalOpen = true">
                            <clr-icon shape="minus-circle"></clr-icon>
                            <span i18n>Remove admin rights</span>
                        </button>
                        <button class="btn btn-secondary btn-sm btn-icon" [disabled]="selectedAdmins.length == 0"
                            (click)="resendAdminWelcomeEmail()">
                            <clr-icon shape="envelope"></clr-icon>
                            <span i18n>Resend Welcome Email</span>
                        </button>
                    </clr-dg-action-bar>

                    <clr-dg-column i18n>First Name</clr-dg-column>
                    <clr-dg-column i18n>Last Name</clr-dg-column>
                    <clr-dg-column i18n>Email</clr-dg-column>

                    <clr-dg-row *clrDgItems="let user of adminUsers" [clrDgItem]="user"
                        [clrDgSelectable]="user.email != currentUser?.mail">
                        <clr-dg-cell>{{user.givenName}}</clr-dg-cell>
                        <clr-dg-cell>{{user.surname}}</clr-dg-cell>
                        <clr-dg-cell>{{user.email}}</clr-dg-cell>
                    </clr-dg-row>

                    <clr-dg-footer><span i18n>{adminUsers.length, plural, =1 {1 admin} other {{{adminUsers.length}}
                            admins}}</span></clr-dg-footer>
                </clr-datagrid>

                <button class="btn btn-secondary btn-icon" style="margin-top: 12px" (click)="isAddAdminModalOpen = true"
                    i18n="@@5726bfec3546c66ada64c4ea1250187f9ce8df42">
                    <clr-icon shape="user"></clr-icon> Add Admin
                </button>
            </ng-template>
        </request-presenter>
    </div>
</div>

<!-- remove admin modal -->
<clr-modal [(clrModalOpen)]="isRemoveAdminModalOpen" [clrModalStaticBackdrop]="true">
    <h3 class="modal-title" i18n>Remove admin</h3>
    <div class="modal-body">
        <request-presenter [request]="removeAdminUsersRequest" [locker]="true"></request-presenter>
        <span i18n>Are you sure you want to remove {this.selectedAdmins.length, plural, =1 {1 admin} other
            {{{this.selectedAdmins.length}} admins}}?</span>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="isRemoveAdminModalOpen = false" i18n>
            Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="removeAdminUsersRequest.send()" i18n>
            Remove admin rights
        </button>
    </div>
</clr-modal>

<!-- add admin modal -->
<clr-modal [(clrModalOpen)]="isAddAdminModalOpen" [clrModalStaticBackdrop]="true">
    <h3 class="modal-title" i18n="@@2badbd2e3cffce83793f91b3fd36e7a72ae58961">Add Admin</h3>
    <div class="modal-body">
        <request-presenter [request]="addAdminUsersRequest" [locker]="true"></request-presenter>
        <form clrForm clrLayout="vertical">
            <clr-input-container class="full-width">
                <label for="newAdminUserEmail" i18n>Admin Email</label>
                <input clrInput type="text" id="newAdminUserEmail" name="newAdminUserEmail"
                    [(ngModel)]="newAdminUserEmail" email required #adminEmail="ngModel" />
            </clr-input-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="isAddAdminModalOpen = false" i18n>
            Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="this.addAdminUsersRequest.send()"
            [disabled]="!adminEmail.valid" i18n>
            Add
        </button>
    </div>
</clr-modal>