//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input, OnInit, Output, EventEmitter, AfterContentInit } from '@angular/core';

/****************************************************************************
 ** MutuallyExclusiveListBox is an element that has one list of items and  **
 ** two control buttons. MutuallyExclusiveListBox looks as follows:        **                 
 ****************************************************************************
 *                                                                         **
 *                                                                         **
 *                               _______________                           **
 *                              | LItem         |                          **
 *                              | LItem2        |                          **
 *                           (T)| LItem3        |                          **
 *                           (B)|  ...          |                          **
 *                              | LItem(n-1)    |                          **
 *                              | LItem(n)      |                          **
 *                              |_______________|                          **
 *                                                                         **
 *                                                                         **
 *                                                                         **
 ***************************************************************************/

export const Direction: { up: string, down: string, left: string, right: string } = { up: "up", down: "down", left: "left", right: "right" };
export interface Button {
  direction: string;
  disabled: boolean;
};

export interface Buttons { 
  top: Button;
  bottom: Button;
};


@Component({
  selector: 'mutually-exclusive-list-box',
  template: `
    <div class="exclusive-list-wrap" [style.width]="Width">
      <h6 class="exclusive-list-title"> {{title}} </h6>
      <div class="exclusive-list-buttons">
        <button class="btn btn-sm btn-link btn-icon btn-tree btn-ovrrd" style="padding: 0" (click)="ButtonClick()" [disabled]="buttons.top.disabled">
            <clr-icon shape="circle-arrow" class="is-solid clr-btn-ovrrd" [ngStyle]="ButtonMap[buttons.top.direction]"></clr-icon>
        </button>
        <br />
        <button class="btn btn-sm btn-link btn-icon btn-tree btn-ovrrd" style="padding: 0" (click)="ButtonClick(false)" [disabled]="buttons.bottom.disabled">
            <clr-icon shape="circle-arrow" class="is-solid clr-btn-ovrrd" [ngStyle]="ButtonMap[buttons.bottom.direction]"></clr-icon>
        </button>
      </div>
      <div class="card exclusive-list-box" style="display: inline-block" [style.height]="Height" (keydown)="SelectAll($event)" tabindex="0">
        <div *ngFor="let item of list" >
            <div class="exclusive-list-item" [class.selected]="IsSelected(item)" id="{{ item | lowercase }}_id" (keydown)="SelectAll($event)" (mousedown)="Selection($event, item)" (focus)="Selection($event, item)" draggable="true" 
            (dragstart)="DragStart($event)" tabindex="0">
                {{item}}
            </div>
        </div>
      </div>
    </div>  
  `,
  styleUrls: ['./ui-components.css']
})

export class MutuallyExclusiveListBox implements OnInit, AfterContentInit {
//#region PrivateVariables
  private _buttonMap: { [direction: string]: {} } = {};
  private m_height: string = "";
  private m_width: string = "";
//#endregion PrivateVariables

//#region InputVariables
  @Input() public buttons: Buttons;         // Consumer can add 2 buttons to list box
  @Input() public list: string[];           // The list inside of the box
  @Input() public selectedItems: string[];  // The selected list items
  @Input() public height: string = "";
  @Input() public width: string = "";
  @Input() public title: string = "";

  @Output() buttonclick: EventEmitter<string> = new EventEmitter<string>();
  @Output() selection: EventEmitter<[KeyboardEvent, string|string[]]> = new EventEmitter<[KeyboardEvent, string|string[]]>();
//#endregion InputVariables

//#region PublicProperties
  public get ButtonMap(): any { return this._buttonMap; };
  public get Height(): string { return this.m_height; };
  public get Width(): string { return this.m_width; };
//#endregion PublicProperties

//#region Initialization
  constructor() {
    this._buttonMap[Direction.up] = {'transform': 'rotate(0deg)'};
    this._buttonMap[Direction.down] = {'transform': 'rotate(180deg)'};
    this._buttonMap[Direction.right] = {'transform': 'rotate(90deg)'};
    this._buttonMap[Direction.left] = {'transform': 'rotate(270deg)'};
  }
  
  ngOnInit() {
  }

  ngAfterContentInit() {
    this.m_height = this.height;
    this.m_width = this.width;
  }
  
  DragStart(event: DragEvent) {
    // prevent any drag events
    event.preventDefault();
    event.stopImmediatePropagation();
  }
//#endregion Initialization

//#region ButtonActions
  ButtonClick(top: boolean = true) {
    // Tell parent which buttons are clicked
    if (top) {
      this.buttonclick.emit(this.buttons.top.direction);
    }
    else {
      this.buttonclick.emit(this.buttons.bottom.direction);
    }
  }
//#endregion ButtonActions

//#region SelectionFuctions
  Selection(event: KeyboardEvent, selection: string) {
    if (event.type === 'mousedown' || !this.selectedItems.includes(selection))
    {
      this.selection.emit([event, selection]);
    }
  }

  IsSelected(item: string): boolean {
    return this.selectedItems.indexOf(item) >= 0;
  }

  SelectAll(event: KeyboardEvent, selection?: string) {
    // follow ctrl-a scheme and select everything
    if (event.ctrlKey && event.key == 'a') {
      event.preventDefault();
      this.selection.emit([event, this.list]);
    }
    // Add keyboard key controls
    // //up
    // if (event.keyCode == 38) {
    //   this.selection.emit([event, selection]);
    //   (document.getElementById(selection.toLowerCase() + "_id")!.parentElement!.previousSibling!.firstChild as HTMLElement).focus();
    // }
    // //down
    // if (event.keyCode == 40) {
    //   this.selection.emit([event, selection]);
    //   (document.getElementById(selection.toLowerCase() + "_id")!.parentElement!.nextSibling!.firstChild as HTMLElement).focus();
    // }
  }
//#endregion SelectionFuctions
}
