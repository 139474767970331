//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { DatagridController } from "@Shared/utils/datagridController"
import { EnterpriseService } from "./enterprise.service";

export default class UsersDatagridController extends DatagridController {
    private enterpriseService: EnterpriseService;

    constructor(enterpriseService: EnterpriseService) {
        super();
        this.enterpriseService = enterpriseService;
        enterpriseService.GetUserSuccessEvent.on(response => {
            this.totalCount = response.totalCount;
        })
    }

    /**
     * Called when page,sort or filter change.
     */
    public refresh(): void {
        this.enterpriseService.dispatchGetUsers(this.page, this.pageSize, true);
    }

    public reset(): void {
        this.page = 0;
        this.pageSize = 10;
        this.refresh();
    }

    public isLoading(): boolean {
        return this.enterpriseService.isGetUsersInProgress;
    }
}