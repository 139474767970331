//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, OnInit } from "@angular/core";
import { EnterpriseService } from "./enterprise.service";
import { ClientDatagridController } from '@Shared/utils/clientDatagridController'
import { ProductClassifier, SubscriptionGroup } from "./subscription";
@Component({
    selector: 'subscriptions-summary',
    templateUrl: './subscriptionsSummary.component.html',
})
export class SubscriptionsSummaryComponent implements OnInit {
    public subscriptionDatagridController: ClientDatagridController;

    public constructor(public enterpriseService:EnterpriseService) {
    }

    ngOnInit(): void {
        this.subscriptionDatagridController = new ClientDatagridController(1,5);
        this.subscriptionDatagridController.setSort({sortBy: 'key.name', isReversed: false})
        this.enterpriseService.onSubscriptionsUpdate.on(_ => {
            let allSubscriptions = this.enterpriseService.subscriptionsGroupedByProduct;
            allSubscriptions.sort((a, b) => a.key.name.localeCompare(b.key.name));
            this.subscriptionDatagridController.setEntireCollection(allSubscriptions);
        });
    }

    getUserFriendlySubscriptionUsage(subGroup: SubscriptionGroup<ProductClassifier>): string {
        return `${subGroup.subscriptions.filter(s => s.userEmail != "").length}/${subGroup.subscriptions.length}`;
    }
}
