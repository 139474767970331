//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input, OnChanges } from "@angular/core";
import { ClientDatagridController } from "@Shared/utils/clientDatagridController";
import { EnterpriseService } from './enterprise.service';
import { NameAndDateClassifier, SubscriptionGroup } from "./subscription";

@Component({
    selector: 'subscription-group-table',
    styleUrls: ['./users.component.css'],
    template: `
        <div *ngIf="this.numberOfAssignees > 1" class="alert alert-info" role="alert">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                    </div>
                    <span class="alert-text">
                        Users selected will have the subscriptions chosen divided evenly amongst themselves.
                    </span>
                    <div class="tooltip tooltip-lg tooltip-bottom-left" role="tooltip">
                        Details
                        <span class="tooltip-content">
                        When multiple users are selected, the quantity of subscriptions selected will be distributed equally among the selected users. 
                        If you want to assign multiple subscriptions of the same type to each selected user, the quantity selected must account for
                        this. Please contact support@ansys.com with any questions.
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <clr-datagrid 
                [clrDgLoading]="!enterpriseService.isSubscriptionsRequestCompleted"
                class="selectAllDisabled hidePlaceholderFilterIcon">
                <clr-dg-column i18n>Product Name</clr-dg-column>
                <clr-dg-column i18n>Start Date</clr-dg-column>
                <clr-dg-column i18n>Expiration Date</clr-dg-column>
                <clr-dg-column i18n>Tecs Date</clr-dg-column>
                <clr-dg-column i18n>Quantity</clr-dg-column>
                <clr-dg-row *clrDgItems="let subGroupRow of subGroupDatagridController.getCollection()"
                    [clrDgItem]="subGroupRow"
                >
                    <clr-dg-cell><div class = "datagrid-cell-auto">{{subGroupRow.subGroup.key.productName}}</div></clr-dg-cell>
                    <clr-dg-cell><div class = "datagrid-cell-auto">{{subGroupRow.subGroup.key.startDate.substring(0,10)}}</div></clr-dg-cell>
                    <clr-dg-cell><div class = "datagrid-cell-auto">{{subGroupRow.subGroup.key.validityDate.substring(0,10)}}</div></clr-dg-cell>
                    <clr-dg-cell><div class = "datagrid-cell-auto">{{subGroupRow.subGroup.key.tecsDate.substring(0,10)}}</div></clr-dg-cell>
                    <clr-dg-cell>
                        <clr-select-container class = "datagrid-cell-auto">
                            <select clrSelect [(ngModel)]="subGroupRow.numberSelected">
                                <option *ngFor="let i of getRange(subGroupRow.subGroup)" value = "{{i}}">{{i * numberOfAssignees}}</option>
                            </select>
                            <clr-control-helper>of {{subGroupRow.subGroup.subscriptions.length}}</clr-control-helper>
                        </clr-select-container>
                    </clr-dg-cell>
                </clr-dg-row>
                <clr-dg-placeholder i18n>Your company has no active and unassigned subscriptions.</clr-dg-placeholder>
                <clr-dg-footer>
                    <request-page-presenter [controller]="subGroupDatagridController"></request-page-presenter>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
    `
})
export class SubscriptionGroupTableComponent implements OnChanges {
    public subGroupDatagridController: ClientDatagridController;

    @Input() numberOfAssignees: number;

    @Input() subGroupRows: SubscriptionGroupTableRow[];

    constructor(public enterpriseService: EnterpriseService) {
        this.subGroupDatagridController = new ClientDatagridController(1, 5);
     }

    ngOnChanges(): void {
        this.subGroupDatagridController.setEntireCollection(this.subGroupRows);

        if (this.subGroupDatagridController.getPageCount() != null) {
            let pageCount: number = this.subGroupDatagridController.getPageCount()!;

            if (pageCount < this.subGroupDatagridController.getPage())
                this.subGroupDatagridController.setPage(Math.max(pageCount, 1));
        }
    }

    public getRange(subGroup: SubscriptionGroup<NameAndDateClassifier>): number[] {
        let result: number[] = [];
        let available: number = subGroup.subscriptions.length;
        const maxSubPerTransaction = 25;
        let max;
        if (this.numberOfAssignees == 0) {
            max = 0;
        } else {
            max = Math.min(Math.floor(available / this.numberOfAssignees), maxSubPerTransaction);
        }
        for (let i = 0; i <= max; i++)
            result.push(i);

        return result;
    }
}

export class SubscriptionGroupTableRow {
    subGroup: SubscriptionGroup<NameAndDateClassifier>;
    numberSelected: number;

    constructor(subGroup: SubscriptionGroup<NameAndDateClassifier>) {
        this.subGroup = subGroup;
        this.numberSelected = 0;
    }

    public resetSelection(): void {
        this.numberSelected = 0;
    }

    public anySelected(): boolean {
        return this.numberSelected > 0;
    }

    public get subscription() {
        return this.subGroup.subscriptions[0];
    }
}