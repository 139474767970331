//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service"
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'helpComponent',
    templateUrl: './help.component.html'
})

export class HelpComponent implements OnInit {    
        public constructor(private httpService:MsalHttpRequestService) {
        }
        public ngOnInit(): void {
        }
}
