<div id="add-user">
    <clr-modal [(clrModalOpen)]="isOpen" [clrModalStaticBackdrop]="true" clrModalSize="xl">
        <h3 class="modal-title" i18n="@@40eb9ed16f8730b5ba075771466956fc3fc58c76">Add User</h3>
        <div class="modal-body">
            <div
                class="errorBlock"
                *ngIf="errorMessage"
                [innerHtml]="errorMessage"
            ></div>
            <div *ngIf="hintMessage" class="alert alert-info" role="alert">
                <div class="alert-items">
                    <div class="alert-item static">
                        <div class="alert-icon-wrapper">
                            <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                        </div>
                        <span 
                            class="alert-text" 
                            [innerHtml]="hintMessage">
                        </span>
                    </div>
                </div>
            </div>
            <loading-presenter class="spinner-position" [isLoading]="enterpriseService.isAddUsersInProgress" [isCenter]="true"></loading-presenter>
            <locker [enabled]="enterpriseService.isAddUsersInProgress"></locker>
            <form clrForm>
                <clr-select-container>
                    <label for="typeInput" i18n>Method:</label>
                    <select clrSelect id="typeInput" name="typeInput" [(ngModel)]="typeAddInput">
                        <option value="one" i18n>Manually Add Users</option>
                        <option value="multiple" i18n>Import From CSV</option>
                    </select>
                </clr-select-container>
            </form>
            <form clrForm clrLayout="vertical" *ngIf="typeAddInput === 'one'">
                <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
                    <label for="email" i18n>Enter up to 10 unique emails</label>
                    <button *ngIf="ConfirmValidEmails(additionManualMail) && additionManualMail.length < 10"
                        style="min-width: 7.5%;" type="button" class="btn btn-sm btn-outline" (click)="handleAddEmailClick()">
                        +
                    </button>
                </div>
                <div style="display: flex; flex-direction: row;"
                    *ngFor="let manualMail of additionManualMail; let i = index; trackBy:trackingFunction">
                    <div class="clr-form-control" style="margin-top: 0; width: 100%">
                        <div class="clr-control-container" style="width: 90%"
                            [class.clr-error]="CheckValidEmail(manualMail, additionManualMail) != getErrorCode.validEmail && (manualMail.length != 0)">
                            <div class="clr-input-wrapper" style="width: 100%">
                                <input type="text" name="email-input-{{i}}" class="clr-input" style="width: 100%"
                                    [(ngModel)]="additionManualMail[i]" />
                                <clr-icon
                                    *ngIf="CheckValidEmail(manualMail, additionManualMail) != getErrorCode.validEmail && (manualMail.length != 0)"
                                    class="clr-validate-icon" shape="exclamation-circle"
                                    style="width: 24px; height: 24px; color:crimson"> </clr-icon>
                            </div>
                            <span class="clr-subtext"
                                *ngIf="CheckValidEmail(manualMail, additionManualMail) == getErrorCode.invalidEmail && manualMail.length != 0">
                                Please enter a valid email
                                address.</span>
                            <span class="clr-subtext"
                                *ngIf="CheckValidEmail(manualMail, additionManualMail) == getErrorCode.duplicateEmail && manualMail.length != 0">
                                This email has already been
                                entered.</span>
                        </div>
                    </div>
                <button *ngIf="additionManualMail.length > 1" type="button" class="btn btn-sm btn-outline"
                    (click)="RemoveEmail(i)">Remove</button>
            </div>
        </form>
        <form clrForm *ngIf="typeAddInput == 'multiple'">
            <csv-file-input itemName="email" fileInputLabel="Emails" [filter]="emailValidation" [columnIndexesToParse]=[0]
                (fileParsedEvent)="handleFileParsedEvent($event)" [errorMessage]="csvInputError"
            ></csv-file-input>
        </form>

            <div>
                <h3 i18n>
                    Assign Subscription 
                </h3>
                <subscription-group-table [numberOfAssignees]="numberOfNewUsers" [subGroupRows]="subscriptionGroupRows">
                </subscription-group-table>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline" (click)="isOpen = false;" i18n>
                Cancel
            </button>
            <button type="button" class="btn btn-primary" (click)="handleSubmitAddUser()"
                [disabled]="(typeAddInput =='one' && !ConfirmValidEmails(additionManualMail)) || (typeAddInput == 'multiple' && additionsMails.length == 0) || disableAddButton == true"
                i18n>
                Add
            </button>
        </div>
    </clr-modal>
</div>