//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, AfterContentInit, AfterViewInit,  Input, Output, EventEmitter } from '@angular/core';

/****************************************************************************
 ** autosize-text-input is a self adjusting text box based on it's parent  **
 ** element's width with an optional label and mask that looks as follows: **                    
 ****************************************************************************
 *   Without password mask:                                                **
 *   Label [__________________]                                            **
 *                                                                         **
 *   With password mask:                                                   **
 *   Label [__________________]                                            **
 *         (o) - Open eye | Closed eye                                     **
 ***************************************************************************/

 @Component({
  selector: 'autosize-text-input',
  template: `
    <div id="{{Id}}_div" [class]="containerclass" [style.width]="MaxWidth" (window:resize)="MaxWidth=''">
      <label class="autosize-label" [style.width]="labelwidth" id="{{Id}}_label">{{label}} </label>
      <span id="{{Id}}_eye" *ngIf="password" class="show-hide-button">
        <button class="btn btn-sm btn-link btn-icon btn-tree btn-ovrrd show-hide-button" (click)="TogglePasswordMask()" [style.margin-top]="'1px'">
          <clr-icon *ngIf="PasswordMask"  class="show-hide-icon" shape="eye"></clr-icon>
          <clr-icon *ngIf="!PasswordMask" class="show-hide-icon" shape="eye-hide"></clr-icon>
        </button>
      </span>
      <input [type]="Type" id="{{Id}}_input" [(ngModel)]="m_valProp" [placeholder]="placeholder" [style.width]="Width" (window:resize)="Width=ResizeMe()" [disabled]="disabled" />
      <div class="ruler">
        <span id="{{Id}}_span" class="fit-content">{{value}}</span>
      </div>
    </div>
  `,
  styleUrls: ['./ui-components.css']
})

export class AutoSizingTextInput implements AfterContentInit, AfterViewInit {
//#region PrivateMemberVariables
    private m_ready: boolean = false;  // used to let the resize function know that everything's ready
    private m_value: string;           // the text value for the text box

    private m_width: string;          // max width of the string
    private m_maxWidth: string = "";  // max width of the container

    private m_id: string = (Date.now() * Math.random()).toString(); // to avoid id collisions, let's randomize the current time in ms
    private m_type: string = "text";                                // let input type be 'text' or 'password'
    private m_passwordMask: boolean = false;                        // used for toggling password mask and displaying correct icons
//#endregion PrivateMemberVariables

//#region InputVariables
    @Input() containerclass: string = "";   // allow a custom class for the component
    @Input() containerwidth: string = "";
    @Input() label: string = "";            // label text
    @Input() labelwidth: string = "";
    @Input() placeholder: string = "";
    @Input() minwidth: number = 20;         // minwidth is the minimum number of characters before resizing
    @Input() maxwidth: number = 0;          // maxwidth is the maximum number of characters before halting expansion
    @Input() password: boolean = false;     // allow the consumer to add a password mask
    @Input() disabled: boolean = false;     // used to disable the text field
    @Input() offset: number = 0;            // any additional right padding to the text box in pixels
    @Input()                                // allow the consumer to use [(ngModel)] syntax for input text - call resize on changes
    get value() {
        return this.m_value;
    }
    set value(value: string) {
      this.m_value = value;
       setTimeout(() => {
        this.m_width = this.ResizeMe();
       }, 0);
    }

    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
//#endregion InputVariables

//#region PublicVariables
    public get Width(): string { return this.m_width; };
    public set Width(val: string) { this.m_width = val; };
    public get MaxWidth(): string { return this.m_maxWidth; };
    public set MaxWidth(val: string) { this.m_maxWidth = val; }
    public get Id(): string { return this.m_id; };
    public get Type(): string { return this.m_type; };
    public get PasswordMask(): boolean { return this.m_passwordMask; };
    
    // By using [(ngModel)] to detect instant changes, the value will be set
    // twice. ngModel detects change and updates the value. The parent detects
    // the change and also updates the value after we emit the event. To reduce
    // operations / process time, let's have separate properties and call
    // ResizeMe() one time, instead of twice.
    public get m_valProp () {
      return this.m_value;
    }
    public set m_valProp (val: string) {
      this.m_value = val;
      this.valueChange.emit(val);
    }
//#endregion PublicVariables

//#region Initialization
    constructor() {
    }

    ngAfterContentInit() {
      // set up whether or not we're using a password mask
      this.m_passwordMask = this.password;
      this.m_type = this.password ? "password" : this.m_type;
    }

    ngAfterViewInit() {
      // we can't resize anything until after the view is initialized. It's now safe to turn on resizing.
      this.m_ready = true;
      // Force change detection to adjust width without complaints
      setTimeout(() => {
        this.m_width = this.ResizeMe();
      }, 0); 
    }
//#endregion Initialization

//#region SizingFunctions
    GetMaxWidth() {
      return this.m_maxWidth;
    }

    GetFontWidth(elem: HTMLElement): number{
      return Number(Number.parseFloat(window.getComputedStyle(elem, null).fontSize as string).toFixed(1));
    }

    ResizeMe(): string {
      // Here's where things get tricky :)
      let ret = ""
      let textOffset: number = 0.66; // average character size is 66% of the font size.
      let padding = 4; // we want a slight padding of 4 px as precaution against a scroll bar popping up / small margins of error;
      if (this.password) {
        const eyeElement = this.$(this.m_id + "_eye");
        padding += eyeElement !== null ? eyeElement.offsetWidth : 0;
      }

      if (this.m_ready) {
        // the label offset will be our padding + any consumer demanded offset + the width of the label, if available.
        let labelElem = this.$(this.m_id + "_label");
        let labelOffset = padding + (this.label.length > 0 && labelElem != null ? labelElem!.offsetWidth + this.offset : this.offset);
        // in order to calculate the width relatively fast with high accuracy, I have hidden a span to get the width of the text
        // we get the input field, the ruler, and the parent elements to do the calculations.
        let currentElement = this.$(this.m_id + "_input");
        let spanElement = this.$(this.m_id + "_span");
        let containerElement = this.$(this.m_id + "_div");
        let parentElement = containerElement != null ? containerElement.parentElement!.parentElement : document.body;
        // we set our min restrictions based off consumer values (default 20 average chars)
        // we set our max width based off consumer value otherwise it's the parent's width - any label/offset
        // we grab our new size from the hidden span element, and pad it by 1 maxchar to avoid rubberbanding then we cut/cap based on our min/max restricitons.
        let parentwidth = Number.parseInt(String(window.getComputedStyle(parentElement!, null).width));
        let fontWidth = currentElement != null ? this.GetFontWidth(currentElement!) : this.GetFontWidth(document.body);
        let minWidth = this.minwidth * fontWidth * textOffset;
        let widthMax = this.maxwidth > 0 ? this.maxwidth * fontWidth * textOffset : ((parentwidth > 0) ? parentwidth - (labelOffset) : minWidth);
        let newSize = spanElement != null ? spanElement.offsetWidth + fontWidth : minWidth; // add a little bit of padding
        if (newSize < minWidth) { newSize = minWidth; }
        else if (newSize > widthMax) { 
          newSize = widthMax;
          // if the consumer has defined a maxwidth, they may want to put somehting to the right of the text box. 
          // this will force the label and textbox's container to be the width of the textbox & label.
          if (this.maxwidth > 0) {
            this.m_maxWidth = Math.ceil(labelOffset  + newSize).toString() + "px";
          } 
        }
        this.m_maxWidth = (newSize === widthMax) ? Math.ceil(labelOffset  + newSize).toString() + "px" : '';
        // use ceiling as caution to avoid small margins of error.
        ret = Math.ceil(newSize).toString() + 'px';
      }

      return ret;
    }
//#endregion SizingFunctions

//#region PasswordFunctions
    TogglePasswordMask() {
      this.m_type = this.m_passwordMask ? "text" : "password";
      this.m_passwordMask = !this.m_passwordMask;
      this.m_width = this.ResizeMe();
    }
//#endregion PasswordFunctions

//#region Misc
    $(id: any) {
      return document.getElementById(id);
    }
//#endregion Misc
}
