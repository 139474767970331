//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input } from '@angular/core';

@Component({
    selector: 'object-view',
    template: `
        <ng-container *ngIf="object != null">
            <ng-container *ngIf="getKeys().length == 0" class="errorColor"> EMPTY </ng-container>
            <ul class="list">
                <ng-container *ngFor="let key of getKeys()">
                    <li>
                        {{key}} :
                        <span *ngIf="isNullOrEmpty(key)" class="errorColor"> NULL </span>
                        <span *ngIf="!isNullOrEmpty(key) && !isObject(key)">
                            {{getValue(key)}}
                        </span>
                        <span *ngIf="!isNullOrEmpty(key) && isObject(key)">
                            <object-view [object]="getValue(key)"></object-view>
                        </span>
                    </li>
                </ng-container>
            </ul>
        </ng-container>
        <ng-container *ngIf="object === null">
            NULL
        </ng-container>
    `
})
export class ObjectViewComponent {
    @Input() object: any;

    public getKeys(): string[] {
        return Object.keys(this.object);
    }

    public isNullOrEmpty(key:string): boolean {
        let value = this.getValue(key);
        return value === null || value === "";
    }

    public getValue(key: string): string {
        return this.object[key];
    }

    public isObject(key: string): boolean {
        const value = this.getValue(key);
        if (value == null)
            return false;
        return typeof value === 'object';
    }
}
