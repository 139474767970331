//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Injectable } from "@angular/core";
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service";
import { Subscription, SubscriptionGroup, SubscriptionGroupUtils, NameAndDateClassifier, ProductClassifier } from "./subscription";
import { LiteEvent } from "@Shared/utils/liteEvent";
import { Dictionary } from "@Shared/utils/dictionary";
import { SubscriptionCollection } from "./subscriptionCollection";
import { InvitationCollection, Invitation } from "./invitationCollection";
import { Subject } from 'rxjs';
import { FailedUserLookup, User } from "./user.model";
import { HttpRequest, HttpRequestType } from "@Shared/utils/httpRequest";
import EnterpriseHttpRequestFragmenter from "./enterpriseHttpRequestFragmenter";
import { SubscriptionGroupTableRow } from "./SubscriptionGroupTable.component";

const MAX_USER_REMOVAL_PER_REQUEST = 5;
const MAX_SUBSCRIPTIONS_ASSIGNMENT_PER_REQUEST = 25;
const MAX_SUBSCRIPTIONS_PER_REQUEST = 5;

export class GetUsersResponseBody {
  users: User[];
  totalCount: number;
}

export class AddUsersResult {
  didNotCreate: {
    alreadyExists: Map<string, string[]>,
    failed: Map<string, string[]>
  } = {alreadyExists: new Map<string, string[]>(), failed: new Map<string, string[]>()};
  fullySuccessful: any[] = [];
  failedAssignment: any[] = [];
}

export class MultipleUserSubscriptionRequestItem {
  userEmail: string;
  fnoActivationIds: string[];
}

export class AssignProductInfo{
  partNumber: string;
  count: number;
  startDate: string;
  tecsDate: string;
  name: string;
  isAddon: boolean;
  productCategory: string;

  constructor(subscription: Subscription, subscriptionCount: number) {
    this.partNumber = subscription.partNumber;
    this.count = subscriptionCount;
    this.startDate = subscription.startDate;
    this.tecsDate = subscription.tecsDate;
    this.name = subscription.productName;
    this.isAddon = false;
    this.productCategory = subscription.productCategory;
  }
}

type MultipleUserSubscriptionRequestBatch = MultipleUserSubscriptionRequestItem[];

@Injectable()
export class EnterpriseService {
  public GetUserSuccessEvent = new LiteEvent<GetUsersResponseBody>();
  public GetUsersErrorEvent = new LiteEvent<string>();
  public GetUserByEmailSuccessEvent = new LiteEvent<User>();
  public GetUserByEmailErrorEvent = new LiteEvent<FailedUserLookup>();
  public RemoveUsersSuccessEvent = new LiteEvent<void>();
  public RemoveUsersErrorEvent = new LiteEvent<string[]>();
  public AddUsersCompleteEvent = new LiteEvent<AddUsersResult>();
  public AddUsersErrorEvent = new LiteEvent<string[]>();
  public AssignSubscriptionsSuccessEvent = new LiteEvent<void>();
  public AssignSubscriptionsErrorEvent = new LiteEvent<string[]>();
  public UnassignSubscriptionsSuccessEvent = new LiteEvent<void>();
  public UnassignSubscriptionsErrorEvent = new LiteEvent<string[]>();

  public invitationCollection: InvitationCollection;
  public shouldRefreshEnterpriseAdminTable = new Subject();
  public onSubscriptionsUpdate = new LiteEvent();
  public onOrganizationChange = new LiteEvent<string | null>();

  // Made private to disallow direct interaction with data from components
  private _subscriptionCollection: SubscriptionCollection;

  private _org: string | null;

  private _invitations: Invitation[] = [];
  private _invitationsUsedCountPerProduct: Dictionary<number> = {};

  private _usersRequestRetrigger: boolean = false;

  private _getUsersRequest: HttpRequest;
  private _getUserByEmailRequest: HttpRequest;
  private _removeUsersRequest: HttpRequest;
  private _removeUsersRequestFragmenter: EnterpriseHttpRequestFragmenter;
  private _addUsersRequest: HttpRequest;
  private _addUsersRequestFragmenter: EnterpriseHttpRequestFragmenter;
  private _assignSubscriptionRequest: HttpRequest;
  private _assignSubscriptionRequestFragmenter: EnterpriseHttpRequestFragmenter;
  private _unassignSubscriptionRequest: HttpRequest;
  private _unassignSubscriptionRequestFragmenter: EnterpriseHttpRequestFragmenter;

  // This is a grouping commonly used in multiple UI component.
  private _subscriptionsGroupedByProduct: SubscriptionGroup<ProductClassifier>[] = [];

  public constructor(private httpService: MsalHttpRequestService) {
    this._subscriptionCollection = new SubscriptionCollection(this.httpService);
    this._subscriptionCollection.onComplete.on(_ => this.onGetSubscriptionsComplete());

    this.invitationCollection = new InvitationCollection(this.httpService);
    this.invitationCollection.onComplete.on(invitations => this.onGetInvitationsComplete(invitations));

    this.onSubscriptionsUpdate.on(_ => {
      this._subscriptionsGroupedByProduct = SubscriptionGroupUtils.groupBy(this.subscriptions, s => new ProductClassifier(s));
    });

    this._getUserByEmailRequest = this.httpService.getRequest(HttpRequestType.GET, 'userenterprise/userByEmail');
    this._getUsersRequest = this.httpService.getRequest(HttpRequestType.GET, 'userenterprise/users');
    this._removeUsersRequest = this.httpService.getRequest(HttpRequestType.POST, 'userenterprise/remove');
    this._removeUsersRequestFragmenter = new EnterpriseHttpRequestFragmenter(this._removeUsersRequest);
    this._addUsersRequest = this.httpService.getRequest(HttpRequestType.POST, "userenterprise/TryCreateMultipleUsers");
    this._addUsersRequestFragmenter = new EnterpriseHttpRequestFragmenter(this._addUsersRequest);
    this._assignSubscriptionRequest = this.httpService.getRequest(HttpRequestType.POST, 'userenterprise/assignMultiple');
    this._assignSubscriptionRequestFragmenter = new EnterpriseHttpRequestFragmenter(this._assignSubscriptionRequest);
    this._unassignSubscriptionRequest = this.httpService.getRequest(HttpRequestType.POST, 'userenterprise/unassignMultiple');
    this._unassignSubscriptionRequestFragmenter = new EnterpriseHttpRequestFragmenter(this._unassignSubscriptionRequest);

    this._getUsersRequest.onSuccess.on(req => {
      let response = new GetUsersResponseBody();
      response.users = req.responseObj.collection;
      response.totalCount = req.responseObj.count;
      this.GetUserSuccessEvent.trigger(response);
    });

    this._getUsersRequest.onError.on(req => {
      this.GetUsersErrorEvent.trigger(req.errorMessage);
    });

    this._getUsersRequest.onComplete.on(_ => {
      if(this._usersRequestRetrigger) {
        setTimeout(() => {
          this._usersRequestRetrigger = false;
          this._getUsersRequest.send()
        }, 100);   
      } 
    });

    this._getUserByEmailRequest.onSuccess.on(req => {
      this.GetUserByEmailSuccessEvent.trigger(req.getReponseObj());
    });

    this._getUserByEmailRequest.onError.on(req => {
      let failedUserLookupResult:FailedUserLookup = new FailedUserLookup();
      failedUserLookupResult.email = this._getUserByEmailRequest.query.email,
      failedUserLookupResult.org = this._getUserByEmailRequest.query.org,
      failedUserLookupResult.errorStatus = req.httpError.status;
      failedUserLookupResult.errorMessage = req.errorMessage,
      this.GetUserByEmailErrorEvent.trigger(failedUserLookupResult);
    })

    this._removeUsersRequestFragmenter.onStartFragment.on(hf => {
      let rawRequestBody = hf.rawRequestBody as User[];
      hf.request.body.userEmails = this.getSelectedUsersEmails(hf.currentIndex, MAX_USER_REMOVAL_PER_REQUEST, rawRequestBody);
    });

    this._removeUsersRequestFragmenter.onStop.on(hf => {
      this.shouldRefreshEnterpriseAdminTable.next();
      if (hf.isInError) {
        this.RemoveUsersErrorEvent.trigger(hf.errorMessages);
      } else {
        this.RemoveUsersSuccessEvent.trigger();
        this.refreshSubscriptions();
      }
    });

    this._addUsersRequestFragmenter.onStartFragment.on(hf => {
      let requestBatches = hf.rawRequestBody as MultipleUserSubscriptionRequestBatch[];
      hf.request.body = requestBatches[hf.currentIndex];
    });

    this._addUsersRequestFragmenter.onStop.on(hf => {
      if (hf.isInError) {
        this.AddUsersErrorEvent.trigger(hf.errorMessages);
      } else {
        this.AddUsersCompleteEvent.trigger(this.mergeAddUsersResults(hf.successRequestResponseObjs));
      }
    });

    this._unassignSubscriptionRequestFragmenter.onStartFragment.on(hf => {
      let requestBatches = hf.rawRequestBody as MultipleUserSubscriptionRequestBatch[];
      hf.request.body = requestBatches[hf.currentIndex];
    });

    this._unassignSubscriptionRequestFragmenter.onStop.on(hf => {
      if (hf.isInError) {
        this.UnassignSubscriptionsErrorEvent.trigger(hf.errorMessages);
      } else {
        this.UnassignSubscriptionsSuccessEvent.trigger();
        this.refreshSubscriptions();
      }
    });

    this._assignSubscriptionRequestFragmenter.onStartFragment.on(hf => {
      let requestBatches = hf.rawRequestBody as MultipleUserSubscriptionRequestBatch[];
      hf.request.body = requestBatches[hf.currentIndex];
    });

    this._assignSubscriptionRequestFragmenter.onStop.on(hf => {
      if (hf.isInError) {
        this.AssignSubscriptionsErrorEvent.trigger(hf.errorMessages);
      } else {
        this.AssignSubscriptionsSuccessEvent.trigger();
        this.refreshSubscriptions();
      }
    });
  }

  private onGetSubscriptionsComplete(): void {
    this.onSubscriptionsUpdate.trigger(this.subscriptions);
  }

  private onGetInvitationsComplete(invitations: Invitation[]) {
    this._invitations = invitations;

    this._invitationsUsedCountPerProduct = {};  // do that as late as possible to keep a valid value in the Company Subscription block
    for (let invitation of invitations) {
      for (let product of invitation.subscriptions) {
        this.clientAddInvitation(product);
      }
    }
  }

  public setOrganization(orgID: string | null) {
    this._org = orgID;
    this._invitations = [];
    this._invitationsUsedCountPerProduct = {};

    this._subscriptionCollection.setOrg(orgID);
    this.invitationCollection.setOrg(orgID);

    this.onOrganizationChange.trigger(orgID);
  }

  public refreshInvitations() {
    this.invitationCollection.refresh();
  }

  public refreshSubscriptions() {
    this._subscriptionCollection.refresh();
  }

  public getSubscriptionRequestProgression() {
    return this._subscriptionCollection.getProgression();
  }

  public getOrganization(): string | null {
    return this._org;
  }

  public get clientComputedData(): boolean {
    return this._subscriptionCollection.isStale;
  }

  public get isSubscriptionsRequestCompleted(): boolean {
    return this._subscriptionCollection.isCompleted;
  }

  public get invitations(): Invitation[] {
    return this._invitations;
  }

  public clientAddInvitation(productName: string) {
    if (!this._invitationsUsedCountPerProduct[productName]) {
      this._invitationsUsedCountPerProduct[productName] = 0;
    }
    this._invitationsUsedCountPerProduct[productName]++;
  }

  public clientRemoveInvitation(productName: string) {
    if (!this._invitationsUsedCountPerProduct[productName]) {
      console.error(`No subscription for ${productName}`);
      return;
    }

    this._invitationsUsedCountPerProduct[productName]--;
  }

  public get subscriptions(): Subscription[] {
    return this._subscriptionCollection.subscriptions;
  }

  public getAllSubscriptionsGrouped(): SubscriptionGroup<ProductClassifier>[] {
    return this.subscriptionsGroupedByProduct;
  }

  public getAvailableSubscriptionsGroupedForProduct(productName: string): SubscriptionGroup<NameAndDateClassifier>[] {
    let availableSubscriptions = this.subscriptions.filter(sub => sub.userEmail == "" && sub.productName == productName);

    //group duplicates
    return this.groupSubscriptionsByDates(availableSubscriptions);
  }

  public getAvailableSubscriptionsGrouped(): SubscriptionGroup<NameAndDateClassifier>[] {
    return this.groupSubscriptionsByDates(this.subscriptions.filter(s => s.userEmail == ""));
  }

  public getSubscriptionsForProduct(productName: string): Subscription[] {
    let subscriptions = this.subscriptions.filter(sub => sub.productName == productName);

    return subscriptions;
  }

  public getSubscriptionsForUser(userEmail: string): Subscription[] {
    let subscriptions = this.subscriptions.filter(sub => sub.userEmail?.toLowerCase() === userEmail?.toLowerCase());
    return subscriptions;
  }

  public getGroupedSubscriptionsForUser(userEmail: string): SubscriptionGroup<ProductClassifier>[] {
    return SubscriptionGroupUtils.groupBy(this.getSubscriptionsForUser(userEmail), s => new ProductClassifier(s));
  }

  public groupSubscriptionsByDates(subscriptions: Subscription[]): SubscriptionGroup<NameAndDateClassifier>[] {
    let groupedSubscriptions = SubscriptionGroupUtils.groupBy(subscriptions, s => new NameAndDateClassifier(s));

    groupedSubscriptions.sort((a, b) => {
      let dateA = new Date(a.key.startDate);
      let dateB = new Date(b.key.startDate);
      let nameA = a.key.productName.toLowerCase();
      let nameB = b.key.productName.toLowerCase();

      if (nameA < nameB)
        return -1;

      else if (nameA > nameB)
        return 1;

      else if (dateA < dateB)
        return -1;

      else if (dateA > dateB)
        return 1;

      else
        return 0;

    });

    return groupedSubscriptions;
  }

  public getSubscriptionUsedCountForProduct(productName: string): number {
    let invitationUsedCount = this._invitationsUsedCountPerProduct[productName] ? this._invitationsUsedCountPerProduct[productName] : 0;
    return this.subscriptions.filter(s => s.productName == productName && s.userEmail != "").length + invitationUsedCount;
  }

  public getSubscriptionLeftForProduct(productName: string): number {
    let invitationUsedCount = this._invitationsUsedCountPerProduct[productName] ? this._invitationsUsedCountPerProduct[productName] : 0;
    return this.subscriptions.filter(s => s.productName == productName && s.userEmail == "").length - invitationUsedCount;
  }

  /**
   * Dispatch request to get/refresh users. Triggers GetUsersSuccess or GetUsersError event.
   */
  public dispatchGetUsers(page: number, pageSize: number, askCount: boolean = false) {
    this._getUsersRequest.setQuery("org", this._org);
    this._getUsersRequest.setQuery("page", page);
    this._getUsersRequest.setQuery("pageSize", pageSize);
    this._getUsersRequest.setQuery("askCount", askCount);

    if (this._getUsersRequest.isLoading) {
      this._usersRequestRetrigger = true;
      return;
    }

    this._getUsersRequest.send();
  }

  /**
   * Dispatch request to search for a user by email. Triggers GetUserByEmailSuccess or GetUserByEmailError event.
   */
  public dispatchGetUserByEmail(userEmail: string) {
    if (this._getUserByEmailRequest.isLoading) return;
    this._getUserByEmailRequest.setQuery("org", this._org);
    this._getUserByEmailRequest.setQuery("email", userEmail);
    this._getUserByEmailRequest.send();
  }

  /**
   * Dispatch request to remove selected users. Triggers RemoveUsersSuccess or RemoveUsersError events.
   */
  public dispatchRemoveUsers(selectedUsers: User[]): string | undefined {
    if (this._removeUsersRequestFragmenter.isRunning) 
      return "Unable to remove users, another remove user process is running.";
    this._removeUsersRequestFragmenter.resetFragmenter();
    this._removeUsersRequest.setQuery("org", this._org);
    this._removeUsersRequestFragmenter.rawRequestBody = selectedUsers;
    this._removeUsersRequestFragmenter.maxIndex = Math.ceil(selectedUsers.length / MAX_USER_REMOVAL_PER_REQUEST);
    this._removeUsersRequestFragmenter.start();
  }

  /**
   * Dispatch request to add users. Triggers AddUsersSuccess or AddUsersError events.
   */
  public dispatchAddUsers(requestBody: MultipleUserSubscriptionRequestBatch): string | undefined {
    if (this._addUsersRequestFragmenter.isRunning) 
      return "Unable to add users, another add user process is running.";

    this.prepareSubscriptionRequestFragmenter(this._addUsersRequestFragmenter, this._addUsersRequest, requestBody);
    this._addUsersRequestFragmenter.start();
  }

  /**
   * Dispatch request to assign subscriptions. Triggers AssignSubscriptionsSuccess or AssignSubscriptionsError event.
   */
  public dispatchAssignSubscriptions(requestBody: MultipleUserSubscriptionRequestBatch): string | undefined {
    if (this._assignSubscriptionRequestFragmenter.isRunning)
      return "Unable to assign subscriptions, another assign subscriptions process is running.";

    this.prepareSubscriptionRequestFragmenter(this._assignSubscriptionRequestFragmenter, this._assignSubscriptionRequest, requestBody);
    this._assignSubscriptionRequestFragmenter.start();
  }

  /**
   * Dispatch request to unassign subscriptions. Triggers UnassignSubscriptionsSuccess or UnassignSubscriptionsError event.
   */
  public dispatchUnassignSubscriptions(requestBody: MultipleUserSubscriptionRequestBatch): string | undefined {
    if (this._unassignSubscriptionRequestFragmenter.isRunning)
      return "Unable to unassign subscriptions, another unassign subscriptions process is running.";

    this.prepareSubscriptionRequestFragmenter(this._unassignSubscriptionRequestFragmenter, this._unassignSubscriptionRequest, requestBody);
    this._unassignSubscriptionRequestFragmenter.start();
  }
  
  public get isGetUsersInProgress(): boolean {
    return this._getUsersRequest.isLoading || this._getUserByEmailRequest.isLoading;
  }

  public get isRemoveUsersInProgress(): boolean {
    return this._removeUsersRequestFragmenter.isRunning;
  }

  public get isAddUsersInProgress(): boolean {
    return this._addUsersRequestFragmenter.isRunning;
  }

  public get isAssignSubscriptionsInProgress(): boolean {
    return this._assignSubscriptionRequestFragmenter.isRunning;
  }

  public get isUnassignSubscriptionsInProgress(): boolean {
    return this._unassignSubscriptionRequestFragmenter.isRunning;
  }

  public get subscriptionsGroupedByProduct(): SubscriptionGroup<ProductClassifier>[] {
    return this._subscriptionsGroupedByProduct;
  }

  /**
   * We want to limit the number of subscription assignments in an api call to MAX_SUBSCRIPTIONS_ASSIGNMENT_PER_REQUEST. 
   * This function helps batch the request items so the request can be made in several batches with a limit of MAX_SUBSCRIPTIONS_ASSIGNMENT_PER_REQUEST per batch.
   * @param allRequestItems The request items to be fragmented
   * @returns Fragmented request batches where each batch will be used for a request fragment.
   */
  private batchMultipleUserSubscriptionRequestItems(allRequestItems: MultipleUserSubscriptionRequestBatch): MultipleUserSubscriptionRequestBatch[] {
    let batches: MultipleUserSubscriptionRequestBatch[] = [];
    let currentBatch: MultipleUserSubscriptionRequestBatch = [];
    allRequestItems.forEach(requestItem => {
      let currentBatchSubCount: number = 0; 
      //If the current batch sub count and the new request item exceed the subscription limit we have to partition
      if(this.ExceedsMaxSubscriptionCount(currentBatchSubCount, requestItem.fnoActivationIds.length))
      {
        //We determine how we want to split the subscriptions of the requestItem based on the size of the current batch
        let subSplit: number = 0;
        if(currentBatch.length == 0){
          subSplit = MAX_SUBSCRIPTIONS_ASSIGNMENT_PER_REQUEST;
        }
        else{
          subSplit = MAX_SUBSCRIPTIONS_ASSIGNMENT_PER_REQUEST - Number(currentBatchSubCount);
          if(subSplit > requestItem.fnoActivationIds.length){
            subSplit = Number(requestItem.fnoActivationIds.length);
          }
        }

        //We push the new request items into the current batch
        let requestItemsToPush = new MultipleUserSubscriptionRequestItem();
        requestItemsToPush.userEmail = requestItem.userEmail;
        requestItemsToPush.fnoActivationIds = requestItem.fnoActivationIds.slice(0, subSplit);
        currentBatch.push(requestItemsToPush);

        batches.push(currentBatch);
        currentBatch = [];
        currentBatchSubCount = 0;

        //After clearing the current batch, we check if there are any subscriptions from the request item we need to carry over
        let requestItemsToCarry = new MultipleUserSubscriptionRequestItem();
        requestItemsToCarry.userEmail = requestItem.userEmail;
        requestItemsToCarry.fnoActivationIds = requestItem.fnoActivationIds.slice(subSplit);

        //If carry over request items exceed the max we need to split them before they are added to current batch
        if(requestItemsToCarry.fnoActivationIds.length > MAX_SUBSCRIPTIONS_ASSIGNMENT_PER_REQUEST){
          let carryIndex: number = 0;
          while(Number(carryIndex) < requestItemsToCarry.fnoActivationIds.length){
            //Final partition that is under subscription limit can be passed to current batch
            if((Number(carryIndex) + MAX_SUBSCRIPTIONS_ASSIGNMENT_PER_REQUEST) > requestItemsToCarry.fnoActivationIds.length)
            {
              let _requestItemsToCarry = new MultipleUserSubscriptionRequestItem();
              _requestItemsToCarry.userEmail = requestItemsToCarry.userEmail;
              _requestItemsToCarry.fnoActivationIds = requestItemsToCarry.fnoActivationIds.slice(carryIndex);
              currentBatch.push(_requestItemsToCarry);
              currentBatchSubCount += _requestItemsToCarry.fnoActivationIds.length;
              break;
            }
            //requestItemsToCarry are pushed directly into batches if they are over the max limit (25)
            let _requestItemsToPush = new MultipleUserSubscriptionRequestItem();
            _requestItemsToPush.userEmail = requestItemsToCarry.userEmail;
            _requestItemsToPush.fnoActivationIds = requestItemsToCarry.fnoActivationIds.slice(carryIndex, carryIndex + MAX_SUBSCRIPTIONS_ASSIGNMENT_PER_REQUEST);
            batches.push([_requestItemsToPush]);
            carryIndex += MAX_SUBSCRIPTIONS_ASSIGNMENT_PER_REQUEST;
          }
        }
        else{
        //Otherwise we push carry over items to currentBatch
        currentBatch.push(requestItemsToCarry);
        currentBatchSubCount += requestItemsToCarry.fnoActivationIds.length; 
        }       
      }
      //If it does not exceed limit we can push directly into current batch
      else{
        currentBatch.push(requestItem);
        currentBatchSubCount += requestItem.fnoActivationIds.length;
      }
    });

    if (currentBatch.length > 0) {
      batches.push(currentBatch);
    }
    return batches;
  }

  private ExceedsMaxSubscriptionCount(currentCount: number, overhead: number): boolean {
    return currentCount + overhead > MAX_SUBSCRIPTIONS_ASSIGNMENT_PER_REQUEST;
  }

  /**
   * 
   * @param results The add users request responses got from successful fragments
   * @returns 
   */
  private mergeAddUsersResults(results: AddUsersResult[]): AddUsersResult {
    let mergedResult = new AddUsersResult();
    results.forEach(response => {
      mergedResult.didNotCreate.alreadyExists = response?.didNotCreate.alreadyExists == undefined ? new Map<string, string[]>() : response?.didNotCreate?.alreadyExists;
      mergedResult.didNotCreate.failed = response?.didNotCreate.failed == undefined ? new Map<string, string[]>() : response?.didNotCreate?.failed;;
      mergedResult.failedAssignment.push(...response?.failedAssignment);
      mergedResult.fullySuccessful.push(...response?.fullySuccessful);
    });
    return mergedResult;
  }

  private getSelectedUsersEmails(index: number, size: number, users: User[]): string[] {
    let start = index * size;

    return users.slice(start, start + size).map(u => u.email);
  }

  private prepareSubscriptionRequestFragmenter(fragmenter: EnterpriseHttpRequestFragmenter, request: HttpRequest, requestBody: MultipleUserSubscriptionRequestBatch){
    fragmenter.resetFragmenter();
    request.setQuery("org", this._org);

    // Fragment the request body on dispatching the action and store it for when a request fragment starts
    let requestBatches = this.batchMultipleUserSubscriptionRequestItems(requestBody);
    if (requestBatches.length == 0)      
      return "Unable to add users, failed to batch requestBody.";

    fragmenter.rawRequestBody = requestBatches;
    fragmenter.maxIndex = requestBatches.length;
  }
}
