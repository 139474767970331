//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Injectable, OnDestroy } from '@angular/core';
import { environment } from "@environments/environment";
import { LiteEvent } from '@Shared/utils/liteEvent';
import { AuthService } from '@Authentication';
import { Subscription } from '@rxjs';

declare var Msal: any;

export class MsalUser {
    displayableId: string;
    identityProvider: string;
    name: string;
    userIdentifier: string;
}

@Injectable()
export class MsalService implements OnDestroy {
    public onTokenReceived = new LiteEvent<string>();  // token as argument

    private token: string | null;
    private tokenSubscription: Subscription;

    applicationConfig = {
        clientID: environment.clientID,
        authority: environment.authority,
        b2cScopes: [""],
    };

    clientApplication: any = null;

    constructor(private auth: AuthService) {
        this.tokenSubscription = this.auth.token.subscribe({
            next: token => {
                this.token = token;
                if (token) {
                    this.onTokenReceived.trigger(token);
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.tokenSubscription.unsubscribe();
    }

    public login(): void {
        this.auth.login();
    }

    public logout(): void {
        this.auth.logout();
    }

    public getToken(): string | null {
        return this.token;
    }

    public get isOnline(): boolean {
        // There were cases where msalToken in localStorage was lost while other storages were fine.
        // I suspect this is why we were in a state this.getUser() != null && getToken() == null.
        // (Change suggested by msaul himself ;) ).
        return this.token != null;
    }
}
