//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { EventEmitter } from '@angular/core';
import { ClrDatagridFilterInterface } from "@clr/angular";
import { Filter as SharedFilter } from "@Shared/utils/iDatagridController";
import { StringUtils } from '@Shared/utils/stringUtils';

export abstract class ClarityFilter<T = any> implements ClrDatagridFilterInterface<T>, SharedFilter {
    public changes: EventEmitter<T | null> = new EventEmitter<T | null>(false);

    // Used to know if the filter change in DatagridController.
    public changed: boolean = false;

    public constructor() {
    }

    public abstract get value(): string;
    public abstract get property(): string; // ~ field name

    // Called to filter the datagrid.
    public abstract accepts(value: T): boolean;

    protected triggerChange(value: T | null) {
        this.changed = true;
        this.changes.emit(value);
    }

    public isActive(): boolean {
        return !StringUtils.IsNullOrEmpty(this.value);
    }
}
