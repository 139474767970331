//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpRequest } from "@Shared/utils/httpRequest";
import { HttpRequestFragmenter } from "@Shared/utils/httpRequestFragmenter";
import { LiteEvent } from "@Shared/utils/liteEvent";

export default class EnterpriseHttpRequestFragmenter extends HttpRequestFragmenter {
    public rawRequestBody: any = null;
    /**
     * The response objects of all the successuful requests.
     */
    public successRequestResponseObjs: any[] = [];
    public onStartFragment: LiteEvent<EnterpriseHttpRequestFragmenter> = new LiteEvent();
    public onSuccessFragment: LiteEvent<EnterpriseHttpRequestFragmenter> = new LiteEvent();
    public onErrorFragment: LiteEvent<EnterpriseHttpRequestFragmenter> = new LiteEvent();
    public onCompleteFragment: LiteEvent<EnterpriseHttpRequestFragmenter> = new LiteEvent();
    public onStart: LiteEvent<EnterpriseHttpRequestFragmenter> = new LiteEvent();
    public onStop: LiteEvent<EnterpriseHttpRequestFragmenter> = new LiteEvent();

    constructor(request: HttpRequest) {
        super(request);
        this.onSuccessFragment.on(hf => {
            if (hf.request.responseObj)
                hf.successRequestResponseObjs.push(hf.request.responseObj);
        });
    }

    public resetFragmenter(): void {
        this.reset();
        this.rawRequestBody = null;
        this.successRequestResponseObjs = [];
    }
}