//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { NgModule } from "@angular/core";
import { EnterpriseComponent } from "./enterprise.component";
import { SubscriptionsSummaryComponent } from "./subscriptionsSummary.component";
import { UsersComponent } from "./users.component";
import { AdminsComponent } from "./admins.component";
import { InvitationsComponent } from "./invitations.component";
import { ProductCountCell } from "./productCountCell.component";
import { EnterpriseService } from "./enterprise.service";
import { SharedModule } from "@Shared/Shared.module";
import { AppRoutingModule } from "../app-routing.module";
import { ClarityModule } from "@clr/angular";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { AccountService } from "../Account/account.service";
import { AddUserModalComponent } from './addUserModal.component';
import { CsvFileInputComponent } from "app/files/csvFileInput.component";
import { SubscriptionGroupTableComponent } from "./SubscriptionGroupTable.component";
import { ExtendedRequestPresenterComponent } from './extendedRequestPresenter.component';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ClarityModule,
        AppRoutingModule,
        SharedModule,
    ],
    declarations: [
        EnterpriseComponent,
        SubscriptionsSummaryComponent,
        UsersComponent,
        AdminsComponent,
        InvitationsComponent,
        ProductCountCell,
        AddUserModalComponent,
        CsvFileInputComponent,
        SubscriptionGroupTableComponent,
        ExtendedRequestPresenterComponent
    ],
    providers: [
        EnterpriseService,
        AccountService,
    ],
    exports: [
        EnterpriseComponent,
    ],
})
export class EnterpriseModule {
}
