//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

export class StringUtils {
    static IsNullOrEmpty(value: string | null | undefined):boolean {
        return value === undefined || value === null || value.length === 0;
    }

    static IsNullOrWhiteSpace(value: string | null | undefined): boolean {
        return (value === undefined || value === null) ? true : value.replace(/\s/g, '').length < 1;
    }

    static validateEmailAddress(email:string) {
        // based on https://stackoverflow.com/a/46181.
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
}
