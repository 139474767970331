//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from '@rxjs/Rx';
import { LiteEvent } from "./liteEvent";
import { StringUtils } from './stringUtils';
import { IHttpRequest } from './iHttpRequest';

export enum HttpRequestType
{
    GET,
    POST,
    PUT,
    DELETE
}

export enum ReponseHandleType
{
    TAKE_LAST,
    TAKE_ALL,
}

export enum BodyType
{
    Raw,
    FormData,
}

export class HttpRequest implements IHttpRequest {
    public static putCredentialsInRequest:boolean = false; // Let it at false if you don't use session variables.

    public isLoading:boolean;
    public isComplete:boolean = false;

    public isInError:boolean;
    public error:Error;
    public httpError:HttpErrorResponse;
    public errorMessage:string;

    public get isCompleteWithoutError():boolean { return this.isComplete && !this.isInError; }

    public responseText:string | null; // null if not complete
    public responseObj: any | null; // JSON.parse() called on responseText, null if failed or request not complete

    public typeRequest = HttpRequestType.GET;
    public uri:string = "";
    public body:any = {};
    public query:any = {};

    private headers?: HttpHeaders;

    public onStart:LiteEvent<HttpRequest>;
    public onSuccess:LiteEvent<HttpRequest>;
    public onError:LiteEvent<HttpRequest>;
    public onComplete:LiteEvent<HttpRequest>;

    public bodyType:BodyType = BodyType.Raw;
    public bodyTransformFunction:{(param:HttpRequest):any} | null;  // call just before sending after onStart, need to return the new body

    public uriTransformFunction:{(fullUri:string):string} | null;  // call just before sending after onStart, need to return the new uri

    public responseHandleType:ReponseHandleType = ReponseHandleType.TAKE_LAST;
    public lastId:number=0;

    public dontAutoResetError:boolean = false;

    public constructor(private http:HttpClient, typeRequest:HttpRequestType, uri:string) {
        this.onStart = new LiteEvent();
        this.onSuccess = new LiteEvent();
        this.onError = new LiteEvent();
        this.onComplete = new LiteEvent();

        this.typeRequest = typeRequest;
        this.uri = uri;
        this.body = {};
        this.query = {};

        this.reset();
    }

    public set authorizationHeader(value:string) {
        this.setHeaderValue("Authorization", value);
    }

    private createHeaderIfUndefined() {
        if (!this.headers) {
            this.headers = new HttpHeaders();
        }
    }

    public setHeaderValue(key:string, value:string) {
        this.createHeaderIfUndefined();
        this.headers = this.headers!.set(key, value);
    }

    public deleteHeader(key:string) {
        this.createHeaderIfUndefined();
        this.headers = this.headers!.delete(key);
    }

    private completeRequest() {
        this.isComplete = true;
        this.isLoading = false;
        this.onComplete.trigger(this);
    }

    public getFullUri():string {
        let uri = this.uri;

        if (this.uriTransformFunction) {
            uri = this.uriTransformFunction(uri);
        }

        let first:boolean = true;
        for (let key of Object.keys(this.query)) {
            let value = this.query[key];
            if (value === null || value === undefined) continue; // Skip null and undefined values not to have "null" or "undefined" strings on server side.
            if (first) {
                first = false;
                uri += "?";
            } else {
                uri += "&";
            }
            uri += encodeURIComponent(key) + "=" + encodeURIComponent(value);
        }
        return uri;
    }

    public send():Observable<string> {
        this.reset();
        this.isLoading = true;
        this.onStart.trigger(this);

        let body:any = this.body;
        if (this.bodyTransformFunction) {
            body = this.bodyTransformFunction(this);
        }

        if (this.bodyType == BodyType.FormData) {
            let formDataBody = new FormData();
            for (let key of Object.keys(body)) {
                formDataBody.append(key, body[key]);
            }
            body = formDataBody;
        }

        let result:Observable<string>;

        let fullUri = this.getFullUri();

        // well ... https://stackoverflow.com/a/48016652
        let options : Object = {
            headers: this.headers,
            observe: 'body' as 'body',
            responseType: 'text' as 'tex',
            withCredentials: HttpRequest.putCredentialsInRequest
        }

        switch (this.typeRequest)
        {
            case HttpRequestType.POST:
                result = this.http.post<string>(fullUri, body, options);
                break;
            case HttpRequestType.DELETE:
                result = this.http.delete<string>(fullUri, options);
                break;
            case HttpRequestType.GET:
                result = this.http.get<string>(fullUri, options);
                break;
            case HttpRequestType.PUT:
                result = this.http.put<string>(fullUri, body, options);
                break;
            default:
                throw new Error("Unsupported request type " + this.typeRequest);
        }

        let id = ++this.lastId;
        result.subscribe(
            (responseText:string) => {
                if (id != this.lastId && this.responseHandleType == ReponseHandleType.TAKE_LAST) {
                    return;
                }

                this.responseText = responseText;
                try {
                    this.responseObj = JSON.parse(responseText);
                } catch(_) {
                    this.responseObj = null;
                }

                /* catch here to not let a loading at true without the user knowing what happens */
                try {
                    this.onSuccess.trigger(this);
                } catch (e) {
                    console.error("HttpRequest - WebClient error");
                    console.error(e);
                    this.handleError(e);
                }

                this.completeRequest();
            }, (errorResponse:HttpErrorResponse) => {
                if (id != this.lastId && this.responseHandleType == ReponseHandleType.TAKE_LAST) {
                    return;
                }

                let errorMessageObj:any = "";
                if (errorResponse.error && !(errorResponse.error instanceof ProgressEvent)) {  // don't want to show ProgressEvent error
                    errorMessageObj = errorResponse.error;
                } else {
                    errorMessageObj = errorResponse.message;
                }

                console.error(fullUri + " error : " +  errorMessageObj);
                this.isInError = true;
                this.error = errorResponse;
                this.httpError = errorResponse;

                let errJsonObj:any | null = null;
                try {
                    errJsonObj = JSON.parse(errorMessageObj)
                } catch (_) {}

                let errorMessage = "";
                if (errJsonObj && errJsonObj.error) {
                    errorMessage = errJsonObj.error;
                    console.error(`${errorResponse.statusText}: ${errorMessage}`);
                } else {
                    errorMessage = errorMessageObj;
                }

                if (!StringUtils.IsNullOrEmpty(this.errorMessage)) {
                    this.errorMessage += "<br/>";
                }
                this.errorMessage += errorMessage;

                this.onError.trigger(this);
                this.completeRequest();
            }
        );

        return result;
    }

    private handleError(error:Error) {
        this.isInError = true;
        this.error = error;
        this.errorMessage = error.message;
    }

    public resetError():void {
        this.isInError = false;
        this.errorMessage = "";
    }

    public reset() {
        if (!this.dontAutoResetError) {
            this.resetError();
        }

        this.responseText = null;
        this.responseObj = null;
        this.isLoading = false;
        this.isComplete = false;
    }

    public getReponseObj<T>() {
        return <T> this.responseObj;
    }

    public getResponseText():string {
        return <string> this.responseText;
    }

    public setQuery(key:string, value:any):HttpRequest {
        this.query[key] = value;
        return this;
    }

    public removeQuery(key:string):HttpRequest {
        delete this.query[key];
        return this;
    }
}
