//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

// https://webcake.co/object-properties-in-angular-2s-ngfor/

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'UseKey'})
export class UseKeyPipe implements PipeTransform {
    // We can look for a particular oject through a key, regardless of the order passed in. If we can't find it, we'll return the second object passed into the pipe.
    transform(obj: any, obj2:any): Object {
        if (obj !== null && obj !== undefined && obj2 !== null && obj[obj2] !== null && obj[obj2] !== undefined)
        {
            return obj[obj2];
        } else if (obj2 !== null && obj2 !== undefined && obj !== null && obj2[obj] !== null && obj2[obj] !== undefined) {
            return obj2[obj];
        } else {
            return obj2;
        }
    }
}
