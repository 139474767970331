export const environment = {
    production: true,
    envName: "fromTFS",
    clientID: "f9586472-3ed0-470a-8bad-1bbe02377f63",
    urlCloudApi: "https://account.ansys.com/",
    authority: "https://login.microsoftonline.com/tfp/ANSYSAccount.onmicrosoft.com/B2C_1_AccountAdmin_sign_in_trafficmgr",
    adminGroupId: "b19f3cc2-2cd4-4b8f-bd36-a8ee6ec021cf",
    oidc: "https://accountregistration.ansys.com",
    oidcClientId: "portal",
    appInsights: { instrumentationKey: "799ab51a-9c2f-4e6f-8e80-e30b6acca9cb" },
};
