//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input } from '@angular/core';
import { RequestPresenterComponent } from '@Shared/components/requestPresenter.component';

@Component({
    selector: 'extended-request-presenter',
    template: `
    <ng-container *ngIf="showError">
        <div
            class="errorBlock"
            *ngIf="request.isInError"
            [innerHtml]="getErrorMessage()"
        >
        </div>
    </ng-container>
    <locker [enabled]="request.isLoading && locker"></locker>
    <ng-container *ngIf="request.isLoading">
        <ng-content select="request-loading"></ng-content>
        <div class="centerText" *ngIf="spinner">
            <span [ngClass]="isLarge ? 'spinner spinner-lg' : 'spinner spinner-sm'"></span>
        </div>
    </ng-container>

    <ng-container *ngIf="(alwaysShowContent && !request.isLoading) || request.isCompleteWithoutError">
        <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
    </ng-container>
`
})
export class ExtendedRequestPresenterComponent extends RequestPresenterComponent {
    @Input() replacementErrorMessage?: string = undefined;

    public getErrorMessage(): string {
        return this.replacementErrorMessage ? this.replacementErrorMessage : this.request.errorMessage;
    }
}