//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { ClarityFilter } from "@Shared/utils/clarityFilter"
import { Component, Input } from '@angular/core';
import { ClrDatagridFilter } from "@clr/angular";
import { Bidictionary } from "@Shared/utils/bidictionary";

@Component({
    selector: "select-filter",
    template: `
        <select [(ngModel)]="valueStr">
            <option *ngFor="let availableValue of availableValues">{{availableValue}}</option>
        </select>`
})
export class SelectFilterComponent extends ClarityFilter<string> {
    @Input() public property: string;
    @Input() public availableValues: string[];
    @Input() public mapping: Bidictionary | undefined;  // internal value => displayed string
    private _valueStr: string = "";

    constructor(private filterContainer: ClrDatagridFilter) {
        super();
        filterContainer.setFilter(this);
    }

    accepts(value: string): boolean {
        return value == this._valueStr;
    }

    public get valueStr(): string {
        return this._valueStr;
    }

    public set valueStr(valueStr: string) {
        this._valueStr = valueStr;
        this.triggerChange(valueStr);
    }

    public get value(): any | undefined {
        return this.mapping ? this.mapping.reverseGet(this.valueStr) : this._valueStr;
    }

    public set value(newValue: any) {
        this._valueStr = this.mapping ? this.mapping.get(newValue) : newValue;
    }
}
