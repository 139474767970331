//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { OnInit, Component, Input } from '@angular/core';
import { MsalHttpRequestService } from '@Msal/services/msalHttpRequest.service'
import { StringUtils } from '@Shared/utils/stringUtils';
import { EnterpriseService, GetUsersResponseBody, MultipleUserSubscriptionRequestItem} from './enterprise.service';
import { EnterpriseComponent } from './enterprise.component';
import { AccountService, UserAccountDetails } from 'app/Account/account.service';
import { SubscriptionGroupTableRow } from './SubscriptionGroupTable.component';
import { ProductClassifier } from './subscription';
import { FailedUserLookup, User } from './user.model';
import UsersDatagridController from './usersDatagridController';

@Component({
  selector: 'users-enterprise',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @Input() enterpriseComponent: EnterpriseComponent;

  public MAX_SUBSCRIPTIONS_ASSIGNMENT_PER_REQUEST: number = 25;
  public users: User[] = [];
  public selectedUsers: User[] = [];
  public userErrorMessage: string = "";
  public removeUsersErrorMessage: string = "";
  public assignSubscriptionErrorMessage: string = "";
  public unassignSubscriptionErrorMessage: string = "";

  public usersDatagridController: UsersDatagridController;

  public isUserLookup: boolean;

  public userByEmailSearch: string = "";

  public unassignProductName = '';

  public subGroupRows: SubscriptionGroupTableRow[] = [];

  private _isAssignModalOpen = false;
  private _isUnassignModalOpen = false;
  private _isRemoveUserModalOpen = false;

  public constructor(
    public enterpriseService: EnterpriseService,
    public accountService: AccountService
  ) {
    enterpriseService.onOrganizationChange.on(orgName => this.handleOrganizationChange());
    enterpriseService.GetUserSuccessEvent.on(this.handleGetUsersSuccess.bind(this));
    enterpriseService.GetUsersErrorEvent.on(this.handleGetUsersError.bind(this));
    enterpriseService.GetUserByEmailSuccessEvent.on(this.handleGetUserByEmailSuccess.bind(this));
    enterpriseService.GetUserByEmailErrorEvent.on(this.handleGetUserByEmailError.bind(this));
    enterpriseService.RemoveUsersSuccessEvent.on(this.handleRemoveUsersSuccess.bind(this));
    enterpriseService.RemoveUsersErrorEvent.on(this.handleRemoveUsersError.bind(this));
    enterpriseService.AssignSubscriptionsSuccessEvent.on(this.handleAssignSubscriptionsSuccess.bind(this));
    enterpriseService.AssignSubscriptionsErrorEvent.on(this.handleAssignSubscriptionsError.bind(this));
    enterpriseService.UnassignSubscriptionsSuccessEvent.on(this.handleUnassignSubscriptionsSuccess.bind(this));
    enterpriseService.UnassignSubscriptionsErrorEvent.on(this.handleUnassignSubscriptionsError.bind(this));
  }

  ngOnInit(): void {
    this.usersDatagridController = new UsersDatagridController(this.enterpriseService);

    // Would be faster if we sent ids here. We can't because we don't know activations ids if we didn't refresh after an assign.

    this.enterpriseService.onSubscriptionsUpdate.on(_ => {
      this.subGroupRows = this.enterpriseService.getAvailableSubscriptionsGrouped().map(subGroup => new SubscriptionGroupTableRow(subGroup));
    });

    this.isUserLookup = false;
    this.usersDatagridController.reset();
  }

  public getUnassignAvailableProducts(): ProductClassifier[] {
    return this.enterpriseService.subscriptionsGroupedByProduct.filter(
        // Select all products where every selected user own at least one subscription
        subGroup => this.selectedUsers.every(u => subGroup.subscriptions.some(s => s.userEmail == u.email))
      ).map(subGroup => subGroup.key);
  }

  public assignProductIsValid(): boolean {
    for (let subGroupRow of this.subGroupRows) {
      if (subGroupRow.numberSelected != 0)
        return true;
    }
    return false;
  }

  public unassignProductIsValid(): boolean {
    return !StringUtils.IsNullOrEmpty(this.unassignProductName);
  }

  public openAssignModal() {
    this.isAssignModalOpen = true;
  }

  public onSubmitAssign() {
    this.enterpriseService.dispatchAssignSubscriptions(this.generateAssignRequestBody(this.selectedUsers, this.subGroupRows));
  }

  public onSubmitUnassign() {
    this.enterpriseService.dispatchUnassignSubscriptions(this.generateUnassignRequestBody(this.selectedUsers, this.unassignProductName));
  }

  public onSubmitRemoveUser() {
    this.enterpriseService.dispatchRemoveUsers(this.selectedUsers);
  }

  public handleSubmitSearchByUserEmail() {
    this.enterpriseService.dispatchGetUserByEmail(this.userByEmailSearch);
  }

  public handleOrganizationChange() {
    this.isUserLookup = false;
    this.usersDatagridController.reset();
  }

  public handleSwitchUserLookup() {
    this.users = [];
    this.selectedUsers = [];
    this.isUserLookup = !this.isUserLookup;
    if (!this.isUserLookup) {
      this.usersDatagridController.reset();
    }
  }

  public handleAddUserModalRefresh() {
    this.isUserLookup = false;
    this.usersDatagridController.reset();
  }

  // no event on OnOpen or OnClose...
  public get isAssignModalOpen(): boolean {
    return this._isAssignModalOpen;
  }

  public set isAssignModalOpen(value: boolean) {
    this._isAssignModalOpen = value;
    this.subGroupRows.forEach(subGroupRow => subGroupRow.resetSelection());
    if(!this._isAssignModalOpen) this.handleAssignSubscriptionsError([]);
  }

  public get isUnassignModalOpen(): boolean {
    return this._isUnassignModalOpen;
  }

  public set isUnassignModalOpen(value: boolean) {
    this._isUnassignModalOpen = value;
    if(!this._isUnassignModalOpen) this.handleUnassignSubscriptionsError([]);
  }

  public get isRemoveUserModalOpen(): boolean {
    return this._isRemoveUserModalOpen;
  }

  public set isRemoveUserModalOpen(value: boolean) {
    this._isRemoveUserModalOpen = value;
    if(!this._isRemoveUserModalOpen) this.handleRemoveUsersError([]);
  }

  public get isCurrentUserSelected(): boolean {
    return this.selectedUsers.some(user => user.email == this.currentUser.mail)
  }

  public get currentUser(): UserAccountDetails {
    return <UserAccountDetails>this.accountService.signedInUserDetails;
  }

  public get isUsersLoading(): boolean {
    return this.enterpriseService.isGetUsersInProgress;
  }

  public get isRemoveUserInProgress(): boolean {
    return this.enterpriseService.isRemoveUsersInProgress;
  }

  private handleGetUsersSuccess(response: GetUsersResponseBody) {
    this.users = response.users;
    this.selectedUsers = [];
    this.userErrorMessage = "";
  }

  private handleGetUsersError(errorMessage: string) {
    this.userErrorMessage = errorMessage;
  }

  private handleGetUserByEmailSuccess(user: User) {
    this.users = [user];
    this.selectedUsers = [user];
    this.userErrorMessage = "";
  }

  private handleGetUserByEmailError(errorResponse: FailedUserLookup) {
    if (errorResponse.errorStatus === 500)
      this.userErrorMessage = `${errorResponse.email} does not exist for the company: ${errorResponse.org}`; // this where the error handling should be done
    else if (errorResponse.errorStatus === 404)
      this.userErrorMessage = `Unable to find user ${errorResponse.email}`;
    else
      this.userErrorMessage = `Something went wrong when trying to find ${errorResponse.email}`;
  }

  private handleRemoveUsersSuccess() {
    // Refresh users
    this.usersDatagridController.reset();

    // Update UI states
    this._isRemoveUserModalOpen = false;
    this.selectedUsers = [];
    this.isUserLookup = false;
    this.handleRemoveUsersError([]);
  }

  private handleRemoveUsersError(errorMessage: string[]) {
    this.removeUsersErrorMessage = errorMessage.join(', ');
  }

  private handleAssignSubscriptionsSuccess() {
    this.isAssignModalOpen = false;
    this.selectedUsers = [];
    this.handleUnassignSubscriptionsError([]);
  }

  private handleAssignSubscriptionsError(errorMessage: string[]) {
    this.assignSubscriptionErrorMessage = errorMessage.join(', ');
  }

  private handleUnassignSubscriptionsSuccess() {
    this.isUnassignModalOpen = false;
    this.unassignProductName = '';
    this.selectedUsers = [];
    this.handleAssignSubscriptionsError([]);
    this.enterpriseService.refreshSubscriptions();
  }

  private handleUnassignSubscriptionsError(errorMessage: string[]) {
    this.unassignSubscriptionErrorMessage = errorMessage.join(', ');
  }

  public generateAssignRequestBody(users: User[], subGroupRows: SubscriptionGroupTableRow[]): MultipleUserSubscriptionRequestItem[] {
    let assignRequestBody: MultipleUserSubscriptionRequestItem[] = users.map((user, index) => {
      // For each new user, create a request item for its creation.
      let assignRequestItem = new MultipleUserSubscriptionRequestItem();
      assignRequestItem.userEmail = user.email;    
      assignRequestItem.fnoActivationIds = this.getAssignSubscriptionIdsForUser(index, subGroupRows);
      return assignRequestItem; 
    });
    return assignRequestBody;
  }

  public getAssignSubscriptionIdsForUser(userIndex: number, subGroupRows: SubscriptionGroupTableRow[]): string[] {
    let fnoActivationIds: string[] = []
    subGroupRows.filter(row => row.anySelected()).forEach(row => {
      //If the amount of subscriptions is above the limit we use the pre-partioned value 
        let subscriptionCount: number = 0;
        subscriptionCount = row.numberSelected;          
        let productActivationIds = row.subGroup.subscriptions.map(subscription => subscription.fnoActivationId);
        let start: number = userIndex * subscriptionCount;
        let end: number = Number(start) + Number(subscriptionCount);
        let productActivationIdsForUser = productActivationIds.slice(start, end);
        fnoActivationIds.push(...productActivationIdsForUser);
    });
    return fnoActivationIds;
  }

  public generateUnassignRequestBody(users: User[], unassignProductName: string):  MultipleUserSubscriptionRequestItem[]{
    let unAssignRequestBody: MultipleUserSubscriptionRequestItem[] = users.map(user => {
      // For each new user, create a request item for its creation.
      let unassignRequestItem = new MultipleUserSubscriptionRequestItem();
      unassignRequestItem.userEmail = user.email;
      unassignRequestItem.fnoActivationIds = this.getUnassignSubscriptionIdsForUser(unassignProductName, user);
      return unassignRequestItem;
  });
  return unAssignRequestBody;
  }

  public concatPartNumberAndNameOfProduct(partNumber:string, productName:string){
    return `${partNumber}@${productName}`;
  }

  private getUnassignSubscriptionIdsForUser(product: string, user: User): string[]{
    //Get subscriptions to return ids for
    var subs = this.enterpriseService.getSubscriptionsForUser(user.email)
    var subsToReturn = product == "_ALL_PRODUCT_" ?  subs : subs.filter(x => this.concatPartNumberAndNameOfProduct(x.partNumber, x.productName) == product);
    return subsToReturn.map(x => x.fnoActivationId);
  }
}
