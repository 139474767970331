//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountService, UserAccountDetails } from './account.service';
import { MsalHttpRequestService } from '@Msal/services/msalHttpRequest.service';
import { HttpRequest, HttpRequestType } from "@Shared/utils/httpRequest";
import { environment } from 'environments/environment';

class EditUserProfile {
  private readonly blank = "%%__BLANK__%%";

  public readonly givenName: string;
  public readonly surname: string;
  public readonly displayName: string;
  public phone: string;
  public jobTitle: string;
  public company: string;
  public street: string;
  public city: string;
  public postal: string;
  public state: string;
  public country: string;
  public marketingOption: boolean;

  constructor(user: UserAccountDetails) {
    this.givenName = this.formatString(user.givenName); // include this in the object but they are not editable
    this.surname = this.formatString(user.surname); // include this in the object but they are not editable
    this.displayName = this.formatString(user.displayName); // include this in the object but they are not editable
    this.phone = this.formatString(user.phone);
    this.jobTitle = this.formatString(user.jobTitle);
    this.company = this.formatString(user.company);
    this.street = this.formatString(user.street);
    this.city = this.formatString(user.city);
    this.postal = this.formatString(user.postal);
    this.state = this.formatString(user.state);
    this.country = this.formatString(user.country);
    this.marketingOption = user.marketingOption;
  }

  public isMatch(profile: EditUserProfile): boolean {
    return this.matchString(this.phone, profile.phone)
      && this.matchString(this.jobTitle, profile.jobTitle)
      && this.matchString(this.company, profile.company)
      && this.matchString(this.street, profile.street)
      && this.matchString(this.city, profile.city)
      && this.matchString(this.postal, profile.postal)
      && this.matchString(this.state, profile.state)
      && this.matchString(this.country, profile.country)
      && this.marketingOption == profile.marketingOption;
  }

  public isDataValid(): boolean {
    const invalid = this.isNullOrWhiteSpace(this.company)
      || this.isNullOrWhiteSpace(this.street)
      || this.isNullOrWhiteSpace(this.city)
      || this.isNullOrWhiteSpace(this.country);

    const pattern = new RegExp(/^$|[\x00-\x7F]+/);

    return !invalid 
      && pattern.test(this.jobTitle)
      && pattern.test(this.company)
      && pattern.test(this.street)
      && pattern.test(this.city)
      && pattern.test(this.postal)
      && pattern.test(this.state);
  }

  private isNullOrWhiteSpace(value: string): boolean {
    return value == null || value.trim() == '';
  }

  private formatString(value: string): string {
    return value == this.blank
      ? ''
      : value;
  }

  private matchString(a: string, b: string): boolean {
    const emptyMatch = this.isNullOrWhiteSpace(a) && this.isNullOrWhiteSpace(b);
    const valueMatch = a == b;
    return emptyMatch || valueMatch;
  }
}

@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  public basicProfileEditLink: string;
  public userDetailUpdateRequest: HttpRequest;
  public userDetailsSubscription: Subscription;

  public userDetails: UserAccountDetails | null = null;
  public editUserProfile: EditUserProfile | null = null;

  public statusSuccess: string | null = null;
  public statusFailure: string | null = null;

  public environment = environment;

  get isLoading(): boolean {
    return this.userDetails == null || this.accountService.userInfoRequest.isLoading || this.userDetailUpdateRequest.isLoading;
  }

  constructor(
    public accountService: AccountService,
    private httpService: MsalHttpRequestService
  ) { }

  ngOnInit(): void {
    this.basicProfileEditLink = this.httpService.getUri("Account/BasicProfile");

    this.userDetailsSubscription = this.accountService.userDetailsSubject
      .subscribe(userDetails => {
        this.userDetails = userDetails;
        this.editUserProfile = userDetails != null ? new EditUserProfile(userDetails) : null;
      });

    this.userDetailUpdateRequest = this.httpService.getRequest(HttpRequestType.POST, "user/UpdateUserProfile");
    this.userDetailUpdateRequest.onStart.on(req => req.body = this.editUserProfile);
    this.userDetailUpdateRequest.onSuccess.on(req => {
      this.setUserDetails();
      if (req.responseObj == false) {
        this.setStatus("The information entered does not meet eligibility requirements.", false);
      }
      else if (req.responseObj == "Exception") {
        this.setStatus("Eligibility screening could not be processed.", false);
      }
      else {
        this.setStatus("Your profile has been updated.", true);
      }
    });
  }

  ngOnDestroy(): void {
    this.userDetailsSubscription.unsubscribe();
  }

  shouldDisableSaveChanges(): boolean {
    const compare = new EditUserProfile(this.userDetails!);
    const match = this.editUserProfile!.isMatch(compare);
    const valid = this.editUserProfile!.isDataValid();
    return match || !valid;
  }

  saveChanges(): void {
    this.userDetailUpdateRequest.send();
  }

  shouldDisableCancel(): boolean {
    const compare = new EditUserProfile(this.userDetails!);
    const match = this.editUserProfile!.isMatch(compare);
    return match;
  }

  cancel(): void {
    this.editUserProfile = this.userDetails != null ? new EditUserProfile(this.userDetails) : null;
  }

  setStatus(message: string, success: boolean): void {
    if (success) {
      this.statusSuccess = message;
      setTimeout(() => this.statusSuccess = null, 10000);
    } else {
      this.statusFailure = message;
      setTimeout(() => this.statusFailure = null, 10000);
    }
  }

  setUserDetails(): void {
    this.userDetails!.jobTitle = this.editUserProfile?.jobTitle!;
    this.userDetails!.company = this.editUserProfile?.company!;
    this.userDetails!.street = this.editUserProfile?.street!;
    this.userDetails!.city = this.editUserProfile?.city!;
    this.userDetails!.postal = this.editUserProfile?.postal!;
    this.userDetails!.state = this.editUserProfile?.state!;
    this.userDetails!.country = this.editUserProfile?.country!;
    this.userDetails!.phone = this.editUserProfile?.phone!;
    this.userDetails!.marketingOption = this.editUserProfile?.marketingOption!;
  }
}
