//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input, Output, EventEmitter } from "@angular/core";
import { StringUtils } from "@Shared/utils/stringUtils";

@Component({
    selector: "csv-file-input",
    template: `
    <clr-control-container class="clr-error">
        <!-- ngIf to not activate the browser input in enter -->
        <label for="csvFile">{{fileInputLabel}}  (.csv)</label>
        <input id="csvFile" type="file" accept=".csv" name="csvFile" (change)="onCsvFileChange($event)"
            hidden="true" #csvFile />
        <span>
            <input clrControl *ngIf="!this.csvFileName" readonly type="text" value="No File Selected"
                (click)="csvFile.click()" />
            <input clrControl *ngIf="this.csvFileName" readonly type="text"
                value="{{itemCount}} {{itemName}}(s) [{{this.csvFileName}}]"
                (click)="csvFile.click()" />
            <button (click)="csvFile.click()" class="btn btn-secondary btn-sm btn-icon">Load</button>
        </span>
        <span class="clr-subtext" id='csvInputError' [innerHtml]="errorMessage"></span>
    </clr-control-container>
    `
})
export class CsvFileInputComponent {
    @Input() public itemName: string;
    @Input() public fileInputLabel: string;
    @Input() public filter?: RegExp = undefined;
    @Input() public errorMessage: string = "";
    @Input() public columnIndexesToParse: number[] = [];

    @Output() public fileParsedEvent = new EventEmitter<CSVFileParsedEvent>();

    public csvFileName: string | null = null;
    public itemCount: number;

    public onCsvFileChange(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            let file = event.target.files[0];
            this.csvFileName = file.name;
            reader.onload = () => {
                let content: string = <string>reader.result;
                let eventToEmit = new CSVFileParsedEvent();
                let csvItems = this.extractColumnEntriesFromCsvAndConvertToLowercase(content, this.columnIndexesToParse);
                if (this.filter) {
                    csvItems.forEach(r => {
                        if (this.filter?.test(r)) {
                            eventToEmit.validItems.push(r);
                        } else {
                            eventToEmit.invalidItems.push(r);
                        }
                    })
                } else {
                    eventToEmit.validItems = [...csvItems];
                }
                this.itemCount = eventToEmit.validItems.length;
                this.fileParsedEvent.emit(eventToEmit);
            }
            reader.readAsText(<Blob>file);

            // we want to be able to select multipletimes the same files (to reload content).
            event.target.value = "";
        } else {
            this.csvFileName = null;
        }
    }

    private splitCsvIntoRows(fileContent: string): string[] {
        return fileContent.split(/\n|\r\n/).filter(x => x);
    }

    // Solution adapted from https://stackoverflow.com/questions/8493195/how-can-i-parse-a-csv-string-with-javascript-which-contains-comma-in-data
    private getRawCSVItems(content: string): string[] {
        const re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
        const re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:[ \t]+[^,'"\s\\]+)*))\s*(?:,|$)/gm;

        let csvItems: string[] = [];
        if (!re_valid.test(content))
            return [];

        /** 
         * re_value is a regular expression that will result in four kind of matches for a string
         * m0: match for empty string,
         * m1: match for single quoted csv item,
         * m2: match for double quoted csv item,
         * m3: match for not quoted csv item.
         */
        content.replace(re_value,
            (m0, m1, m2, m3) => {
                if (m1 !== undefined) {
                    // Remove backslash from \' in single quoted values.
                    csvItems.push(m1.replace(/\\'/g, "'"));
                }
                else if (m2 !== undefined) {
                    // Remove backslash from \" in double quoted values.
                    csvItems.push(m2.replace(/\\"/g, '"'));
                }
                else if (m3 !== undefined) {
                    csvItems.push(m3);
                }
                return '';
            });
        // Handle special case of empty last value.
        if (/,\s*$/.test(content)) csvItems.push('');

        return csvItems;
    }

    private extractColumnEntriesFromCsvAndConvertToLowercase(fileContent: string, columnIndexesToParse: number[]): Set<string> {
        // this method removes duplicate entries
        const rows = this.splitCsvIntoRows(fileContent);
        let result: Set<string> = new Set<string>();
        for (let row of rows) {
            const rawCsvItems = this.getRawCSVItems(row);
            for (let i of columnIndexesToParse) {
                const item = rawCsvItems[i].toLowerCase();
                result.add(item);
            }
        }
        return result;
    }
}

export class CSVFileParsedEvent {
    validItems: string[] = [];
    invalidItems: string[] = [];
}
