//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, OnInit } from "@angular/core";
import { HttpRequest, HttpRequestType } from "@Shared/utils/httpRequest";
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service";
import { EnterpriseService } from "./enterprise.service";
import { AccountService, UserAccountDetails } from "../Account/account.service";
import { HttpRequestFragmenter } from "@Shared/utils/httpRequestFragmenter";
import { Subscription } from 'rxjs';

@Component({
    selector: 'admins-enterprise',
    templateUrl: './admins.component.html',
})
export class AdminsComponent implements OnInit {
    public getAdminUsersRequest:HttpRequest;
    public addAdminUsersRequest:HttpRequest;
    public removeAdminUsersRequest:HttpRequest;
    public resendAdminWelcomeEmailRequest:HttpRequest;
    public resendAdminWelcomeEmailFragmenter:HttpRequestFragmenter;

    public adminUsers:any[] = [];
    public selectedAdmins: any[] = [];
    public newAdminUserEmail:string = "";

    private _subscriptionAdminTableRefresh: Subscription;

    public constructor(
        private httpService:MsalHttpRequestService,
        public enterpriseService:EnterpriseService,
        public accountService:AccountService) {
    }

    ngOnInit(): void {
        this.getAdminUsersRequest = this.httpService.getRequest(HttpRequestType.GET, "userenterprise/adminusers");
        this.addAdminUsersRequest = this.httpService.getRequest(HttpRequestType.POST, "userenterprise/adminusers");
        this.removeAdminUsersRequest = this.httpService.getRequest(HttpRequestType.POST, "userenterprise/RemoveAdminUsers");
        this.resendAdminWelcomeEmailRequest = this.httpService.getRequest(HttpRequestType.POST, "userenterprise/ResendAdminWelcomeEmail");
        this.resendAdminWelcomeEmailFragmenter = new HttpRequestFragmenter(this.resendAdminWelcomeEmailRequest);

        this.getAdminUsersRequest.onStart.on(req => {
            req.query.org = this.enterpriseService.getOrganization();
        });

        this.getAdminUsersRequest.onSuccess.on(req => {
            this.adminUsers = req.getReponseObj<any[]>();
        });

        this.addAdminUsersRequest.onStart.on(req => {
            req.query.org = this.enterpriseService.getOrganization();
            req.body = { email: this.newAdminUserEmail };
        });

        this.addAdminUsersRequest.onSuccess.on(h => {
            var resp = h.responseObj;    
            if (resp && resp.errorInfo){
                throw Error(resp.errorInfo);
            }
            else{
                this.newAdminUserEmail = "";
                this.isAddAdminModalOpen = false;
                this.getAdminUsersRequest.send()
            }  
        });

        this.resendAdminWelcomeEmailFragmenter.onStart.on((fragmenter: HttpRequestFragmenter) => {
            fragmenter.request.query.org = this.enterpriseService.getOrganization();
        });

        this.resendAdminWelcomeEmailFragmenter.onStartFragment.on((fragmenter: HttpRequestFragmenter) => {
            fragmenter.request.body = { email: this.selectedAdmins[fragmenter.currentIndex].email };
        });

        this.resendAdminWelcomeEmailFragmenter.onStop.on((fragmenter: HttpRequestFragmenter) => {
            this.selectedAdmins = [];
        });

        this.removeAdminUsersRequest.onStart.on(req => {
            req.query.org = this.enterpriseService.getOrganization();
            req.body = { users: this.selectedAdmins };
        });

        this.removeAdminUsersRequest.onSuccess.on(req => {
            this.isRemoveAdminModalOpen = false;
            this.getAdminUsersRequest.send();
        });

        this.enterpriseService.onOrganizationChange.on(org => {
            this.getAdminUsersRequest.send();
        });

        this.getAdminUsersRequest.send();

        this._subscriptionAdminTableRefresh = this.enterpriseService.shouldRefreshEnterpriseAdminTable.subscribe(x => {
          this.getAdminUsersRequest.send();
        });
    }

    ngOnDestroy() {
      this._subscriptionAdminTableRefresh.unsubscribe();
    }

    public _isRemoveAdminModalOpen = false;
    public get isRemoveAdminModalOpen():boolean {
        return this._isRemoveAdminModalOpen;
    }

    public set isRemoveAdminModalOpen(value:boolean) {
        this._isRemoveAdminModalOpen = value;
        this.removeAdminUsersRequest.reset();
    }

    public _isAddAdminModalOpen = false;
    public get isAddAdminModalOpen():boolean {
        return this._isAddAdminModalOpen;
    }

    public set isAddAdminModalOpen(value:boolean) {
        this._isAddAdminModalOpen = value;
        this.addAdminUsersRequest.reset();
    }

    public get currentUser(): UserAccountDetails {
        // can't be null cause we need to be connected to see that page
        return <UserAccountDetails> this.accountService.signedInUserDetails;
    }

    public resendAdminWelcomeEmail(){
        this.resendAdminWelcomeEmailFragmenter.reset();
        this.resendAdminWelcomeEmailFragmenter.maxIndex = this.selectedAdmins.length;
        this.resendAdminWelcomeEmailFragmenter.start();
    }
}
