<clr-main-container [ngClass]="isConnected ? 'authorized' : 'not-authorized'">
    <clr-header class="accountHeader header-4" *ngIf="isConnected">
        <div class="branding">
            <span class="title">
                <img src="assets/images/account-logo-yellow-skew-white-text.svg" id="banner-logo"
                    style="width: auto; height: 1.5rem;" class="clr-icon" />
            </span>
        </div>
        <div class="header-actions nav-icon">
            <clr-dropdown>
                <button type="button" clrDropdownToggle clrDropdownTrigger>
                    {{language}}
                    <clr-icon shape="caret down"></clr-icon>
                </button>
                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                    <ng-container *ngFor="let supportedLanguage of supportedLanguages">
                        <button type="button" (click)="setLanguage(supportedLanguage, true)"
                            clrDropdownItem>{{supportedLanguage}}</button>
                    </ng-container>
                </clr-dropdown-menu>
            </clr-dropdown>

            <a routerLink="/help" [routerLinkActive]="['active']" class="nav-link nav-icon headerLink"
                *ngIf="isConnected">
                <clr-icon shape="help" class="is-solid"></clr-icon>
            </a>

            <clr-dropdown class="nav-icon" *ngIf="isConnected">
                <button type="button" clrDropdownToggle clrDropdownTrigger>
                    <clr-icon shape="user"></clr-icon>
                    <clr-icon shape="caret down"></clr-icon>
                </button>
                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                    <a [href]="basicProfileUri" [routerLinkActive]="['active']" clrDropdownItem i18n>Manage Ansys
                        Account</a>
                    <!--<button type="button" [disabled]="!isConnected" class="btn-primary btn" (click)="redirectToECommerce()" clrDropdownItem i18n>Upgrade or Renew</button>-->
                    <a routerLink="/account" [routerLinkActive]="['active']" clrDropdownItem i18n>Edit Profile</a>
                    <a [href]="welcomeForumUri" target="_blank" clrDropdownItem i18n>Discovery Forum</a>
                    <a [href]="ACCForumUri" target="_blank" *ngIf="isACCUser" clrDropdownItem i18n>Ansys Cloud Forum</a>
                    <button type="button" (click)="logout()" clrDropdownItem i18n>Sign out</button>
                </clr-dropdown-menu>
            </clr-dropdown>
        </div>
    </clr-header>
    <div class="content-container">
        <clr-vertical-nav clr-vertical-nav *ngIf="isConnected" [clrVerticalNavCollapsible]="true" [clr-nav-level]="1"
            class="navigation">
            <a clrVerticalNavLink *ngIf="!isOnPremise" routerLinkActive="active" routerLink="/mysubscriptions"
                class="nav-link">
                <clr-icon shape="bundle" clrVerticalNavIcon class="is-solid"></clr-icon>
                <span i18n="@@b54b6cbe266f2678222a53f78e29530f25c7c353">My Subscriptions</span>
            </a>
            <a clrVerticalNavLink *ngIf="isEnterpriseAdmin" routerLink="/enterprise" routerLinkActive='active'
                class="nav-link">
                <clr-icon shape="administrator" clrVerticalNavIcon class="is-solid"></clr-icon>
                <span i18n="@@6d534a1ef1c96bf01ad2b495da48a5ebc5e302d3">Administration</span>
            </a>
        </clr-vertical-nav>
        <main class="content-area" *ngIf="isConnected">
            <request-presenter [request]="redirectToECommerceRequest" [showError]="true" [locker]="true">
            </request-presenter>
            <router-outlet></router-outlet>
        </main>
    </div>
</clr-main-container>