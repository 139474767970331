<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
  <h1 i18n>Profile Details</h1>

  <ng-container *ngIf="isLoading">
    <div style="text-align:center; width:100%; padding: 50px">
      <span class='spinner spinner-lg'></span>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <div>
      <button (click)="saveChanges()" [disabled]="shouldDisableSaveChanges()" class="btn btn-primary">Save
        Changes</button>
      <button (click)="cancel()" [disabled]="shouldDisableCancel()"
        class="btn btn-secondary btn-separated">Cancel</button>
    </div>

    <div *ngIf="statusSuccess" class="alert alert-success clr-col-md-6 clr-col-12" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <clr-container class="alert-icon-wrapper">
            <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
          </clr-container>
          <span class="alert-text">
            {{statusSuccess}}
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="statusFailure" class="alert alert-danger clr-col-md-6 clr-col-12" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <clr-container class="alert-icon-wrapper">
            <clr-icon class="alert-icon" shape="error-standard"></clr-icon>
          </clr-container>
          <span class="alert-text">
            {{statusFailure}}
          </span>
        </div>
      </div>
    </div>

    <div class="card profile-card">
      <div class="card-header" i18n>
        Ansys Account Info
      </div>
      <div class="card-block">
        <form clrForm class="clr-form-horizontal">
          <clr-input-container class="avoid-truncate">
            <label>First Name</label>
            <input clrInput type="text" readonly [value]="userDetails.givenName">
          </clr-input-container>
          <clr-input-container class="avoid-truncate">
            <label>Last Name</label>
            <input clrInput type="text" readonly [value]="userDetails.surname">
          </clr-input-container>
          <clr-input-container class="avoid-truncate">
            <label>Email</label>
            <input clrInput type="text" readonly [value]="userDetails.mail">
          </clr-input-container>
          <br />
          <div>
            <a class="textbutton" [href]="basicProfileEditLink" i18n>Manage Ansys Account</a>
            <p>
              Please note that any changes to Account Information may take a few seconds to appear in our system.
              You may need to refresh the page to view the changes.
            </p>
          </div>
        </form>
      </div>
    </div>

    <div class="card profile-card">
      <div class="card-header" i18n>
        Company Info
      </div>
      <div class="card-block">
        <form clrForm clrLayout="horizontal">
          <clr-input-container>
            <label for="job">Job Title</label>
            <input clrInput type="text" id="job" name="job" pattern="^$|[\x00-\x7F]+"
              [(ngModel)]="editUserProfile.jobTitle">
            <clr-control-error id="errortxt">Please enter only alphanumeric
              characters (A-Z, a-z, 0-9).</clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label for="company">Company</label>
            <input clrInput type="text" id="company" name="company" required pattern="^$|[\x00-\x7F]+"
              [(ngModel)]="editUserProfile.company">
            <clr-control-error id="errortxt">Please enter only alphanumeric
              characters (A-Z, a-z, 0-9).</clr-control-error>
          </clr-input-container>
          <address-enrichment [url]="environment.oidc" [(street)]="editUserProfile.street"
            [(city)]="editUserProfile.city" [(postal)]="editUserProfile.postal" [(state)]="editUserProfile.state"
            [(country)]="editUserProfile.country">
          </address-enrichment>
        </form>
      </div>
    </div>

    <div class="card profile-card">
      <div class="card-header" i18n>
        Marketing Preferences
      </div>
      <div class="card-block">
        <form clrForm class="clr-form-horizontal">
          <clr-checkbox-container>
            <clr-checkbox-wrapper>
              <input type="checkbox" id="marketing" name="marketing" clrCheckbox
                [(ngModel)]="editUserProfile.marketingOption" />
              <label>I want to receive updates and offers from Ansys and its partners. I can unsubscribe at any
                time.</label>
            </clr-checkbox-wrapper>
          </clr-checkbox-container>
          <clr-input-container>
            <label for="phone">Phone Number (optional)</label>
            <input clrInput type="text" id="phone" name="phone" [(ngModel)]="editUserProfile.phone" pattern="[0-9]+">
            <clr-control-helper>This number will only be used for marketing purposes.</clr-control-helper>
            <clr-control-error>Please enter only numbers (0-9).
            </clr-control-error>
          </clr-input-container>
        </form>
      </div>
    </div>
  </ng-container>
</div>